import {
	Box,
	Button,
	Card,
	Checkbox,
	DataTable,
	Layout,
	Spinner,
	Text,
} from "@shopify/polaris";
import Modal from 'components/Modal'
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import { createCustomDispatch } from "helpers/customDispatch";
import withRouter from "helpers/withRouter";
import _ from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import BannerCustom from '../../components/Banners/BannerCustom'
import {
	fetchAutosubmitCountries,
	fetchAutosubmitStores,
	saveAutosubmit,
} from "../../redux/features/tax-settings/taxSettingsSlice";
import formatRow from "utils/formatRow";

class AutoSubSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedStores: [],
			selectedCountries: [],
			showSaveModal: false,
			saveConfirmed: false,
		};
	}
	
	componentDidMount = () => {
		this.props.fetchAutosubmitCountries().then(() =>
			this.setState({
				selectedCountries: this.props.autoCountries.map((item,
					ind) => ({
					ind,
					autosubmit: item.type_of_report.autosubmit,
					country_code: item.country.code,
					country_name: item.country.name,
					type_of_report_id: item.type_of_report.id,
					type_of_report: item.type_of_report.name,
				})),
			})
		);
		this.props.fetchAutosubmitStores().then(() =>
			this.setState({
				selectedStores: this.props.autoStores.map((item,
					ind) => ({
					ind,
					id: item.id,
					autosubmit: item.autosubmit,
					title: item.title,
				})),
			})
		);
	};
	
	//   componentDidUpdate(prevProps) {
	//     const { defaultLanguage } = this.props;
	//     if (!prevProps.defaultLanguage && defaultLanguage) {
	//       t("taxSettings.setLanguage(defaultLanguage.lang);
	//     }
	//   }
	
	saveData = () => {
		const {selectedCountries, selectedStores} = this.state;
		const data = {
			countries: selectedCountries.map((item) => ({
				autosubmit: item.autosubmit,
				country_code: item.country_code,
				type_of_report_id: item.type_of_report_id,
			})),
			stores: selectedStores.map((item) => ({
				autosubmit: item.autosubmit,
				id: item.id,
			})),
		};
		this.props
			.saveAutosubmit(data)
			.then(() => this.setState({savedAuto: true, savingAutoError: null}))
			.catch(({error}) =>
				this.setState({
					savingAutoError: error,
					savedAuto: null,
				})
			);
	};
	
	renderStoresTable = () => {
		const {autoStores, fetchingAutoStores, t} = this.props;
		const {selectedStores} = this.state;
		
		const columns = [
			{
				property: "title",
				header: {
					label: t("taxSettings.store"),
				},
			},
			{
				property: "autosubmit",
				header: {
					label: t("taxSettings.autopilot"),
					formatters: [
						(value) => <div style={{textAlign: "end"}}>{value}</div >,
					],
				},
				cell: {
					formatters: [
						(value,
							{rowData}) => (
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Checkbox
	                checked={rowData.autosubmit || false}
	                onChange={(checked) => {
		                this.setState((prevState) => {
			                const selected = [...prevState.selectedStores];
			                if (selected[rowData.ind]) {
				                selected[rowData.ind].autosubmit = checked;
			                }
			                return {
				                selectedStores: selected,
			                };
		                });
	                }}
                />
              </div>
						),
					],
				},
			},
		];
		
		const sortedRows = formatRow(selectedStores, columns);
		
		return (
			<Layout.AnnotatedSection
				title={
					<Text
						variant='headingLg'
						as='p'
					>
            {t("taxSettings.stores")}
          </Text >
				}
			>
        <Card >
          {!_.isEmpty(autoStores) && !fetchingAutoStores && (
	          <DataTable
		          columnContentTypes={["text", "text"]}
		          headings={columns.map(({header},
			          i) => (
			          <Text
				          fontWeight='semibold'
				          alignment={i === 1 ? "end" : "start"}
			          >
                  {header.label}
                </Text >
		          ))}
		          rows={sortedRows}
	          />
          )}
	        
	        {fetchingAutoStores && _.isEmpty(autoStores) && (
		        <div >
              <Spinner />
            </div >
	        )}
	        {!fetchingAutoStores && _.isEmpty(autoStores) && (
		        <NoDataMessage style={{height: "auto", padding: "1.5rem 0px"}} />
	        )}
        </Card >
      </Layout.AnnotatedSection >
		);
	};
	
	renderCountriesTable = () => {
		const {autoCountries, fetchingAutoCountries, t} = this.props;
		const {selectedCountries} = this.state;
		
		const columns = [
			{
				property: "country_name",
				header: {
					label: t("taxSettings.country"),
				},
				cell: {
					formatters: [
						(value,
							{rowData}) => {
							return <div >{rowData.country_name}</div >;
						},
					],
				},
			},
			{
				property: "type_of_report",
				header: {
					label: t("taxSettings.typeOfReport"),
				},
				cell: {
					formatters: [
						(value,
							{rowData}) => {
							return <div >{rowData.type_of_report}</div >;
						},
					],
				},
			},
			{
				property: "autosubmit",
				header: {
					label: t("taxSettings.autopilot"),
					formatters: [
						(value) => <div style={{textAlign: "end"}}>{value}</div >,
					],
				},
				cell: {
					formatters: [
						(value,
							{rowData}) => {
							return (
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Checkbox
	                  checked={rowData.autosubmit || false}
	                  onChange={(checked) => {
		                  this.setState((prevState) => {
			                  const selected = [...prevState.selectedCountries];
			                  if (selected[rowData.ind]) {
				                  selected[rowData.ind].autosubmit = checked;
			                  }
			                  return {
				                  selectedCountries: selected,
			                  };
		                  });
	                  }}
                  />
                </div>
							);
						},
					],
				},
			},
		];
		
		const sortedRows = formatRow(selectedCountries, columns);
		
		return (
			<Layout.AnnotatedSection
				title={
					<Text
						variant='headingLg'
						as='p'
					>
            {t("taxSettings.countries")}
          </Text >
				}
			>
        <Card >
          {!_.isEmpty(autoCountries) && !fetchingAutoCountries && (
	          <DataTable
		          columnContentTypes={["text", "text", "text"]}
		          headings={columns.map(({header},
			          i) => (
			          <Text
				          fontWeight='semibold'
				          alignment={i === 2 ? "end" : "start"}
			          >
                  {header.label}
                </Text >
		          ))}
		          rows={sortedRows}
	          />
          )}
	        
	        {fetchingAutoCountries && _.isEmpty(autoCountries) && (
		        <div >
              <Spinner />
            </div >
	        )}
	        {!fetchingAutoCountries && _.isEmpty(autoCountries) && (
		        <NoDataMessage style={{height: "auto", padding: "1.5rem 0px"}} />
	        )}
        </Card >
      </Layout.AnnotatedSection >
		);
	};
	// Show the modal for confirmation
	handleSaveClick = () => {
		this.setState({showSaveModal: true});
	};
	
	// Confirm the save and close the modal
	handleConfirmSave = () => {
		const {saveConfirmed} = this.state;
		if (saveConfirmed) {
			this.saveData();
			this.setState({showSaveModal: false});
		}
	};
	
	renderSaveModal = () => {
		const {showSaveModal, saveConfirmed} = this.state;
		
		return (
			<Modal
				iconType={"warning"}
				title={'Do you want to activate Autopilot?'}
				visible={showSaveModal}
				contentOnCenter={true}
				onClose={() => this.setState({showSaveModal: false})}
				content={
					<div
						style={{
							display: "flex",
							alignItems: "center",
							maxWidth: "400px",
							flexDirection: 'column',
							justifyContent: 'center'
						}}
					>
          <div style={{display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginTop: 20}}>
             <Checkbox
	             label={'I agree to turn on this feature for free for the first month. Then it will be charged 10% of monthly payment amount.'}
	             checked={saveConfirmed}
	             onChange={(checked) => this.setState({saveConfirmed: checked})}
             />
          </div >
						<div style={{width: '100%', display: "flex", alignItems: "center", justifyContent: 'space-around', marginTop: 20}}>
            <Button
	            size='large'
	            onClick={() => this.setState({showSaveModal: false})}
            >
	            Cancel
						</Button >
            <Button
	            size='large'
	            variant='primary'
	            onClick={this.handleConfirmSave}
	            disabled={!saveConfirmed}
            >
	            Turn on
						</Button >
          </div >
        </div >
				}
			/>
		);
	};
	
	render() {
		const {t} = this.props;
		const {savingAutoError, savedAuto} = this.state;
		
		return (
			<React.Fragment >
        <Box >
          <Box
	          display="flex"
	          alignItems="end"
          >
            <Button
	            variant='primary'
	            // onClick={() => this.saveData()}
	            onClick={() => this.handleSaveClick()}
	            loading={this.props.savingAuto}
            >
              {t("taxSettings.saveAll")}
            </Button >
          </Box >
        </Box >
				
				{savingAutoError && (
					<Layout.AnnotatedSection >
            {/*<Banner tone='critical'>{savingAutoError}</Banner >*/}
						<BannerCustom
							status={'critical'}
							message={savingAutoError}
						/>
          </Layout.AnnotatedSection >
				)}
				{savedAuto && (
					<Layout.AnnotatedSection >
            {/*<Banner tone='success'>{t("taxSettings.autoSubSaved")}</Banner >*/}
						<BannerCustom
							status={'success'}
							message={t("taxSettings.autoSubSaved")}
						/>
          </Layout.AnnotatedSection >
				)}
				
				{this.renderCountriesTable()}
				{this.renderStoresTable()}
				{this.renderSaveModal()}
      </React.Fragment >
		);
	}
}

const mapStateToProps = (state) => ({
	defaultLanguage: state.user.defaultLanguage,
	autoStores: state.taxSettings.autoStores,
	fetchingAutoStores: state.taxSettings.fetchingAutoStores,
	autoCountries: state.taxSettings.autoCountries,
	fetchingAutoCountries: state.taxSettings.fetchingAutoCountries,
	savingAuto: state.taxSettings.savingAuto,
	savingAutoError: state.taxSettings.savingAutoError,
	savedAuto: state.taxSettings.savedAuto,
});

const mapDispatchToProps = (defaultDispatch) => {
	const dispatch = createCustomDispatch(defaultDispatch);
	
	return {
		fetchAutosubmitCountries: () => dispatch(fetchAutosubmitCountries()),
		fetchAutosubmitStores: () => dispatch(fetchAutosubmitStores()),
		saveAutosubmit: (params) => dispatch(saveAutosubmit(params)),
	};
};

export default withTranslation()(
	connect(mapStateToProps, mapDispatchToProps)(withRouter(AutoSubSettings))
);
