import * as PropTypes from 'prop-types'
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  createUser,
  editUser,
  deleteUser,
  changeOwnPassword,
  changeUserPassword,
  fetchUsers,
} from "../../../../redux/features/user/userSlice";
import _ from "lodash";
import { isEmail, isLatin, isValidPassword } from "utils/validation";
import {
  Layout,
  Card,
  Avatar,
  ResourceList,
  ResourceItem,
  Scrollable,
  Button,
  ButtonGroup,
  TextField,
  FormLayout,
  Banner,
  Spinner,
  Text,
  Tooltip,
  Box,
} from "@shopify/polaris";

import settingsIMG from "img/settings.svg";
import deleteIMG from "img/delete.svg";
import Modal from "components/Modal/Modal";
import { createCustomDispatch } from "helpers/customDispatch";
import { getRoleTitle } from "utils/getRoleTitle";

function Typography(props) {
  return null
}

Typography.propTypes = {
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node
}

class ProfileUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      users: props.users,
      selectedUser: null,
      isOpenEditPassword: false,
      isOpenEditUser: false,
      isOpenAddUser: false,
      isOpenDeleteUser: false,

      nameErrorEdit: false,
      surnameErrorEdit: false,
      emailErrorEdit: false,

      nameAdd: "",
      nameErrorAdd: false,
      surnameAdd: "",
      surnameErrorAdd: false,
      emailAdd: "",
      emailErrorAdd: false,
      passwordAdd: "",
      passwordErrorAdd: false,

      passwordOld: "",
      passwordErrorOld: false,
      passwordNew: "",
      passwordErrorNew: false,
      passwordRepeat: "",
      passwordErrorRepeat: false,

      passwordError: false,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps) {
      if (typeof newProps.changingError !== "undefined") {
        this.setState({ changingError: newProps.changingError });
      }
    }
  }

  handleChange = (value, id, errorName, errorText) => {
    if (value.length > 0 && isLatin(value)) {
      this.setState({
        [id]: value,
        [errorName]: false,
      });
    } else if (value.length > 0 && !isLatin(value)) {
      this.setState({
        [id]: value,
        [errorName]: "Use the Latin alphabet!",
      });
    } else {
      this.setState({
        [id]: "",
        [errorName]: errorText,
      });
    }
  };

  afterEditUser = () => {
    this.toggleEditUser(false);
    this.toggleAddUser(false);
    this.toggleDeleteUser(false);
    this.props
      .fetchUsers()
      .then((result) => {
        this.setState({
          users: result,
        });
      })
      .catch(_.noop);
  };

  doEditUser = (event) => {
    event.preventDefault();
    const { email, password } = this.state.selectedUser.user;
    const {
      nameErrorEdit,
      surnameErrorEdit,
      passwordErrorEdit,
      emailErrorEdit,
    } = this.state;
    if (
      !email ||
      !this.state.selectedUser.user.first_name ||
      !this.state.selectedUser.user.last_name ||
      nameErrorEdit ||
      surnameErrorEdit ||
      passwordErrorEdit ||
      emailErrorEdit
    ) {
      return;
    }
    const formData = {
      email,
      first_name: this.state.selectedUser.user.first_name,
      last_name: this.state.selectedUser.user.last_name,
      password,
    };
    if (this.state.selectedUser.user.id > 0) {
      this.props
        .editUser({ id: this.state.selectedUser.user.id, ...formData })
        .then(() => {
          if (formData.password) {
            this.props
              .changeUserPassword({
                id: this.state.selectedUser.user.id,
                new_password: formData.password,
              })
              .then(() => {
                this.afterEditUser();
              });
          } else {
            this.afterEditUser();
          }
        })
        .catch(_.noop);
    }
  };

  doAddUser = (event) => {
    event.preventDefault();
    const { t } = this.props;
    const {
      emailAdd,
      nameAdd,
      surnameAdd,
      passwordAdd,
      emailErrorAdd,
      nameErrorAdd,
      surnameErrorAdd,
      passwordErrorAdd,
    } = this.state;

    if (
      !emailAdd ||
      !nameAdd ||
      !surnameAdd ||
      !passwordAdd ||
      emailErrorAdd ||
      nameErrorAdd ||
      surnameErrorAdd ||
      passwordErrorAdd
    ) {
      this.setState({
        emailErrorAdd: !emailAdd ? t("profileUsers.err.email") : emailErrorAdd,
        nameErrorAdd: !nameAdd ? t("profileUsers.err.userName") : nameErrorAdd,
        surnameErrorAdd: !surnameAdd
          ? t("profileUsers.err.userSurname")
          : surnameErrorAdd,
        passwordErrorAdd: !passwordAdd
          ? t("profileUsers.err.pass")
          : passwordErrorAdd,
      });
      return;
    }
    const formData = {
      email: emailAdd,
      first_name: nameAdd,
      last_name: surnameAdd,
      password: passwordAdd,
    };
    this.props
      .createUser({ ...formData })
      .then(() => {
        this.afterEditUser();
      })
      .catch((err) => {
        this.setState({
          errorAddUser: err.error,
        });
      });
  };

  doDeleteUser = () => {
    this.props
      .deleteUser(this.state.selectedUser.user.id)
      .then(() => {
        this.afterEditUser();
      })
      .catch(_.noop);
  };

  doChangePassword = (event) => {
    event.preventDefault();
    const { passwordOld, passwordNew, passwordRepeat } = this.state;
    if (!passwordOld || !passwordNew || !passwordRepeat) {
      return;
    }
    if (this.state.user.id === this.state.selectedUser.id) {
      this.props
        .changeOwnPassword({
          old_password: passwordOld,
          new_password: passwordNew,
        })
        .then(() => {
          this.toggleEditPassword();
        })
        .catch(({ error }) => {
          this.setState({
            passwordError: error,
          });
        });
    }
  };

  toggleEditUser = (user = null) => {
    this.setState({ isOpenEditUser: !!user });
    this.setState({
      isOpenAddUser: false,
      isOpenEditPassword: false,
    });
    if (!user) {
      this.setState({ selectedUser: null });
    } else {
      this.setState({
        selectedUser: JSON.parse(JSON.stringify(user)) /*user - deep clone*/,
        nameErrorEdit: false,
        surnameErrorEdit: false,
        emailErrorEdit: false,
      });
    }
  };

  toggleEditPassword = (user = null) => {
    this.setState({ isOpenEditPassword: !!user });
    this.setState({
      isOpenAddUser: false,
      isOpenEditUser: false,
    });
    this.setState({ changingError: "" });
    if (!user) {
      this.setState({ selectedUser: null });
    } else {
      this.setState({
        selectedUser: user,
        passwordOld: "",
        passwordNew: "",
        passwordRepeat: "",
        passwordErrorOld: false,
        passwordErrorNew: false,
        passwordErrorRepeat: false,
      });
    }
  };

  toggleAddUser = (status = true) => {
    this.setState({
      isOpenAddUser: status,
      nameErrorAdd: false,
      surnameErrorAdd: false,
      emailErrorAdd: false,
      passwordErrorAdd: false,
      errorAddUser: false,
      nameAdd: "",
      surnameAdd: "",
      emailAdd: "",
      passwordAdd: "",
    });
    this.setState({
      isOpenEditUser: false,
      isOpenEditPassword: false,
    });
    if (status) {
      this.setState({ selectedUser: { user: { id: -1 } } });
    } else {
      this.setState({ selectedUser: null });
    }
  };

  toggleDeleteUser = (user = null) => {
    this.setState({ isOpenDeleteUser: !!user });
    if (!user) {
      this.setState({ selectedUser: null });
    } else {
      this.setState({ selectedUser: user });
    }
  };

  validateEmail = (email, errorName) => {
    const { t } = this.props;
    if (email.length > 0 && !isEmail(email)) {
      this.setState({
        [errorName]: t("profileUsers.err.emailInvalid"),
      });
    } else if (email.length === 0) {
      this.setState({
        [errorName]: t("profileUsers.err.email"),
      });
    } else {
      this.setState({
        [errorName]: false,
      });
    }
  };

  validatePassword = (password, errorName) => {
    const { t } = this.props;
    if (password.length > 0 && password.length < 7) {
      this.setState({
        [errorName]: t("profileUsers.err.passLeast"),
      });
    } else if (password.length === 0) {
      this.setState({
        [errorName]: t("profileUsers.err.pass"),
      });
    } else if (!isValidPassword(password)) {
      this.setState({
        [errorName]: "Use the Latin alphabet and special characters!",
      });
    } else {
      this.setState({
        [errorName]: false,
      });
    }
  };

  validatePasswordEqual = () => {
    const { t } = this.props;
    const { passwordNew, passwordRepeat } = this.state;
    if (passwordNew !== passwordRepeat) {
      this.setState({
        passwordErrorRepeat: t("profileUsers.err.passNotEq"),
      });
    }
  };

  renderViewUsersList(users = []) {
    const { t } = this.props;

    const items = _.map(users, (user) => ({
      id: user.user.id,
      user,
      name: `${user.user.first_name} ${user.user.last_name}`,
      initials:
        user.user.first_name.substr(0, 1) + user.user.last_name.substr(0, 1),
      currentUser: user.user.id !== this.state.user.id,
    }));

    return (
      <div>
        <ResourceList
          items={items}
          renderItem={(item, index) => {
            const { id, name, currentUser, user } = item;
            const media = <Avatar name={item.name} initials={item.initials} />;

            const shortcutActions = currentUser
              ? [
                  {
                    content: (
                      <Tooltip content='Edit'>
                        <img src={settingsIMG} alt='icon settings' />
                      </Tooltip>
                    ),
                    onAction: (e) => {
                      e.preventDefault();
                      this.toggleEditUser(user);
                    },
                  },
                  {
                    content: (
                      <Tooltip content='Delete'>
                        <img src={deleteIMG} alt='icon delete' />
                      </Tooltip>
                    ),
                    onAction: (e) => {
                      e.preventDefault();
                      this.toggleDeleteUser(user);
                    },
                  },
                ]
              : [
                  {
                    content: (
                      <Tooltip content={t("profileUsers.changePass")}>
                        <img src={settingsIMG} alt='icon settings' />
                      </Tooltip>
                    ),
                    onAction: (e) => {
                      e.preventDefault();
                      this.toggleEditPassword(this.state.user);
                    },
                  },
                ];

            if (
              this.state.isOpenEditUser &&
              this.state.selectedUser &&
              this.state.selectedUser.user.id === item.id
            ) {
              const largeMedia = (
                <Avatar
                  name={item.name}
                  initials={item.initials}
                  size='large'
                />
              );

              return (
                <ResourceItem
                  key={`${index}-row`}
                  id={id}
                  media={largeMedia}
                  accessibilityLabel={name}
                  shortcutActions={shortcutActions.slice(1)}
                  persistActions
                >
                  {this.renderEditUser(this.state.selectedUser)}
                </ResourceItem>
              );
            }
            if (
              this.state.isOpenEditPassword &&
              this.state.user.id === item.id
            ) {
              const largeMedia = (
                <Avatar
                  name={item.name}
                  initials={item.initials}
                  size='large'
                />
              );

              return (
                <ResourceItem
                  key={`${index}-row`}
                  id={id}
                  media={largeMedia}
                  accessibilityLabel={name}
                  shortcutActions={shortcutActions.slice(1)}
                  persistActions
                >
                  <h3>
                    <Text as="h6" variant="headingMd"  fontWeight="bold">{name}</Text>
                  </h3>
                  {this.renderEditPassword(user)}
                </ResourceItem>
              );
            }

            return (
              <ResourceItem
                key={`${index}-row`}
                id={id}
                media={media}
                accessibilityLabel={name}
                shortcutActions={shortcutActions}
                persistActions
              >
                <h3>
                  <span style={{fontSize: 14, fontWeight: 'bold', color: '#474747'}}>
                    {name}
                  </span>
                </h3>
                <div style={{fontWeight: 400, fontSize: 14, color: '#707070'}}>{getRoleTitle(user)}</div>
              </ResourceItem>
            );
          }}
        />
      </div>
    );
  }

  renderEditUser(user) {
    const { t } = this.props;
    const {
      nameErrorEdit,
      surnameErrorEdit,
      emailErrorEdit,
      passwordErrorEdit,
    } = this.state;

    return (
      <form onSubmit={this.doEditUser} style={{ width: "90%" }}>
        <FormLayout>
          <Box
            display="flex"
            flexDirection="column"
            gap="3"
            width="100%"
          >
            <TextField
              name='first_name'
              label={`${t("profileUsers.name")}:`}
              value={user.user.first_name}
              error={nameErrorEdit}
              onChange={(value) => {
                const { selectedUser } = this.state;
                selectedUser.user.first_name = value;
                this.setState({
                  selectedUser,
                  nameErrorEdit: isLatin(value)
                    ? false
                    : "Use the Latin alphabet!",
                });
              }}
              onBlur={() => {
                const { selectedUser } = this.state;
                if (!selectedUser.user.first_name) {
                  this.setState({
                    nameErrorEdit: t("profileUsers.err.userName"),
                  });
                }
              }}
            />
            <TextField
              name='last_name'
              label={`${t("profileUsers.surname")}:`}
              value={user.user.last_name}
              error={surnameErrorEdit}
              onChange={(value) => {
                const { selectedUser } = this.state;
                selectedUser.user.last_name = value;
                this.setState({
                  selectedUser,
                  surnameErrorEdit: isLatin(value)
                    ? false
                    : "Use the Latin alphabet!",
                });
              }}
              onBlur={() => {
                const { selectedUser } = this.state;
                if (!selectedUser.user.last_name) {
                  this.setState({
                    surnameErrorEdit: t("profileUsers.err.userSurname"),
                  });
                }
              }}
            />
            <TextField
              name='email'
              label={`${t("profileUsers.email")}:`}
              value={user.user.email}
              error={emailErrorEdit}
              onChange={(value) => {
                const { selectedUser } = this.state;
                selectedUser.user.email = value;
                this.setState({
                  selectedUser,
                  emailErrorEdit: false,
                });
              }}
              onBlur={() =>
                this.validateEmail(
                  this.state.selectedUser.user.email,
                  "emailErrorEdit"
                )
              }
            />
            <TextField
              name='password'
              label={`${t("profileUsers.password")}:`}
              value={user.user.password || ""}
              error={passwordErrorEdit}
              onChange={(value) => {
                const { selectedUser } = this.state;
                selectedUser.user.password = value;
                this.setState({ selectedUser });
              }}
              onBlur={() => {
                const password = user.user.password;
                if (password.length > 0 && password.length < 7) {
                  this.setState({
                    passwordErrorEdit: t("profileUsers.err.passLeast"),
                  });
                } else if (!isValidPassword(password)) {
                  this.setState({
                    passwordErrorEdit:
                      "Use the Latin alphabet and special characters!",
                  });
                } else {
                  this.setState({
                    passwordErrorEdit: false,
                  });
                }
              }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="end"
          >
            <ButtonGroup>
              <Button variant='primary' submit>
                {t("profileUsers.editUser")}
              </Button>
              <Button onClick={() => this.toggleEditUser(false)}>
                {t("profileUsers.cancel")}
              </Button>
            </ButtonGroup>
          </Box>
        </FormLayout>
      </form>
    );
  }

  renderEditPassword() {
    const { t } = this.props;
    const {
      passwordOld,
      passwordErrorOld,
      passwordNew,
      passwordErrorNew,
      passwordRepeat,
      passwordErrorRepeat,
      passwordError,
    } = this.state;

    return (
      <form onSubmit={this.doChangePassword} style={{ width: "90%" }}>
        <FormLayout>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
          >
            <TextField
              name='user-old-pass'
              label={`${t("profileUsers.oldPass")}:`}
              type='password'
              value={passwordOld}
              error={passwordErrorOld}
              onChange={(value) => {
                this.setState({
                  passwordOld: value,
                  passwordErrorOld: false,
                });
              }}
              onBlur={() =>
                this.validatePassword(passwordOld, "passwordErrorOld")
              }
            />
            <Text variant='bodySm' color='subdued'>
              {t("profileUsers.mess.passLeast")}
            </Text>
            <TextField
              name='user-new-pass'
              label={`${t("profileUsers.newPass")}:`}
              type='password'
              value={passwordNew}
              error={passwordErrorNew}
              onChange={(value) => {
                this.setState({
                  passwordNew: value,
                  passwordErrorNew: false,
                });
              }}
              onBlur={() =>
                this.validatePassword(passwordNew, "passwordErrorNew")
              }
            />
            <TextField
              name='user-new-pass-rpt'
              label={`${t("profileUsers.repeatPass")}:`}
              type='password'
              value={passwordRepeat}
              error={passwordErrorRepeat}
              onChange={(value) => {
                this.setState({
                  passwordRepeat: value,
                  passwordErrorRepeat: false,
                });
              }}
              onBlur={() => this.validatePasswordEqual()}
            />
          </Box>

          <Box
            display="flex"
            alignItems="end"
          >
            <ButtonGroup>
              <Button variant='primary' submit>
                {t("profileUsers.changePass")}
              </Button>
              <Button onClick={() => this.toggleEditPassword(false)}>
                {t("profileUsers.cancel")}
              </Button>
            </ButtonGroup>
          </Box>

          {this.state.changingError && (
            <Text variant="bodyMd" color="critical">
              {this.state.changingError}
            </Text>
          )}
          {passwordError && (
            <Banner
              title={passwordError}
              onDismiss={() => this.setState({ passwordError: false })}
              status='critical'
            />
          )}
        </FormLayout>
      </form>
    );
  }

  renderAddUser() {
    const { t, creating } = this.props;
    const {
      nameAdd,
      nameErrorAdd,
      surnameAdd,
      surnameErrorAdd,
      emailAdd,
      emailErrorAdd,
      passwordAdd,
      passwordErrorAdd,
      errorAddUser,
    } = this.state;

    return (
      <Card title={t("profileUsers.addUser")} sectioned>
        <form onSubmit={this.doAddUser}>
          {errorAddUser && (
            <div>
              <Banner tone='critical'>{errorAddUser}</Banner>
              <br />
            </div>
          )}
          <FormLayout>
            <FormLayout.Group>
              <TextField
                id='nameAdd'
                name='first_name'
                label={t("profileUsers.name")}
                value={nameAdd}
                error={nameErrorAdd}
                onChange={(value, id) =>
                  this.handleChange(
                    value,
                    id,
                    "nameErrorAdd",
                    t("profileUsers.err.userName")
                  )
                }
              />
              <TextField
                id='surnameAdd'
                name='last_name'
                label={t("profileUsers.surname")}
                value={surnameAdd}
                error={surnameErrorAdd}
                onChange={(value, id) =>
                  this.handleChange(
                    value,
                    id,
                    "surnameErrorAdd",
                    t("profileUsers.err.userSurname")
                  )
                }
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                name='email'
                label={t("profileUsers.email")}
                value={emailAdd}
                error={emailErrorAdd}
                onChange={(value) =>
                  this.setState({ emailAdd: value, emailErrorAdd: false })
                }
                onBlur={() => this.validateEmail(emailAdd, "emailErrorAdd")}
              />
              <TextField
                name='password'
                label={t("profileUsers.password")}
                value={passwordAdd}
                error={passwordErrorAdd}
                onChange={(value) =>
                  this.setState({ passwordAdd: value, passwordErrorAdd: false })
                }
                onBlur={() =>
                  this.validatePassword(passwordAdd, "passwordErrorAdd")
                }
              />
            </FormLayout.Group>
          </FormLayout>
          <br />
          <ButtonGroup>
            <Button loading={creating} variant='primary' submit>
              {t("profileUsers.addUser")}
            </Button>
            <Button onClick={() => this.toggleAddUser(false)}>
              {t("profileUsers.cancel")}
            </Button>
          </ButtonGroup>
        </form>
      </Card>
    );
  }

  renderDeleteUserConfirm(user) {
    const { t } = this.props;
    return (
      <Modal
        title={`${t("profileUsers.delUser")} ${user.user.first_name} 
            ${user.user.last_name}?`}
        description={t("profileUsers.sureDel")}
        iconType='danger'
        onClose={() => this.toggleDeleteUser(false)}
        visible={this.state.isOpenDeleteUser}
        footer={
          <ButtonGroup fullWidth>
            <Button
              onClick={() => {
                this.toggleDeleteUser(false);
              }}
              size='large'
              fullWidth
            >
              {t("profileUsers.cancel")}
            </Button>
            <Button
              destructive
              onClick={() => this.doDeleteUser()}
              size='large'
              fullWidth
            >
              {t("profileUsers.del")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  }

  render() {
    const { t, user, users, fetchingUsers } = this.props;

    return (
      <Layout.AnnotatedSection
        title={
          <Text variant='headingLg' as='p'>
            {t("profileUsers.users")} List
          </Text>
        }
        description={
          <Text variant={'bodySm'}>
            {t("profileUsers.desc")}
          </Text>
        }
      >
        <Layout.Section>
          <Card
            actions={[
              {
                content: t("profileUsers.addUsers"),
                onAction: () => this.toggleAddUser(true),
              },
            ]}
            sectioned
          >
            <Text variant='headingLg'>{t("profileUsers.users")}</Text>
            {fetchingUsers ? (
              <Spinner />
            ) : (
              <Scrollable shadow className='Scrollable'>
                {(user.company && user.role === 1) || user.is_manager
                  ? this.renderViewUsersList(users)
                  : null}
              </Scrollable>
            )}
          </Card>

          {this.state.isOpenAddUser && this.renderAddUser()}
          {this.state.selectedUser !== null &&
            this.state.user.id !== this.state.selectedUser.id &&
            !this.state.isOpenEditPassword &&
            this.renderDeleteUserConfirm(this.state.selectedUser)}
        </Layout.Section>
      </Layout.AnnotatedSection>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  users: state.user.users,
  fetchingUsers: state.user.fetchingUsers,
  changingError: state.user.changingError,
  defaultLanguage: state.user.defaultLanguage,
  creating: state.user.creating,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchUsers: () => dispatch(fetchUsers()),
    editUser: (params) => dispatch(editUser(params)),
    createUser: (params) => dispatch(createUser(params)),
    deleteUser: (params) => dispatch(deleteUser(params)),
    changeOwnPassword: (params) => dispatch(changeOwnPassword(params)),
    changeUserPassword: (params) => dispatch(changeUserPassword(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ProfileUsers)
);
