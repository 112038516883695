import { t } from 'i18next'
import * as PropTypes from 'prop-types'
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import withRouter from "helpers/withRouter";
import _ from "lodash";
import { formatDate } from "utils/dates";
import { Link } from "react-router-dom";
import {
  Page,
  Layout,
  Card,
  Button,
  Spinner,
  Text,
  Box,
  ButtonGroup,
} from "@shopify/polaris";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import Pagination from "components/Pagination/Pagination";
import Modal from "components/Modal/Modal";
import PageHelmet from "components/PageHelmet";
import { createCustomDispatch } from "helpers/customDispatch";
import GoBackPageNavigation from '../../components/GoBackPageNavigation/GoBackPageNavigation'
import { deleteNews, fetchNews } from "../../redux/features/dashboard/dashboardSlice";

const defaultLimit = 4;

function Typography(props) {
  return null
}

Typography.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node
}

class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagination: {
        page: 1,
        pages: 1,
        perPage: defaultLimit,
      },
      query: {
        limit: defaultLimit,
      },
      actionDialogs: {
        delete: { open: false },
      },
    };
  }

  componentDidMount() {
    const { newsCount } = this.props;
    document.title = "News";
    this.props
      .fetchNews({ limit: defaultLimit })
      .then(() => {
        const { news } = this.props;
        return this.setState({
          pages: news && Math.ceil(newsCount / defaultLimit),
        });
      })
      .catch(_.noop);
  }

  componentWillReceiveProps(nextProps) {
    const pagination = this.state.pagination;
    const { query } = this.state;
    pagination.count = nextProps.newsCount && nextProps.newsCount;
    pagination.pages =
      nextProps.news && nextProps.newsCount
        ? Math.ceil(nextProps.newsCount / defaultLimit) || 1
        : 1;
    if (query && !query.offset) {
      pagination.page = 1;
    }
    this.setState({ pagination });
  }

  onSelectPage = (inputPage) => {
    const pages =
      Math.ceil(this.props.newsCount / this.state.pagination.perPage) || 1;
    const page = Math.min(Math.max(inputPage, 1), pages);

    const query = {
      offset: (page - 1) * this.state.pagination.perPage,
      limit: defaultLimit,
    };
    this.setState(
      {
        query: _.assign(this.state.query, query),
        pagination: {
          ...this.state.pagination,
          page: page,
          pages: pages,
        },
      },
      () => {
        this.props.fetchNews(this.state.query);
      }
    );
  };

  handleActionDialogsClose(name) {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  }

  handleActionDialogsOpen(name, data = {}) {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  }

  doDeleteNews = () => {
    const { actionDialogs } = this.state;
    const id = _.get(actionDialogs, "cellData.id");

    if (id) {
      this.props
        .deleteNews(id)
        .then(() => {
          this.props.fetchNews(this.state.query);
          this.handleActionDialogsClose("delete");
        })
        .catch(_.noop);
    }
  };

  renderDeleteNewsDialog = () => {
    const { t } = this.props;
    const { actionDialogs } = this.state;

    const onClose = () => this.handleActionDialogsClose("delete");

    return (
      <Modal
        title={"Delete"}
        visible={actionDialogs.delete.open}
        iconType={"danger"}
        content={
          <p style={{ maxWidth: "450px" }}>
            {t("news.sure")}{" "}
            {actionDialogs.cellData && `"${actionDialogs.cellData.title}"`}?
          </p>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose} size='large'>
              {t("billing.cancel")}
            </Button>
            <Button
              variant='primary'
              onClick={this.doDeleteNews}
              destructive
              size='large'
            >
              {t("news.del")}
            </Button>
          </ButtonGroup>
        }
        onClose={onClose}
      />
    );
  };

  render() {
    const { t, news } = this.props;
    const { user, fetchingNews } = this.props;
    const { pagination } = this.state;

    return (
      <Page
        fullWidth
        separator
        title={
          <GoBackPageNavigation content={
            <Text variant='heading3xl' as='span'>
              Blog
           </Text>
          } />
        }
        subtitle={
          <Text variant='bodyLg' as='span' color='subdued'>
            Here you can find latest news and articles written for you
          </Text>
        }
        primaryAction={
          user.is_manager && (
            <Button
              variant='primary'
              onClick={() => this.props.navigate("/dashboard/add-news")}
            >
              Add news
            </Button>
          )
        }
      >
        <PageHelmet title='Blog' />

        <Layout>
          <Layout.Section>
            {fetchingNews ? (
              <Spinner />
            ) : (
              <>
                <br />
                <div style={{ backgroundColor: 'white', padding: 30}}>
                  {!_.isEmpty(news) &&
                    news.map((newsItem) => (
                      <div key={newsItem.id} style={{marginBottom: 20}}>
                        <div style={{display: 'flex', flexWrap: 'nowrap'}}>
                          <div>
                            {newsItem?.imageSrc && (
                              <img
                                alt='news'
                                width='180px'
                                height='100%'
                                style={{
                                  borderRadius: "8px",
                                  objectFit: "cover",
                                  objectPosition: "center",
                                }}
                                src={newsItem?.imageSrc || ""}
                              />
                            )}
                          </div>
                          <div
                            vertical
                            fill='true'
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              gap='2'
                            >
                              <Text
                                variant='bodyMd'
                                color='critical'
                              >
                                {formatDate(newsItem.date)}
                              </Text >
                              <Text
                                variant='headingLg'
                                as='h2'
                              >{newsItem.title}</Text >
                              <Text >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: newsItem.description,
                                  }}
                                />
                              </Text >
                              <Link
                                className='Polaris-Link'
                                to={`/dashboard/news/${newsItem.id}`}
                                style={{textDecoration: "none"}}
                              >
                                Read more
                              </Link >
                              {user.is_manager && (
                                <div style={{display: 'flex', flexWrap: 'nowrap', width: 200}}>
                                  <Button
                                    variant='plain'
                                    destructive
                                    onClick={() =>
                                      this.handleActionDialogsOpen(
                                        "delete",
                                        newsItem
                                      )
                                    }
                                    >
                                    <span style={{color: 'red'}}>Delete</span>
                                  </Button >
                                </div>
                              )}
                            </Box >
                          </div>
                        </div>
                      </div >
                    ))}
                  {_.isEmpty(news) && <NoDataMessage title='No articles yet' />}
                </div>
                {!_.isEmpty(news) && (
                  <Box padding='4'>
                    <Pagination
                      total={pagination.count}
                      current={pagination.page}
                      pageSize={defaultLimit}
                      onChange={(current) => this.onSelectPage(current)}
                    />
                  </Box>
                )}
              </>
            )}
          </Layout.Section>
        </Layout>
        {this.renderDeleteNewsDialog()}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  news: state.dashboard.news,
  newsCount: state.dashboard.newsCount,
  fetchingNews: state.dashboard.fetchingNews,
  defaultLanguage: state.user.defaultLanguage,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchNews: (params) => dispatch(fetchNews(params)),
    deleteNews: (params) => dispatch(deleteNews(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(News))
);
