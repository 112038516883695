import * as PropTypes from 'prop-types'
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import BannerCustom from '../../components/Banners/BannerCustom'
import ArrowLeftBack from '../../img/arrow-left-for-button-back.svg'
import ArrowRight from '../../img/arrow-right-white.svg'
import { fetchAllCountries } from "../../redux/features/other/otherSlice";
import { addNewClient } from "../../redux/features/tax/taxSlice";
import { isEmail, isLatin, isPhone, isValidPassword } from "utils/validation";

import {
  Page,
  Card,
  Layout,
  Button,
  ButtonGroup,
  FormLayout,
  TextField,
  Select,
  Tag,
  ChoiceList,
  List,
  Text, Box,
} from "@shopify/polaris";
import _ from "lodash";
import Stepper from "components/Stepper";
import PageHelmet from "components/PageHelmet";
import SaveBar from "components/SaveBar/SaveBar";

import step1IMG from "img/step1.svg";
import { createUser, fetchUsers } from "../../redux/features/user/userSlice";
import { createCustomDispatch } from "helpers/customDispatch";

function Typography(props) {
  return null
}

Typography.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node
}

class AddClient extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stepIndex: 0,
      persons: [],
      country: "",
      blurList: [],

      fileNameList: {
        files: [],
      },
    };
  }

  componentDidMount() {
    this.props.fetchAllCountries();
    this.props.fetchUsers();
  }

  getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return this.renderStep1();
      case 1:
        return this.renderStep2();
      case 2:
        return this.renderStep3();
      case 3:
        return this.renderStep4();
      default:
        return "";
    }
  };

  getAvailableNextStep = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          !this.state.companyName ||
          this.state.errorCompanyName ||
          !this.state.postcode ||
          this.state.errorPostcode ||
          !this.state.country ||
          !this.state.city ||
          this.state.errorCity ||
          !this.state.street ||
          this.state.errorStreet ||
          !this.state.housenum ||
          this.state.errorHousenum ||
          !this.state.phone ||
          this.state.errorPhone ||
          !this.state.vatNum
        );
      case 1:
        return false;
      case 2:
        return (
          !this.state.name ||
          this.state.errorName ||
          !this.state.surname ||
          this.state.errorSurname
        );
      case 3:
        return _.isEmpty(this.state.persons);
      default:
        return false;
    }
  };

  doAddClient = () => {
    const {
      companyName,
      postcode,
      country,
      city,
      street,
      housenum,
      apartment,
      phone,
      vatNum,
      name,
      surname,
      persons,
    } = this.state;

    const newFormData = {
      companyName,
      postcode,
      country,
      city,
      street,
      housenum,
      apartment,
      phone,
      vatNum,
      name,
      surname,
      persons: persons.join(),
    };

    const files = this.attachFiles(this.docsFiles);
    this.props
      .addNewClient({ ...newFormData, data: files })
      .then(() => {
        this.props.navigate("/clients");
      })
      .catch(_.noop);
  };

  attachFiles = (docsFiles) => {
    // attach documents to form
    const formDataFiles = new FormData();
    for (let i = 0; i < docsFiles.files.length; i++) {
      formDataFiles.append(
        "file_" + i,
        docsFiles.files[i],
        docsFiles.files[i].name
      );
    }
    return formDataFiles;
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleNext = () => {
    const { stepIndex } = this.state;
    if (stepIndex < 4) {
      this.setState((prevState) => ({
        stepIndex: prevState.stepIndex + 1,
      }));
    }
  };

  handlePrev = () => {
    const { stepIndex } = this.state;
    if (stepIndex > 0) {
      this.setState((prevState) => ({
        stepIndex: prevState.stepIndex - 1,
      }));
    }
  };

  handleBlur = (input) => {
    const { blurList } = this.state;
    this.setState({ blurList: [...blurList, input.target.id] });
  };

  handleChange = (value, id, errorName, errorText) => {
    if (value.length > 0 && isLatin(value)) {
      this.setState({
        [id]: value,
        [errorName]: false,
      });
    } else if (value.length > 0 && !isLatin(value)) {
      this.setState({
        [id]: value,
        [errorName]: "Use the Latin alphabet!",
      });
    } else {
      this.setState({
        [id]: "",
        [errorName]: errorText,
      });
    }
  };

  handleChangePhone = (phone) => {
    const { t } = this.props;

    if (!isPhone(phone) && phone.length !== 0) {
      this.setState({
        phone,
        errorPhone: t("addClient.phoneMust"),
      });
    } else {
      this.setState({
        phone,
        errorPhone: false,
      });
    }
  };

  handleFileSelect = (docsType) => {
    const { fileNameList } = this.state;

    if (this.fileUploadInput) {
      this.fileUploadInput.click();
      this.fileUploadInput.onchange = () => {
        const files = this.fileUploadInput.files;
        for (let i = 0; i < files.length; i++) {
          this.doDeleteFile(docsType, files[i].name); // delete the same file from list, comparing inside 'doDeleteFile' function
          this.docsFiles[docsType][this.docsFiles[docsType].length] = files[i]; // add file
          fileNameList[docsType][fileNameList[docsType].length] = files[i].name; // add filename
        }
        this.setState({ fileNameList });
      };
    }
  };

  doDeleteFile = (fileListName, fileName) => {
    const { fileNameList } = this.state;
    let fileToDelete = undefined;
    _.map(this.docsFiles[fileListName], (file, index) => {
      if (file.name === fileName) {
        fileToDelete = index;
      }
    });

    if (fileToDelete !== undefined) {
      this.docsFiles[fileListName].splice(fileToDelete, 1); // delete file name the list
      fileNameList[fileListName].splice(fileToDelete, 1); // delete file name from the list
      this.setState({ fileNameList });
    }
  };

  doAddUser = () => {
    const { emailAdd, nameAdd, surnameAdd, passwordAdd } = this.state;
    if (!emailAdd || !nameAdd || !surnameAdd || !passwordAdd) {
      return;
    }
    const formData = {
      email: emailAdd,
      first_name: nameAdd,
      last_name: surnameAdd,
      password: passwordAdd,
    };
    this.props
      .createUser({ ...formData })
      .then(() => {
        this.props.fetchUsers();
        this.toggleAddUser(false);
      })
      .catch((err) => {
        this.setState({ errorAddUser: err.error });
      });
  };

  docsFiles = {
    files: [],
  };

  toggleAddUser(status = true) {
    this.setState({
      isOpenAddUser: status,
      emailErrorAdd: false,
      passwordErrorAdd: false,
      errorAddUser: false,
      nameAdd: "",
      surnameAdd: "",
      emailAdd: "",
      passwordAdd: "",
    });
  }

  validateEmail = (email, errorName) => {
    const { t } = this.props;

    if (email.length > 0 && !isEmail(email)) {
      this.setState({
        [errorName]: t("addClient.err.emailInvalid"),
      });
    } else {
      this.setState({
        [errorName]: false,
      });
    }
  };

  validatePassword = (password, errorName) => {
    const { t } = this.props;
    if (password.length > 0 && password.length < 7) {
      this.setState({
        [errorName]: t("addClient.err.passLeast"),
      });
    } else if (password.length === 0) {
      this.setState({
        [errorName]: t("addClient.err.pass"),
      });
    } else if (!isValidPassword(password)) {
      this.setState({
        [errorName]: "Use the Latin alphabet and special characters!",
      });
    } else {
      this.setState({
        [errorName]: false,
      });
    }
  };

  renderMultipleFileSelect(docType) {
    const { t } = this.props;
    const { fileNameList } = this.state;

    return (
      <div>
        <Button onClick={() => this.handleFileSelect(docType)}>
          {t("addClient.chooseFile")}
          <input
            type='file'
            multiple
            style={{ display: "none" }}
            ref={(input) => (this.fileUploadInput = input)}
          />
        </Button>
        <div>
          {_.map(fileNameList[docType], (fileName, index) => {
            return (
              <div
                style={{ display: "inline-block", margin: "5px" }}
                key={index}
              >
                <Tag
                  key={index}
                  onRemove={() => this.doDeleteFile(docType, fileName)}
                >
                  {fileName}
                </Tag>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderAddUser() {
    const {
      nameAdd,
      surnameAdd,
      emailAdd,
      emailErrorAdd,
      passwordAdd,
      passwordErrorAdd,
      errorAddUser,
    } = this.state;
    const { creating, t } = this.props;
    return (
      <Card title={t("addClient.addUser")} sectioned>
        {errorAddUser && (
          <div>
            {/*<Banner tone='critical'>{errorAddUser}</Banner>*/}
            <BannerCustom
              status={'critical'}
              message={errorAddUser}
            />
            <br />
          </div>
        )}
        <FormLayout>
          <FormLayout.Group>
            <TextField
              name='first_name'
              label={t("addClient.name")}
              value={nameAdd}
              error={!nameAdd}
              onChange={(value) => this.setState({ nameAdd: value })}
            />
            <TextField
              name='last_name'
              label={t("addClient.surname")}
              value={surnameAdd}
              error={!surnameAdd}
              onChange={(value) => this.setState({ surnameAdd: value })}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              name='email'
              label={t("addClient.email")}
              value={emailAdd}
              error={emailErrorAdd || !emailAdd}
              onChange={(value) =>
                this.setState({ emailAdd: value, emailErrorAdd: false })
              }
              onBlur={() => this.validateEmail(emailAdd, "emailErrorAdd")}
            />
            <TextField
              name='password'
              label={t("addClient.password")}
              value={passwordAdd}
              error={passwordErrorAdd || !passwordAdd}
              onChange={(value) =>
                this.setState({ passwordAdd: value, passwordErrorAdd: false })
              }
              onBlur={() =>
                this.validatePassword(passwordAdd, "passwordErrorAdd")
              }
            />
          </FormLayout.Group>
        </FormLayout>
        <br />
        <ButtonGroup>
          <Button primary loading={creating} onClick={() => this.doAddUser()}>
            {t("addClient.addUser")}
          </Button>
          <Button onClick={() => this.toggleAddUser(false)}>
            {t("addClient.cancel")}
          </Button>
        </ButtonGroup>
      </Card>
    );
  }

  renderStep1() {
    const { t } = this.props;
    const {
      companyName,
      errorCompanyName,
      postcode,
      errorPostcode,
      country,
      city,
      errorCity,
      street,
      errorStreet,
      housenum,
      errorHousenum,
      apartment,
      errorApartment,
      phone,
      errorPhone,
      vatNum,
      blurList,
    } = this.state;

    return (
      <Card title={"Place of establishment"} sectioned>
        <FormLayout>
          <TextField
            id='companyName'
            value={companyName}
            onChange={(value, id) =>
              this.handleChange(value, id, "errorCompanyName", "Required data")
            }
            label={`${t("addClient.companyName")}:`}
            type='text'
            error={
              (!companyName || errorCompanyName) &&
              blurList.includes("companyName")
            }
            onBlur={this.handleBlur}
          />

          <FormLayout.Group condensed>
            <TextField
              id='postcode'
              type='text'
              maxLength='12'
              label={`${t("addClient.postcode")}:`}
              onChange={(value, id) =>
                this.handleChange(value, id, "errorPostcode", "Required data")
              }
              value={postcode}
              error={
                (!postcode || errorPostcode) && blurList.includes("postcode")
              }
              onBlur={this.handleBlur}
            />

            <Select
              label={`${t("addClient.country")}:`}
              options={[
                { key: "null", label: "", value: "" },
                ...this.props.countries.map((item) => ({
                  key: item.code,
                  label: item.name,
                  value: item.code,
                })),
              ]}
              onChange={(value) => this.setState({ country: value })}
              value={country}
              error={!country && blurList.includes("country")}
              onBlur={this.handleBlur}
            />

            <TextField
              id='city'
              maxLength='30'
              type='text'
              label={`${t("addClient.cityTown")}:`}
              onChange={(value, id) =>
                this.handleChange(value, id, "errorCity", "Required data")
              }
              value={city}
              error={(!city || errorCity) && blurList.includes("city")}
              onBlur={this.handleBlur}
            />
          </FormLayout.Group>

          <FormLayout.Group condensed>
            <TextField
              id='street'
              maxLength='30'
              type='text'
              label={`${t("addClient.strAddress")}:`}
              onChange={(value, id) =>
                this.handleChange(value, id, "errorStreet", "Required data")
              }
              value={street}
              error={(!street || errorStreet) && blurList.includes("street")}
              onBlur={this.handleBlur}
            />

            <TextField
              id='housenum'
              maxLength='10'
              type='text'
              label={`${t("addClient.housenum")}:`}
              onChange={(value, id) =>
                this.handleChange(value, id, "errorHousenum", "Required data")
              }
              value={housenum}
              error={
                (!housenum || errorHousenum) && blurList.includes("housenum")
              }
              onBlur={this.handleBlur}
            />

            <TextField
              id='apartment'
              type='text'
              label={`${t("addClient.apt")}:`}
              onChange={(value, id) =>
                this.handleChange(value, id, "errorApartment", "Required data")
              }
              value={apartment}
              onBlur={this.handleBlur}
            />
          </FormLayout.Group>

          <FormLayout.Group>
            <TextField
              id='phone'
              type='text'
              onChange={(value) => this.handleChangePhone(value)}
              label={`${t("addClient.phone")}:`}
              value={phone}
              error={errorPhone}
              onBlur={this.handleBlur}
            />

            <TextField
              id='vatNum'
              type='text'
              onChange={(value) => this.setState({ vatNum: value })}
              label={
                country === "USA"
                  ? "Sales tax permit number"
                  : `${t("addClient.vatNum")}:`
              }
              value={vatNum}
              error={!vatNum && blurList.includes("vatNum")}
              onBlur={this.handleBlur}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card>
    );
  }

  renderStep2() {
    const { country } = this.state;

    return (
      <Card title={"Please provide us with some documents"} sectioned>
        <Box padding="4" maxWidth="500px">
          <Text as="p" variant="bodyMd">Please provide us with the following documents of the client:</Text>
          <List type='number'>
            <List.Item>Trade (Commercial) Register Extract</List.Item>
            <List.Item>{`${
              country === "USA" ? "Tax" : "VAT"
            } Certificates (for reporting countries)`}</List.Item>
            <List.Item>
              Power of Attorney (concluded between you and the client)
            </List.Item>
          </List>
          {this.renderMultipleFileSelect("files")}
        </Box>
      </Card>
    );
  }

  renderStep3() {
    const { name, errorName, surname, errorSurname, blurList } = this.state;
    const { t } = this.props;

    return (
      <Card title={"Director of the company"} sectioned>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              id='name'
              type='text'
              onChange={(value, id) =>
                this.handleChange(value, id, "errorName", "Required data")
              }
              label={`${t("addClient.name")}:`}
              value={name}
              error={(!name || errorName) && blurList.includes("name")}
              onBlur={this.handleBlur}
            />

            <TextField
              id='surname'
              type='text'
              onChange={(value, id) =>
                this.handleChange(value, id, "errorSurname", "Required data")
              }
              label={`${t("addClient.surname")}:`}
              value={surname}
              error={(!surname || errorSurname) && blurList.includes("surname")}
              onBlur={this.handleBlur}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card>
    );
  }

  renderStep4() {
    const { persons } = this.state;
    const { addingError } = this.props;

    return (
      <Card
        title={"Appoint the responsible person"}
        actions={[
          {
            content: "Add responsible person",
            onAction: () => this.toggleAddUser(true),
          },
        ]}
        sectioned
      >
        {addingError && (
          <div>
            {/*<Banner tone='critical'>{addingError}</Banner>*/}
            <BannerCustom
              status={'critical'}
              message={addingError}
            />
            
            <br />
          </div>
        )}
        <div>
          <Text variant="bodyMd" color="critical">
            Choose someone from your team or add another team member
          </Text>
        </div>
        <br />
        <FormLayout>
          <ChoiceList
            allowMultiple
            title={`Responsible person:`}
            choices={this.props.users.map((item) => ({
              key: item.user.id,
              label: `${item.user.first_name} ${item.user.last_name}`,
              value: item.user.id,
            }))}
            selected={persons}
            onChange={(value) => this.setState({ persons: value })}
          />
        </FormLayout>
      </Card>
    );
  }

  render() {
    const { stepIndex, isOpenAddUser } = this.state;
    const { adding } = this.props;

    const steps = [
      {
        title: "Step 1",
        icon: step1IMG,
      },
      {
        title: "Step 2",
        icon: step1IMG,
      },
      {
        title: "Step 3",
        icon: step1IMG,
      },
      {
        title: "Step 4",
        icon: step1IMG,
      },
    ];

    return (
      <Page
        title={
          <Text variant='heading3xl' as='span'>
            Add new client
          </Text>
        }
        separator
        fullWidth
      >
        <PageHelmet title='Add new client' />
        <Layout>
          <Layout.Section secondary>
            <Stepper
              steps={steps}
              activeStep={stepIndex}
              titleFontSize={14}
              activeColor='#E4F3FE'
              completeColor='#216DC5'
              circleFontColor='#212B36'
              defaultTitleColor='#919EAB'
              completeTitleColor='#919EAB'
              activeTitleColor='#212B36'
              completeBorderColor={"#367C41"}
              defaultBorderWidth={4}
              defaultBarColor='#ADADAD'
              size={24}
              circleFontSize={14}
            />
          </Layout.Section>
          <Layout.Section>
            <div style={{ marginBottom: 102 }}>
              {this.getStepContent(stepIndex)}
              {stepIndex === 3 && isOpenAddUser && this.renderAddUser()}
            </div>
          </Layout.Section>
        </Layout>

        <SaveBar title={"Add client"}>
          <ButtonGroup>
            <Button size={'micro'} disabled={stepIndex === 0} onClick={this.handlePrev}>
               <img src={ArrowLeftBack} alt='arrow' style={{width: 10, height:10 , marginRight:8}}/> Back
            </Button>
            <Button
              variant={'primary'}
              size={'micro'}
              loading={stepIndex === 3 && adding}
              disabled={this.getAvailableNextStep(stepIndex)}
              onClick={() =>
                stepIndex !== 3 ? this.handleNext() : this.doAddClient()
              }
            >
              Next <img src={ArrowRight} alt='arrow' style={{width: 10, height:10 , marginLeft:8}}/>
            </Button>
          </ButtonGroup>
        </SaveBar>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.other.countriesAll,
  defaultLanguage: state.user.defaultLanguage,
  users: state.user.users,
  creating: state.user.creating,
  addingError: state.tax.addingError,
  adding: state.tax.adding,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    createUser: (params) => dispatch(createUser(params)),
    fetchUsers: () => dispatch(fetchUsers()),
    addNewClient: (params) => dispatch(addNewClient(params)),
    fetchAllCountries: () => dispatch(fetchAllCountries()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(AddClient))
);
