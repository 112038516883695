import * as PropTypes from 'prop-types'
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Stepper from "components/Stepper";
import withRouter from "helpers/withRouter";
import {
  Card,
  Layout,
  Button,
  ButtonGroup,
  TextField,
  FormLayout,
  Text,
} from "@shopify/polaris";
import _ from "lodash";
import { preciseRound } from "utils/numbers";
import ArrowLeftBack from '../../../img/arrow-left-for-button-back.svg'
import ArrowRight from '../../../img/arrow-right-white.svg'
import { createDeclaration } from '../../../redux/features/declarations/declarationsSlice'
import IOSSPreview from "../Previews/IOSSPreview";
import SaveBar from "components/SaveBar/SaveBar";
import Modal from "components/Modal";
import step1IMG from "img/step1.svg";
import RenderCreatedReturnDialog from "../common/RenderCreatedReturnDialog";
import { createCustomDispatch } from "helpers/customDispatch";

function Typography(props) {
  return null
}

Typography.propTypes = {
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node
}

class IOSSReturn extends Component {
  constructor(props) {
    super(props);
    const data = {};
    props.returnData.data.map((item) => {
      if (
        !_.isEmpty(
          item.vat_rate.filter(
            (country) => country.total_sum !== 0 || country.total_vat !== 0
          )
        )
      ) {
        data[item.country_id] = item.vat_rate;
      }
    });

    this.state = {
      stepIndex: 1, // 0 - intro in Create Return
      data: data,

      actionDialogs: {
        reset: { open: false },
        created: { open: false },
      },
    };
  }

  getStepContent(stepIndex) {
    switch (stepIndex) {
      /* case 0:
            return this.step1();*/
      case 1:
        return this.step2();
      case 2:
        return this.step3();
      default:
        return "";
    }
  }

  handleNext = () => {
    const { stepIndex } = this.state;
    if (stepIndex < 2) {
      this.setState({
        stepIndex: stepIndex + 1,
        month: false,
        year: false,
      });
    }
  };

  handlePrev = () => {
    const { stepIndex } = this.state;
    if (stepIndex > 1) {
      this.setState({ stepIndex: stepIndex - 1 });
    }
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  updateInput = (country, id, vatPercent, value) => {
    let data = { ...this.state.data };
    if (!data.country) {
      data = {
        ...data,
        [country]: data[country] ? [...data[country]] : [],
      };
      data[country][id] = {
        total_sum: value,
        vat_percent: vatPercent,
        total_vat: (value * vatPercent) / 100,
      };
    } else {
      data[country][id].total_sum = value;
      data[country][id].vat_percent = vatPercent;
      data[country][id].total_vat = (value * vatPercent) / 100;
    }
    this.setState({
      data,
    });
  };

  numberInput = (
    id,
    country,
    name,
    value,
    currency,
    vatPercent,
    onChangeFunc
  ) => {
    const uniqueKey = id + country + name;
    const { data } = this.state;

    const initValue =
      data[country] && data[country][id]
        ? data[country][id].total_sum * data[country][id].vat_percent
        : value * vatPercent;

    const isSummReserve = data[id] && data[id].summ_reserve;

    return (
      <TextField
        id={uniqueKey}
        key={uniqueKey}
        name={uniqueKey}
        type='number'
        step={0.01}
        suffix={"  " + currency}
        value={
          name === "total_sum" || name === "vat_percent"
            ? `${preciseRound(value)}`
            : `${preciseRound(initValue)}`
        }
        placeholder='0.00'
        disabled={name !== "total_sum" || isSummReserve}
        onChange={(v) => {
          if (name === "total_sum") {
            onChangeFunc(country, id, vatPercent, +v);
          }
        }}
      />
    );
  };

  downloadFile(link) {
    const from = link.indexOf("/") + 1;
    const fileName = link.substring(from);
    const a = document.createElement("a");
    a.setAttribute("href", link);
    a.setAttribute("download", fileName);
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(link);
    document.body.removeChild(a);
  }

  fillReturn = (returnParams, data, finalType) => {
    const filteredData = { ...data };
    for (const key in filteredData) {
      if (this.state.data.hasOwnProperty(key)) {
        filteredData[key] = filteredData[key]
          .filter(Boolean)
          .filter((item) => item.total_sum !== 0);
        if (_.isEmpty(filteredData[key])) {
          delete filteredData[key];
        }
      }
    }

    const params = {
      ...returnParams,
      data: filteredData,
    };

    if (finalType) {
      params[finalType] = true;
    }

    this.props
      .createDeclaration(params)
      .then((result) => {
        if (finalType) {
          this.setState({
            addingResult: result || true,
            addingError: null,
          });
          if (finalType === "report") {
            this.downloadFile(result);
          } else {
            this.handleActionDialogsOpen("created", { finalType });
          }
        } else {
          this.setState({
            previewResult: result,
            addingError: null,
          });
          this.handleNext();
        }
      })
      .catch((result) => {
        this.handleActionDialogsOpen("created", {});
        this.setState({
          addingError: result,
        });
      });
  };

  step2() {
    const { returnParams, returnData, creating, t } = this.props;
    const { data } = this.state;

    return (
      <div>
        {this.renderSummary()}
        <Card sectioned>
          <FormLayout>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <div style={{ width: "20%" }}>
                <Text as="h6" variant="headingMd"  fontWeight="bold">{"Country"}</Text>
              </div>
              <div style={{ width: "15%", textAlign: "center" }}>
                <Text as="h6" variant="headingMd"  fontWeight="bold">
                  {"Revenue"} ({returnData.currency})
                </Text>
              </div>
              <div style={{ width: "30%", textAlign: "center" }}>
                <Text as="h6" variant="headingMd"  fontWeight="bold">VAT rate</Text>
              </div>
              <div style={{ width: "30%", textAlign: "center" }}>
                <Text as="h6" variant="headingMd"  fontWeight="bold">
                  {t("createReturns.vatSum")}
                </Text>
              </div>
            </div>

            {returnData.data.map((item, i) => (
              <>
                {item.vat_rate.map((rate, ind) => {
                  const initTotalSum =
                    data[item.country_id] &&
                    data[item.country_id][ind] &&
                    data[item.country_id][ind].total_sum;
                  const initVatSum =
                    data[item.country_id] &&
                    data[item.country_id][ind] &&
                    (data[item.country_id][ind].total_sum *
                      data[item.country_id][ind].vat_percent) /
                      100;
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                        marginBottom: "1rem",
                      }}
                    >
                      <div style={{ width: "20%" }}>
                        {ind === 0 ? (
                          <Text variant="bodyMd" color="critical">
                            {item.country}
                          </Text>
                        ) : (
                          <span />
                        )}
                      </div>

                      <div style={{ width: "15%" }}>
                        {this.numberInput(
                          ind,
                          item.country_id,
                          "total_sum",
                          initTotalSum || initTotalSum === 0
                            ? initTotalSum
                            : rate.total_sum,
                          returnData.currency,
                          rate.vat_percent,
                          this.updateInput
                        )}
                      </div>

                      <div style={{ width: "30%" }}>
                        {this.numberInput(
                          ind,
                          item.country_id,
                          "vat_percent",
                          rate.vat_percent,
                          "%"
                        )}
                      </div>

                      <div style={{ width: "30%" }}>
                        {this.numberInput(
                          ind,
                          item.country_id,
                          "vat_percent",
                          initVatSum || initVatSum === 0
                            ? initVatSum
                            : rate.total_vat,
                          returnData.currency
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            ))}
          </FormLayout>
        </Card>

        <SaveBar title={t("createReturns.createVR")}>
          <ButtonGroup>
            <Button
              size={'micro'}
              disabled={creating}
              onClick={() => this.handleActionDialogsOpen("reset", {})}
            >
               <img src={ArrowLeftBack} alt='arrow' style={{width: 10, height:10 , marginRight:8}}/>{t("createReturns.back")}
            </Button>
            <Button
              size={'micro'}
              variant={'primary'}
              loading={creating}
              onClick={() => this.fillReturn(returnParams, data)}
            >
              {t("createReturns.next")}<img src={ArrowRight} alt='arrow' style={{width: 10, height:10 , marginLeft:8}}/>
            </Button>
          </ButtonGroup>
        </SaveBar>
      </div>
    );
  }

  step3() {
    const { returnParams, creating, t } = this.props;
    const { previewResult, data } = this.state;

    return (
      <div>
        <IOSSPreview
          sum={previewResult.low_value_goods}
          vat={previewResult.vat_sum}
          currency={previewResult.currency}
          data={previewResult.data}
        />

        <SaveBar title={t("createReturns.createVR")}>
          <ButtonGroup>
            <Button  size={'micro'} disabled={creating} onClick={() => this.handlePrev()}>
               <img src={ArrowLeftBack} alt='arrow' style={{width: 10, height:10 , marginRight:8}}/>{t("createReturns.back")}
            </Button>
            <Button
              variant={'primary'}
              size={'micro'}
              disabled={creating}
              onClick={() => this.fillReturn(returnParams, data, "submit")}
            >
              {"Submit"}
            </Button>
            <Button
              variant={'primary'}
              size={'micro'}
              disabled={creating}
              onClick={() => this.fillReturn(returnParams, data, "save")}
            >
              {"Save"}
            </Button>
            <Button
              variant={'primary'}
              disabled={creating}
              size={'micro'}
              onClick={() => this.fillReturn(returnParams, data, "report")}
            >
              {"Download report"}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </div>
    );
  }

  renderResetDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={t("createReturns.warning")}
        visible={actionDialogs.reset.open}
        onClose={() => this.handleActionDialogsClose("reset")}
        contentOnCenter
        iconType={"warning"}
        description={
          <>
            <p>{t("createReturns.willLose")}</p>
            <p>{t("createReturns.wantComeBack")}</p>
          </>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => {
                this.props.reset();
                this.handleActionDialogsClose("reset");
              }}
            >
              {t("createReturns.toTheFirst")}
            </Button>
          </div>
        }
      />
    );
  }

  renderCreatedReturnDialog() {
    return (
      <RenderCreatedReturnDialog
        state={this.state}
        declarationType={this.props.title}
        handleActionDialogsClose={this.handleActionDialogsClose}
      />
    );
  }

  renderSummary = () => {
    const { returnData } = this.props;
    const { data } = this.state;

    let sum = returnData.low_value_goods;
    let vat = returnData.vat_sum;

    if (!_.isEmpty(data)) {
      sum = []
        .concat(...Object.values(data))
        .filter(Boolean)
        .map((obj) => obj.total_sum)
        .reduce((prev, current) => prev + current, 0);

      vat = []
        .concat(...Object.values(data))
        .filter(Boolean)
        .map((obj) => (obj.total_sum * obj.vat_percent) / 100)
        .reduce((prev, current) => prev + current, 0);
    }

    return <IOSSPreview sum={sum} vat={vat} currency={returnData.currency} />;
  };

  render() {
    const { stepIndex } = this.state;
    const { t, title } = this.props;

    const steps = [
      {
        title: t("createReturns.returnParams"),
        icon: step1IMG,
      },
      {
        title: t("createReturns.outputVat"),
        icon: step1IMG,
      },
      {
        title: t("createReturns.vatDue"),
        icon: step1IMG,
      },
    ];

    return (
      <div style={{marginTop: 32}}>
        <Layout>
          <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%', padding: '0 0 0 30px'}}>
            {this.state.stepIndex > 0 && (
              <Stepper
                steps={steps}
                activeStep={stepIndex}
                titleFontSize={14}
                activeColor={'#E4F3FE'}
                completeColor={'#216DC5'}
                circleFontColor={'#212B36'}
                defaultTitleColor={'rgba(0, 0, 0, 1)'}
                completeTitleColor={'rgba(0, 0, 0, 1)'}
                activeTitleColor={'#216DC5'}
                completeBorderColor={"#367C41"}
                defaultBorderWidth={2}
                defaultBarColor={'#ADADAD'}
                size={24}
                circleFontSize={14}
              />
            )}
           <div style={{width: '70%', marginBottom: 102}}>
              <Text variant='headingMd'>{title}</Text>
              <br />
              {this.getStepContent(stepIndex)}
            </div>
          </div>
        </Layout>

        {this.renderResetDialog()}
        {this.renderCreatedReturnDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  defaultLanguage: state.user.defaultLanguage,
  creating: state.declarations.creating,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    createDeclaration: (params) => dispatch(createDeclaration(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(IOSSReturn))
);
