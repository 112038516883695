import * as PropTypes from 'prop-types'
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Layout,
  Button,
  TextField,
  ButtonGroup,
  Card,
  FormLayout,
  Select,
  DataTable,
  Text,
} from "@shopify/polaris";
import withRouter from "helpers/withRouter";
import SaveBar from "components/SaveBar/SaveBar";
import Stepper from "components/Stepper";
import { Link } from "react-router-dom";
import Modal from "components/Modal";
import ArrowLeftBack from '../../../img/arrow-left-for-button-back.svg'
import ArrowRight from '../../../img/arrow-right-white.svg'
import { checkVatNumbers, createDeclaration } from '../../../redux/features/declarations/declarationsSlice'
import { fetchCountriesEU } from '../../../redux/features/other/otherSlice'
import {
  addSalesList,
  deleteSalesListById,
  fetchSalesListFields
} from '../../../redux/features/transactions/transactionsSlice'
import VatReturnROWPreview from "../Previews/VatReturnROWPreview";
import { formatMoney, preciseRound } from "utils/numbers";
import moment from "moment";
import _ from "lodash";

import step1IMG from "img/step1.svg";
import formatRow from "utils/formatRow";
import RenderCreatedReturnDialog from "../common/RenderCreatedReturnDialog";
import { createCustomDispatch } from "helpers/customDispatch";

function Typography(props) {
  return null
}

Typography.propTypes = {
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node
}

class VatReturnROW extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stepIndex: 1, // 0 - intro in Create Return
      // stepIndexLimit,
      salesOfServices: props.returnData.data.sales_of_services_oss,
      salesOfServicesCorrected:
        props.returnData.data.sales_of_services_corrected,

      correctedServices: {},

      actionDialogs: {
        reset: { open: false },
        created: { open: false },
        subscription: { open: false },
      },
    };
  }

  componentDidMount() {
    this.props.fetchCountriesEU();
  }

  getStepContent(stepIndex) {
    const {
      salesOfServices,
      salesOfServicesCorrected,

      correctedServices,
    } = this.state;

    switch (stepIndex) {
      case 1:
        return this.step2(
          "sales_of_services_oss",
          salesOfServices,
          "salesOfServices"
        );
      case 2:
        return this.step3(
          ["Year", "Quarter", "Arrival country", "Vat rate", "Amount", "Vat"],
          ["year", "quarter", "arrival_country", "vat_percent", "amount", "vat"],
          salesOfServicesCorrected,
          "salesOfServicesCorrected",
          correctedServices,
          "correctedServices",
          "sumOfServicesCorrected",
          "vatOfServicesCorrected"
        );
      case 3:
        return this.step4();
      default:
        return "";
    }
  }

  getFilteredData = (data) => {
    const filteredData = [...data].filter((item) => item.summ);

    return filteredData;
  };

  getAvailibleCountries = () => {
    const { returnData } = this.props;
    const countries = [
      {
        value: "",
        label: "",
      },
      ...Object.keys(returnData.dict_of_countries).map((code) => ({
        value: code,
        label: returnData.dict_of_countries[code],
      })),
    ];
    return _.sortBy(countries, (country) => country.value);
  };

  getPreviewData = () => {
    const { salesOfServices, salesOfServicesCorrected } = this.state;

    const data = [];
    const countries = this.getAvailibleCountries();

    countries.map((country, ind) => {
      data[ind] = {};
      data[ind].country_code = country.value;
      data[ind].country_name = country.label;
      data[ind].sales_of_services = salesOfServices.filter(
        (item) => item.summ && item.arrival_country === country.value
      );
      data[ind].sales_of_services_corrected = salesOfServicesCorrected.filter(
        (item) => item.summ && item.arrival_country === country.value
      );

      return null;
    });

    return data;
  };

  styles = {
    tableCell: {
      padding: "1.5rem 0 1.5rem 2rem",
      overflow: "visible",
      width: "20%",
    },
  };

  numberInput = (
    data,
    keyData,
    id,
    country,
    name,
    value,
    suffix,
    vatPercent,
    onChangeFunc
  ) => {
    const uniqueKey = id + country + name;

    const initValue =
      data[keyData] && data[keyData][id]
        ? data[keyData][id].summ * data[keyData][id].vat_percent
        : value * vatPercent;

    const isSummReserve = data[id] && data[id].summ_reserve;

    return (
      <TextField
        id={uniqueKey}
        key={uniqueKey}
        name={uniqueKey}
        type='number'
        step={0.01}
        suffix={"  " + suffix}
        disabled={name !== "summ" || isSummReserve}
        value={
          name === "summ" || name === "vat_percent"
            ? `${preciseRound(value)}`
            : `${preciseRound(initValue)}`
        }
        placeholder='0.00'
        onChange={(v) => {
          if (name === "summ") {
            onChangeFunc(keyData, id, vatPercent, +v);
          }
        }}
      />
    );
  };

  updateInput = (keyData, id, vatPercent, value) => {
    const data = [...this.state[keyData]];

    data[id].summ = value;
    data[id].vat_percent = vatPercent;
    data[id].vat = (value * vatPercent) / 100;

    switch (keyData) {
      case "salesOfServices":
        this.updateSummary(data, "sumOfServices", "vatOfServices");
        break;
      case "salesOfServicesCorrected":
        this.updateSummary(
          data,
          "sumOfServicesCorrected",
          "vatOfServicesCorrected"
        );
        break;
      default:
        break;
    }

    this.setState({
      [keyData]: data,
    });
  };

  checkErrors = (data) => {
    const errArray = data.filter((item) => item.err);
    return errArray.length > 0;
  };

  downloadFile(link) {
    const from = link.indexOf("/") + 1;
    const fileName = link.substring(from);
    const a = document.createElement("a");
    a.setAttribute("href", link);
    a.setAttribute("download", fileName);
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(link);
    document.body.removeChild(a);
  }

  updateSummary = (data, keySum, keyVat) => {
    if (!_.isEmpty(data)) {
      const sum = data
        .map((obj) => obj.summ)
        .reduce((prev, current) => prev + current, 0);

      const vat = data
        .map((obj) => (obj.summ * obj.vat_percent) / 100)
        .reduce((prev, current) => prev + current, 0);

      this.setState({
        [keySum]: sum,
        [keyVat]: vat,
      });
    }
  };

  fillReturn = (finalType) => {
    const { returnParams } = this.props;
    const { salesOfServices, salesOfServicesCorrected } = this.state;

    const params = {
      ...returnParams,
      sales_of_services_oss: this.getFilteredData(salesOfServices),
      sales_of_services_corrected: this.getFilteredData(
        salesOfServicesCorrected
      ),
    };

    if (finalType) {
      params[finalType] = true;
    }

    this.props
      .createDeclaration(params)
      .then((result) => {
        if (finalType) {
          this.setState({
            addingResult: result || true,
            addingError: null,
          });
          if (finalType === "report") {
            this.downloadFile(result);
          } else {
            this.handleActionDialogsOpen("created", { finalType });
          }
        } else {
          this.setState({
            previewResult: result,
            addingError: null,
          });
          this.handleNext();
        }
      })
      .catch((result) => {
        this.handleActionDialogsOpen("created", {});
        this.setState({
          addingError: result,
        });
      });
  };

  handleNext = () => {
    const { returnParams } = this.props;
    const countryId = this.props.returnParams.customer_country_id;
    const { stepIndex } = this.state;
    let maxStep = countryId === "CAN" ? 3 : 4;

    if (
      returnParams.type_of_report === 1 &&
      returnParams.period_type === "year"
    ) {
      maxStep = countryId === "CAN" ? 4 : 5;
    }

    if (returnParams.type_of_report === 2) {
      maxStep = 2;
    }

    if (stepIndex < maxStep) {
      this.setState({
        stepIndex: stepIndex + 1,
        month: false,
        year: false,
      });
    }
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handlePrev = () => {
    const { stepIndex } = this.state;
    if (stepIndex > 1) {
      this.setState({
        stepIndex: stepIndex - 1,
      });
    }
  };

  doDeleteRow = (key, index, keySum, keyVat) => {
    this.setState(
      (prevState) => {
        const data = [...prevState[key]];
        data.splice(index, 1);
        return {
          [key]: data,
        };
      },
      () => {
        const data = [...this.state[key]];
        data.splice(index, 1);
        this.updateSummary(data, keySum, keyVat);
      }
    );
  };

  step2(key, data, name) {
    const { returnData } = this.props;

    return (
      <div>
        {this.renderSummary()}
        <Card sectioned>
          <FormLayout>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <div style={{ width: "20%" }}>
                <Text as="h6" variant="headingMd"  fontWeight="bold">{"Arrival country"}</Text>
              </div>
              <div style={{ width: "15%" }}>
                <Text as="h6" variant="headingMd"  fontWeight="bold">Vat rate</Text>
              </div>
              <div style={{ width: "30%" }}>
                <Text as="h6" variant="headingMd"  fontWeight="bold">
                  {"Amount"} ({returnData.currency})
                </Text>
              </div>
              <div style={{ width: "30%" }}>
                <Text as="h6" variant="headingMd"  fontWeight="bold">{"Vat"}</Text>
              </div>
            </div>

            {returnData.data[key].map((item, ind) => {
              const initTotalSum = data[ind].summ;
              const initVatSum = (data[ind].summ * data[ind].vat_percent) / 100;

              return (
                <div
                  key={`${ind}row`}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    marginBottom: "1rem",
                  }}
                >
                  <div style={{ width: "20%" }}>
                    {(ind === 0 ||
                      returnData.data[key][ind - 1].arrival_country !==
                        item.arrival_country) && (
                      <Text variant="bodyMd" color="critical">
                        {item.arrival_country}
                      </Text>
                    )}
                  </div>

                  <div style={{ width: "15%" }}>
                    {this.numberInput(
                      data,
                      name,
                      ind,
                      item.arrival_country,
                      "vat_percent",
                      item.vat_percent,
                      "%"
                    )}
                  </div>

                  <div style={{ width: "30%" }}>
                    {this.numberInput(
                      data,
                      name,
                      ind,
                      item.arrival_country,
                      "summ",
                      initTotalSum || initTotalSum === 0
                        ? initTotalSum
                        : item.summ,
                      returnData.currency,
                      item.vat_percent,
                      this.updateInput
                    )}
                  </div>

                  <div style={{ width: "30%" }}>
                    {this.numberInput(
                      data,
                      name,
                      ind,
                      item.arrival_country,
                      "vat_percent",
                      initVatSum || initVatSum === 0 ? initVatSum : item.vat,
                      returnData.currency
                    )}
                  </div>
                </div>
              );
            })}
          </FormLayout>
        </Card>
      </div>
    );
  }

  step3 = (
    titles,
    fields,
    data,
    dataKey,
    dataAdding,
    dataAddingKey,
    keySum,
    keyVat
  ) => {
    const { returnData, t } = this.props;

    const columns = [
      {
        property: "year",
        header: {
          label: "Year",
        },
      },
      {
        property: "quarter",
        header: {
          label: "Quarter",
        },
      },
      {
        property: "arrival_country",
        header: {
          label: "Arrival country",
        },
        cell: {
          formatters: [(value) => <>{returnData.dict_of_countries[value]}</>],
        },
      },
      {
        property: "vat_percent",
        header: {
          label: "Vat rate",
        },
      },
      {
        property: "summ",
        header: {
          label: "Amount",
        },
        cell: {
          formatters: [(value) => <>{formatMoney(value)}</>],
        },
      },
      {
        property: "vat",
        header: {
          label: "Vat",
        },
        cell: {
          formatters: [(value) => <>{formatMoney(value)}</>],
        },
      },
    ];

    const rows = formatRow(data, columns);

    return (
      <React.Fragment>
        {this.renderSummary()}
        <Card>
          <DataTable
            columnContentTypes={["text", "text", "text", "text", "text"]}
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold'>{header.label}</Text>
            ))}
            rows={rows}
            hideScrollIndicator
          />
        </Card>
        <Card sectioned>
          <form>
            <FormLayout>
              <FormLayout.Group>
                {fields.some((field) => field === "year") && (
                  <Select
                    name='year'
                    label={"Year"}
                    options={[
                      "",
                      moment().year() - 2,
                      moment().year() - 1,
                      moment().year(),
                      moment().year() + 1,
                      moment().year() + 2,
                    ].map((item) => ({
                      value: item.toString(),
                      label: item.toString(),
                    }))}
                    value={dataAdding.year || ""}
                    onChange={(value) =>
                      this.setState((prevState) => ({
                        [dataAddingKey]: {
                          ...prevState[dataAddingKey],
                          year: value,
                        },
                      }))
                    }
                  />
                )}
                {fields.some((field) => field === "quarter") && (
                  <Select
                    name='quarter'
                    label={"Quarter"}
                    options={["", 1, 2, 3, 4].map((item) => ({
                      value: item.toString(),
                      label: item ? `${item} quarter` : "",
                    }))}
                    value={dataAdding.quarter || ""}
                    onChange={(value) =>
                      this.setState((prevState) => ({
                        [dataAddingKey]: {
                          ...prevState[dataAddingKey],
                          quarter: value,
                        },
                      }))
                    }
                  />
                )}
                {fields.some((field) => field === "departure_country") && (
                  <Select
                    name='dep_country'
                    label={"Departure country"}
                    options={this.getAvailibleCountries()}
                    value={dataAdding.departure_country || ""}
                    onChange={(value) =>
                      this.setState((prevState) => ({
                        [dataAddingKey]: {
                          ...prevState[dataAddingKey],
                          departure_country: value,
                        },
                      }))
                    }
                  />
                )}
                {fields.some((field) => field === "arrival_country") && (
                  <Select
                    name='arr_country'
                    label={"Arrival country"}
                    options={this.getAvailibleCountries()}
                    value={dataAdding.arrival_country || ""}
                    onChange={(value) =>
                      this.setState((prevState) => ({
                        [dataAddingKey]: {
                          ...prevState[dataAddingKey],
                          arrival_country: value,
                        },
                      }))
                    }
                  />
                )}
                {fields.some((field) => field === "vat_percent") && (
                  <Select
                    name='vat_rate'
                    label={"Vat rate"}
                    disabled={!dataAdding.arrival_country}
                    options={
                      dataAdding.arrival_country
                        ? [
                            {
                              value: "",
                              label: "",
                            },
                            ...returnData.vat_rates[
                              dataAdding.arrival_country
                            ].map((vatRate) => ({
                              value: vatRate.toString(),
                              label: vatRate,
                            })),
                          ]
                        : []
                    }
                    value={
                      dataAdding.vat_percent
                        ? dataAdding.vat_percent.toString()
                        : ""
                    }
                    onChange={(value) =>
                      this.setState((prevState) => ({
                        [dataAddingKey]: {
                          ...prevState[dataAddingKey],
                          vat_percent: +value,
                        },
                      }))
                    }
                  />
                )}
                {fields.some((field) => field === "summ") && (
                  <TextField
                    name='summ'
                    label={"Amount"}
                    type='number'
                    disabled={!dataAdding.vat_percent}
                    step={0.01}
                    suffix={"  " + returnData.currency}
                    value={dataAdding.summ ? dataAdding.summ.toString() : ""}
                    placeholder='0.00'
                    onChange={(value) =>
                      this.setState((prevState) => ({
                        [dataAddingKey]: {
                          ...prevState[dataAddingKey],
                          summ: +value,
                          vat: preciseRound(
                            value * (prevState[dataAddingKey].vat_percent / 100)
                          ),
                        },
                      }))
                    }
                  />
                )}
                {fields.some((field) => field === "vat") && (
                  <TextField
                    name='vat'
                    label={"Vat"}
                    type='number'
                    step={0.01}
                    suffix={"  " + returnData.currency}
                    value={dataAdding.vat ? dataAdding.vat.toString() : ""}
                    disabled
                  />
                )}
                <br />
              </FormLayout.Group>
            </FormLayout>
            <br />
            <Button
              disabled={!fields.every((field) => dataAdding[field])}
              variant={'primary'}
              size={'micro'}
              onClick={() => {
                this.setState((prevState) => ({
                  [dataKey]: [...prevState[dataKey], dataAdding],
                  [dataAddingKey]: {},
                }));
                this.updateSummary([...data, dataAdding], keySum, keyVat);
              }}
            >
              {t("createReturns.addRecord")}
            </Button>
          </form>
        </Card>
      </React.Fragment>
    );
  };

  step4() {
    const { creating, t } = this.props;

    return (
      <div>
        {this.renderSummary()}

        <SaveBar title={t("createReturns.createVR")}>
          <ButtonGroup>
            <Button size={'micro'}  onClick={() => this.handlePrev()}>
               <img src={ArrowLeftBack} alt='arrow' style={{width: 10, height:10 , marginRight:8}}/>{t("createReturns.back")}
            </Button>
            <Button
              size={'micro'}
              variant={'primary'}
              disabled={creating}
              onClick={() => this.fillReturn("submit")}
            >
              {"Submit"}
            </Button>
            <Button
              size={'micro'}
              variant={'primary'}
              disabled={creating}
              onClick={() => this.fillReturn("save")}
            >
              {"Save"}
            </Button>
            <Button
              size={'micro'}
              variant={'primary'}
              disabled={creating}
              onClick={() => this.fillReturn("report")}
            >
              {"Download report"}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </div>
    );
  }

  renderSummary = () => {
    const { returnData } = this.props;
    const {
      sumOfServices,
      sumOfServicesCorrected,
      vatOfServices,
      vatOfServicesCorrected,
      stepIndex,
    } = this.state;

    const lastStep = 3;

    const salesOfServicesValue =
      returnData.sales_of_services === undefined
        ? returnData.sales_of_services_oss
        : returnData.sales_of_services;
    const vatOfServicesValue =
      returnData.vat_of_services === undefined
        ? returnData.vat_of_services_oss
        : returnData.vat_of_services;

    return (
      <VatReturnROWPreview
        sumOfServices={sumOfServices || salesOfServicesValue}
        sumOfServicesCorrected={
          sumOfServicesCorrected || returnData.sales_of_services_corrected
        }
        vatOfServices={vatOfServices || vatOfServicesValue}
        vatOfServicesCorrected={
          vatOfServicesCorrected || returnData.vat_of_services_corrected
        }
        step={stepIndex}
        currency={returnData.currency}
        data={stepIndex === lastStep ? this.getPreviewData() : null}
      />
    );
  };

  renderSubscriptionDialog = () => {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={t("createReturns.warning")}
        visible={actionDialogs.subscription.open}
        onClose={() => {
          this.handleActionDialogsClose("subscription");
        }}
        content={
          <p>
            {t("createReturns.planOver")} (
            {
              <Link className='Polaris-Link' to='/subscription'>
                {t("createReturns.selectPlan")}
              </Link>
            }
            ).
            <br />
            {t("createReturns.contUsing")}
          </p>
        }
      ></Modal>
    );
  };

  renderResetDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={t("createReturns.warning")}
        visible={actionDialogs.reset.open}
        onClose={() => this.handleActionDialogsClose("reset")}
        contentOnCenter
        iconType={"warning"}
        description={
          <>
            <p>{t("createReturns.willLose")}</p>
            <p>{t("createReturns.wantComeBack")}</p>
          </>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => {
                this.props.reset();
                this.handleActionDialogsClose("reset");
              }}
            >
              {t("createReturns.toTheFirst")}
            </Button>
          </div>
        }
      />
    );
  }

  renderCreatedReturnDialog() {
    return (
      <RenderCreatedReturnDialog
        state={this.state}
        declarationType={this.props.title}
        handleActionDialogsClose={this.handleActionDialogsClose}
      />
    );
  }

  render() {
    const { creating, t, title } = this.props;
    const { stepIndex } = this.state;

    const steps = [
      {
        title: t("createReturns.returnParams"),
        icon: step1IMG,
      },
      {
        title: "Sales of services",
        icon: step1IMG,
      },
      {
        title: "Sales of services corrected",
        icon: step1IMG,
      },
      {
        title: "Summary",
        icon: step1IMG,
      },
    ];

    const lastStep = steps.length - 1;

    return (
      <div style={{marginTop: 32}}>
        <Layout>
          <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%', padding: '0  0 0 30px'}}>
            {this.state.stepIndex > 0 && (
              <Stepper
                steps={steps}
                activeStep={stepIndex}
                titleFontSize={14}
                activeColor={'#E4F3FE'}
                completeColor={'#216DC5'}
                circleFontColor={'#212B36'}
                defaultTitleColor={'rgba(0, 0, 0, 1)'}
                completeTitleColor={'rgba(0, 0, 0, 1)'}
                activeTitleColor={'#216DC5'}
                completeBorderColor={"#367C41"}
                defaultBorderWidth={2}
                defaultBarColor={'#ADADAD'}
                size={24}
                circleFontSize={14}
              />
              )}
            <div style={{width: '70%', marginBottom: 102}}>
              <Text variant='headingMd'>{title}</Text>
              <br />
              {this.getStepContent(stepIndex)}
            </div>
          </div>
        </Layout>

        {stepIndex < lastStep && (
          <SaveBar title={t("createReturns.createVR")}>
            <ButtonGroup>
              <Button
                size={'micro'}
                disabled={creating}
                onClick={
                  stepIndex === 1
                    ? () => this.handleActionDialogsOpen("reset", {})
                    : this.handlePrev
                }
              >
                <img src={ArrowLeftBack} alt='arrow' style={{width: 10, height:10 , marginRight:8}}/> {t("createReturns.back")}
              </Button>
              <Button
                size={'micro'}
                variant={'primary'}
                disabled={creating}
                onClick={() => this.fillReturn("save_draft")}
              >
                {"Save"}
              </Button>
              <Button  size={'micro'} variant={'primary'} loading={creating} onClick={this.handleNext}>
                {t("createReturns.next")}<img src={ArrowRight} alt='arrow' style={{width: 10, height:10 , marginLeft:8}}/>
              </Button>
            </ButtonGroup>
          </SaveBar>
        )}

        {this.renderResetDialog()}
        {this.renderCreatedReturnDialog()}
        {this.renderSubscriptionDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  countriesEU: state.other.countriesEU,
  defaultLanguage: state.user.defaultLanguage,
  creating: state.declarations.creating,
  salesListImport: state.transactions.salesListImport,
  checking: state.declarations.checking,
  checkedNumbers: state.declarations.checkedNumbers,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    createDeclaration: (params) => dispatch(createDeclaration(params)),
    fetchSalesListFields: () => dispatch(fetchSalesListFields()),
    addSalesList: (params) => dispatch(addSalesList(params)),
    deleteSalesListById: (id) => dispatch(deleteSalesListById(id)),
    checkVatNumbers: (params) => dispatch(checkVatNumbers(params)),
    fetchCountriesEU: () => dispatch(fetchCountriesEU()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(VatReturnROW))
);
