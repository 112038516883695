import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  Page,
  Card,
  Layout,
  TextField,
  Button,
  Badge,
  Banner,
  Text,
  Box,
  DataTable,
} from "@shopify/polaris";
import {
  fetchBalance,
  fetchReferralInv,
  fetchToken,
  inviteByEmail,
} from "../../../redux/features/payment/paymentSlice";
import _ from "lodash";

import widgetIMG from "img/widget.svg";
import twoCardsIMG from "img/twoCreditCards.svg";
import formatRow from "utils/formatRow";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import { getCurrencySymbol } from "utils/currency";
import { createCustomDispatch } from "helpers/customDispatch";

class ReferralProgram extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortingColumns: [],
      email: "",

      personalInviteLink: "",
    };
  }

  componentDidMount() {
    this.props.fetchReferralInv();
    this.props.fetchToken().then(({ token }) => {
      this.setState({
        personalInviteLink: `https://merchant.vatcompliance.co/signup/${token}`,
      });
    });
    this.props.fetchBalance();
  }

  afterInviteByEmail() {
    this.setState({
      invitingSuccess: true,
      copySuccess: false,
      email: "",
      invitingError: false,
    });
    this.props.fetchReferralInv();
  }

  doInviteByEmail = (email) => {
    const data = {
      email,
    };
    this.props
      .inviteByEmail({ data })
      .then(() => {
        this.afterInviteByEmail();
      })
      .catch((err) => {
        this.setState({ invitingError: err.error });
      });
  };

  doCopyToClipboard = () => {
    const { personalInviteLink } = this.state;

    navigator.clipboard
      .writeText(personalInviteLink)
      .then(() => this.setState({ copySuccess: true }));
  };

  render() {
    const {
      email,
      sortingColumns,
      invitingError,
      copySuccess,
      invitingSuccess,
      personalInviteLink,
    } = this.state;
    const { referralInv, balance, inviting, t } = this.props;
    const rows = referralInv;

    const columns = [
      {
        property: "date",
        header: {
          label: "Date",
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <Text color='subdued' variant='bodySm'>
                {value}
              </Text>
            ),
          ],
        },
      },
      {
        property: "email",
        header: {
          label: t("referral.email"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <Text color='subdued' variant='bodySm'>
                {value}
              </Text>
            ),
          ],
        },
      },
      {
        property: "number",
        header: {
          label: "Number",
        },
      },
      {
        property: "earned",
        header: {
          label: "Earned",
        },
        cell: {
          formatters: [(value, { rowData }) => (value ? <>€ {value}</> : "–")],
        },
      },
      {
        property: "status",
        header: {
          label: t("referral.status"),
        },
        cell: {
          formatters: [
            (value) => {
              let status = "";
              switch (value) {
                case "sent":
                  status = "info";
                  break;
                case "confirmed":
                  status = "success";
                  break;
                default:
                  status = "attention";
              }

              return <Badge tone={status}>{value}</Badge>;
            },
          ],
        },
      },
    ];

    const sortedRows = formatRow(rows, columns);

    return (
      <Page fullWidth>
        <Layout>
          <Layout.Section fullWidth>
            {invitingSuccess && (
              <div>
                <Banner
                  onDismiss={() => this.setState({ invitingSuccess: false })}
                  tone='success'
                >
                  {t("referral.invSent")}
                </Banner>
              </div>
            )}
            {copySuccess && (
              <div>
                <Banner
                  onDismiss={() => this.setState({ copySuccess: false })}
                  tone='success'
                >
                  Copied to clipboard!
                </Banner>
              </div>
            )}
          </Layout.Section>
          <Layout.Section twoThird>
           <Box
             display="flex"
             flexDirection="column"
             gap="2"
             width="100%"
           >
              <TextField
                fullWidth
                label={<Text fontWeight='bold'>{t("referral.copy")}</Text>}
                value={personalInviteLink}
                readOnly
                selectTextOnFocus
                connectedRight={
                  <Button
                    variant='primary'
                    size='micro'
                    loading={inviting}
                    onClick={() => this.doCopyToClipboard()}
                  >
                    Copy
                  </Button>
                }
              />
             <br/>
              <TextField
                label={
                  <Text fontWeight='bold'>{t("referral.inviteEmail")}</Text>
                }
                value={email}
                type='email'
                error={invitingError}
                connectedRight={
                  <Button
                    variant={'primary'}
                    loading={inviting}
                    size='micro'
                    onClick={() => this.doInviteByEmail(email)}
                  >
                    {t("referral.invite")}
                  </Button>
                }
                onChange={(value) => this.setState({ email: value })}
              />
            </Box>
          </Layout.Section>
          <Layout.Section oneThird>
            <div  className={'block-white'}>
              <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
              >
                <div
                  style={{display: 'flex', flexDirection: 'row', gap: '2', alignItems: 'center'}}
                >
                  <img src={widgetIMG} style={{width: 40, marginRight: 10}} alt='widget icon' />
                  <Box>
                    <Text fontWeight='bold'>{t("referral.balance")}</Text>
                    <Text>
                      {getCurrencySymbol(balance.currency)}
                      {balance.balance || "0"}{" "}
                    </Text>
                  </Box>
                </div>
                <img src={twoCardsIMG} alt='twoCards icon' style={{width: 50}} />
              </div>
              <Text variant='bodySm' color='subdued'>
                {t("referral.spend")}
              </Text>
            </div>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <DataTable
                columnContentTypes={["text", "text", "text", "text", "text"]}
                headings={columns.map(({ header }) => (
                  <Text fontWeight='semibold'>{header.label}</Text>
                ))}
                rows={sortedRows}
              />

              {_.isEmpty(rows) && (
                <NoDataMessage
                  description={"Invite your friends to see data"}
                />
              )}
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  referralInv: state.payment.referralInv,
  token: state.payment.token,
  balance: state.payment.balance,
  inviting: state.payment.inviting,
  defaultLanguage: state.user.defaultLanguage,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    inviteByEmail: (params) => dispatch(inviteByEmail(params)),
    fetchReferralInv: () => dispatch(fetchReferralInv()),
    fetchToken: () => dispatch(fetchToken()),
    fetchBalance: () => dispatch(fetchBalance()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ReferralProgram))
);
