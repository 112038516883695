import React from 'react'

const BadgeCustom = ({content}) => {
	let style = {
		display: "inline-block",
		padding: "0px 6px",
		borderRadius: "16px",
		fontWeight: "400",
		fontSize: "12px",
	}
	let textColor = "rgba(71, 71, 71, 1)";
	// console.log('content', content)
	switch (content) {
		case "new":
		case "accepted":
		case "refund":
			style = { ...style, backgroundColor: "rgba(228, 243, 254, 1)" };
			textColor = "rgba(38, 127, 215, 1)";
			break;
		case "submitted":
		case "auto-submitted":
		case "paid":
			style = { ...style, backgroundColor: "rgba(200, 230, 205, 1)" };
			textColor = "rgba(62, 141, 75, 1)";
			break;
		case "pending":
		case "warning":
			style = { ...style, backgroundColor: "rgba(255, 238, 178, 1)" };
			textColor = "rgba(184, 130, 27, 1)";
			break;
		case "draft":
			style = { ...style, backgroundColor: "#F4F6F8" };
			textColor = "#637381";
			break;
		case "declined":
		case "error":
			style = { ...style, backgroundColor: "rgba(254, 228, 226, 1)" };
			textColor = "rgba(180, 35, 24, 1)";
			break;
		default:
			style = { ...style, backgroundColor: "rgba(194, 194, 194, 1)" };
			textColor = "rgba(71, 71, 71, 1)";
			break;
	}
	
	return (
		<div style={style}>
      <span style={{ color: textColor }}>{content}</span>
    </div>
	)
}

export default BadgeCustom