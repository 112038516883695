import React, { useEffect, useMemo, useState } from "react";
import PageHelmet from "components/PageHelmet";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  DataTable,
  FormLayout,
  Layout,
  Page,
  RadioButton,
  Select,
  Text,
  TextField,
  Tooltip,
} from "@shopify/polaris";
import Stepper from "components/Stepper";
import formatRow from "utils/formatRow";
import SaveBar from "components/SaveBar/SaveBar";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import editIMG from "img/settings.svg";
import useActionDialogs from "hooks/useActionDIalogs";
import Modal from "components/Modal";
import chunkArray from "utils/chunkArray";
import ArrowLeftBack from '../../img/arrow-left-for-button-back.svg'
import { createSchemas } from "./schemas";
import HighlightableDataTable from "components/HighlightableDataTable";
import plusIMG from "img/plusBlue.svg";
import InfoTooltip from "components/InfoTooltip/InfoTooltip";
import { email } from "utils/validation";
import { AlertIcon } from "icons";
import {
  MerchantNotifications,
  NotificationMethod,
  dialogNames,
} from "./constants";
import step1IMG from "img/step1.svg";
import succesIMG from "img/step1.svg";
import Datepicker from "components/Datepicker/Datepicker";
import moment from "moment";
import ArrowRight from '../../img/arrow-right-white.svg'

const renderInput = ({
  id,
  type,
  inputMode,
  placeholder,
  value,
  onChange,
  validate,
  noValidate,
  infoTooltipText,
  ...props
}) => {
  const label = infoTooltipText ? (
    <InfoTooltip
      content={infoTooltipText}
      children={placeholder}
      style={{ marginTop: -3 }}
    />
  ) : (
    placeholder
  );
  return (
    <TextField
      id={id}
      type={type}
      inputMode={inputMode}
      label={label}
      value={value}
      onChange={(value) => onChange(value, id)}
      error={noValidate ? false : validate(value)}
      {...props}
    />
  );
};

const renderSelect = ({
  id,
  placeholder,
  options,
  onChange,
  value,
  validate,
}) => {
  return (
    <Select
      id={id}
      label={placeholder}
      options={options}
      onChange={(value) => onChange(value, id)}
      value={value}
      error={validate(value)}
    />
  );
};

const renderDatepicker = ({
  id,
  placeholder,
  onChange,
  value,
  customFormat,
  datepickerProps,
}) => {
  return (
    <Datepicker
      label={placeholder}
      onChange={(value) => onChange(moment(value).format("yyyy-MM-DD"), id)}
      start={value}
      value={value}
      allowRange={false}
      customFormatDate={(date) => customFormat(date)}
      datepickerProps={datepickerProps}
    />
  );
};

const Report1099 = () => {
  const { report1099 } = useSelector((state) => state.declarations);
  const navigate = useNavigate();
  const [stepIndex, setStepIndex] = useState(1);
  const [notificationSendCheckbox, setNotificationSendCheckbox] =
    useState(false);
  const [merchantNotifications, setMerchantNotifications] = useState(
    MerchantNotifications.Lovat
  );
  const [notificationMethod, setNotificationMethod] = useState(
    NotificationMethod.Digital
  );
  const [isConfirmSubmitDialog, setConfirmSubmitDialog] = useState(false);
  const {
    actionDialogs,
    handleActionDialogsOpen,
    handleActionDialogsClose,
    editActionDialogCellData,
  } = useActionDialogs(dialogNames);
  const [touched, setTouched] = useState({});
  const [returnEmail, setReturnEmail] = useState("");
  const [fillFormLoading, setFillFormLoading] = useState("");
  const [selectedRelevantActivity, setSelectedRelevantActivity] =
    useState(null);
  const [
    selectedRelevantActivityOptionMCC,
    setSelectedRelevantActivityOptionMCC,
  ] = useState("");

  useEffect(() => {
    if (!report1099) {
      navigate("/reports");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fillForm = (type) => {
    setFillFormLoading(type);
    const data = {};

    if (merchantNotifications === MerchantNotifications.Myself) {
      data.notification_method = notificationMethod;
    } else {
      data.return_email = returnEmail;
    }

    if (type !== "download") {
      handleActionDialogsClose("confirmSubmit");
      handleActionDialogsOpen("successDialog", { type });
    }

    setFillFormLoading("");
  };

  const handlePrev = () => {
    if (stepIndex === 1) {
      navigate(-1);
    } else {
      setStepIndex((step) => step - 1);
    }
  };

  const handleNext = () => {
    setStepIndex((step) => step + 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 1:
        return step2();
      case 2:
        return step3();
      case 3:
        return step4();
      default:
        return null;
    }
  };

  const steps = [
    {
      title: t("dac7.step1"),
      icon: step1IMG,
    },
    {
      title: "1099-K preliminary report",
      icon: step1IMG,
    },
    {
      title: t("dac7.merchantNotifications"),
      icon: step1IMG,
    },
    {
      title: t("dac7.summary"),
      icon: step1IMG,
    },
  ];

  const { merchantSchema, tableSchema } = createSchemas({ t });

  const settingsColumn = useMemo(
    () => ({
      property: "",
      header: {
        label: "",
      },
      cell: {
        formatters: [
          (_, { rowData }) => (
            <Box display="flex" alignItems="center" gap="4">
              {!rowData.is_warning && (
                <img
                  src={succesIMG}
                  alt='successfully filled merchant info'
                  width='14px'
                />
              )}
              <Tooltip content={t("dac7.editMerchantInfo")}>
                <Button
                  variant={'plain'}
                  onClick={() => {
                    // handleActionDialogsOpen("merchantSettings", rowData);
                  }}
                >
                  <img src={editIMG} alt='edit merchant info' />
                </Button>
              </Tooltip>
            </Box>
          ),
        ],
      },
    }),
    []
  );

  const step2 = () => {
    const columns = [...tableSchema, settingsColumn];
    const { merchants: data = [] } = report1099 ?? {};
    const sortedRows = formatRow(data, columns);
    const isNextButtonDisabled = data.some((merchant) => merchant.is_warning);
    const isWarningMessage = data.some((merchant) => merchant.is_warning);

    const handleNextClick = () => {
      handleNext();
    };

    const handleManualUploadClick = () => {
      // handleActionDialogsOpen("uploadMerchants");
    };

    return (
      <>
        <Card sectioned>
          <ButtonGroup>
            <Button variant={'primary'} size={'micro'}>{t("dac7.uploadTransactions")}</Button>
            <Button onClick={handleManualUploadClick}>
              {t("dac7.manualUploadMerchantData")}
            </Button>
          </ButtonGroup>
        </Card>
        <Card>
          {isWarningMessage && (
            <div>
              <Box display="flex" alignItems="center" gap="2">
                <AlertIcon stroke={"#B8821B"} />
                <Text color='subdued'>{t("dac7.merchantsWithWarnings")}</Text>
              </Box>
            </div>
          )}
          <HighlightableDataTable
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "numeric",
            ]}
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold'>{header.label}</Text>
            ))}
            rowClassNameCallback={(row, rowData) => ({
              "bg-yellow": rowData.is_warning,
            })}
            rawItems={data}
            rows={sortedRows}
            hideScrollIndicator
            increasedTableDensity
            hoverable
          />
        </Card>
        <div style={{ margin: "0.5rem 0" }}>
          <Button
            variant={'plain'}
            icon={<img src={plusIMG} alt='plus' style={{ marginTop: 3 }} />}
            onClick={() => {
              handleActionDialogsOpen("addMerchant");
            }}
          >
            {t("dac7.addNewMerchant")}
          </Button>
        </div>

        <SaveBar>
          <ButtonGroup>
            <Button size={'micro'} onClick={handlePrev}> <img src={ArrowLeftBack} alt='arrow' style={{width: 10, height:10 , marginRight:8}}/>{t("createReturns.back")}</Button>
            <Button
              variant={'primary'}
              onClick={handleNextClick}
              size={'micro'}
              // loading={dac7Loading}
              disabled={isNextButtonDisabled}
            >
              {t("orderReg.next")}<img src={ArrowRight} alt='arrow' style={{width: 10, height:10 , marginLeft:8}}/>
            </Button>
          </ButtonGroup>
        </SaveBar>
      </>
    );
  };

  const step3 = () => {
    const isNextButtonDisabled =
      merchantNotifications === MerchantNotifications.Lovat
        ? email(returnEmail)
        : false;
    const handlePrevClick = () => {
      handlePrev();
    };

    const validateEmail = (value) => {
      return touched.returnEmail && email(value);
    };

    const handleNextClick = () => {
      if (merchantNotifications === MerchantNotifications.Myself) {
        handleActionDialogsOpen("notificationsCheckbox");
      } else {
        handleNext();
      }
    };

    return (
      <>
        <Card sectioned>
          <Box
            display="flex"
            flexDirection="column"
            gap="1"
            width="100%"
          >
            <Box display="flex" alignItems="center">
              <Text>{t("dac7.merchantNotifications")}</Text>
              <InfoTooltip content={<span>{t("dac7.ompFacilitators")}</span>} />
            </Box>
            <Box spacing='0'>
              <RadioButton
                label={t("dac7.sendMerchantNotificationsViaLovatPlatform")}
                checked={merchantNotifications === MerchantNotifications.Lovat}
                onChange={() => {
                  setMerchantNotifications(MerchantNotifications.Lovat);
                }}
              />
              <RadioButton
                label={t("dac7.sendMerchantNotificationsMyself")}
                checked={merchantNotifications === MerchantNotifications.Myself}
                onChange={() => {
                  setMerchantNotifications(MerchantNotifications.Myself);
                }}
              />
            </Box>
            <br />
            {merchantNotifications === MerchantNotifications.Myself ? (
              <Box
                display="flex"
                flexDirection="column"
                gap="1"
                width="100%"
              >
                <Text>{t("dac7.pleaseChooseNotificationMethod")}</Text>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="0"
                >
                  <RadioButton
                    label={t("dac7.digital")}
                    checked={notificationMethod === NotificationMethod.Digital}
                    onChange={() => {
                      setNotificationMethod(NotificationMethod.Digital);
                    }}
                  />
                  <RadioButton
                    label={t("dac7.paper")}
                    checked={notificationMethod === NotificationMethod.Paper}
                    onChange={() => {
                      setNotificationMethod(NotificationMethod.Paper);
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                gap="1"
                width="100%"
              >
                <Box display="flex" alignItems="center">
                  <Text>{t("dac7.pleaseAddReturnEmailAddress")}</Text>
                  <InfoTooltip
                    content={
                      <span>
                        {t(
                          "dac7.thisAddressWillBeIndicatedAsSenderContactEmail"
                        )}
                      </span>
                    }
                  />
                </Box>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      placeholder='Return email address'
                      value={returnEmail}
                      type='email'
                      onChange={(value) => setReturnEmail(value)}
                      error={validateEmail(returnEmail)}
                      onBlur={() =>
                        setTouched((prevTouched) => ({
                          ...prevTouched,
                          returnEmail: true,
                        }))
                      }
                    />
                    <br />
                  </FormLayout.Group>
                </FormLayout>
              </Box>
            )}
          </Box>
        </Card>
        {/* {dac7_files_examples.length && (
          <Card sectioned>
            <AlphaStack spacing='2'>
              <Text>{t("dac7.examplesOfMerchantNotificationPdfFiles")}</Text>
              <AlphaStack spacing='2'>
                {dac7_files_examples.map((filePath) => {
                  return (
                    <Link external url={`${baseURL}/${filePath}`}>
                      {filePath.split("/").reverse()[0]}
                    </Link>
                  );
                })}
              </AlphaStack>
            </AlphaStack>
          </Card>
        )} */}
        <SaveBar>
          <ButtonGroup>
            <Button size={'micro'} onClick={handlePrevClick}> <img src={ArrowLeftBack} alt='arrow' style={{width: 10, height:10 , marginRight:8}}/>{t("createReturns.back")}</Button>
            <Button
              variant={'primary'}
              onClick={handleNextClick}
              size={'micro'}
              // loading={dac7Loading}
              disabled={isNextButtonDisabled}
            >
              {t("orderReg.next")}<img src={ArrowRight} alt='arrow' style={{width: 10, height:10 , marginLeft:8}}/>
            </Button>
          </ButtonGroup>
        </SaveBar>
      </>
    );
  };

  const step4 = () => {
    const commisionsColumn = {
      property: "total_amount_of_commissions",
      header: {
        label: "Commissions",
      },
    };
    const columns = [...tableSchema, commisionsColumn];
    const { merchants: data = [] } = report1099 ?? {};
    const sortedRows = formatRow(data, columns);

    const handleSubmitClick = () => {
      handleActionDialogsOpen("confirmSubmit");
    };

    const handleSaveClick = () => {
      fillForm("save");
    };

    const handleDownloadClick = () => {
      fillForm("download");
    };

    return (
      <>
        <Card>
          <DataTable
            columnContentTypes={["text", "text", "text", "text", "numeric"]}
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold'>{header.label}</Text>
            ))}
            rows={sortedRows}
            hideScrollIndicator
            hoverable
          />
        </Card>
        <SaveBar>
          <ButtonGroup>
            <Button size={'micro'} onClick={handlePrev}> <img src={ArrowLeftBack} alt='arrow' style={{width: 10, height:10 , marginRight:8}}/>{t("createReturns.back")}</Button>
            <Button
              variant={'primary'}
              size={'micro'}
              loading={fillFormLoading === "submit"}
              onClick={handleSubmitClick}
            >
              {t("dac7.submit")}
            </Button>
            <Button
              variant={'primary'}
              size={'micro'}
              loading={fillFormLoading === "save"}
              onClick={handleSaveClick}
            >
              {t("dac7.save")}
            </Button>
            <Button
              variant={'primary'}
              size={'micro'}
              loading={fillFormLoading === "download"}
              onClick={handleDownloadClick}
            >
              {t("dac7.downloadReport")}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </>
    );
  };

  const renderRelevantActivities = () => {
    const { relevant_activities = [] } = report1099 ?? {};

    const relevantActivitiesOptions = [
      "",
      ...relevant_activities.map(({ id, label, value }) => ({
        value: String(id),
        label: `${value} - ${label}`,
      })),
    ];

    const relevantActivityOptionsMCC = selectedRelevantActivity
      ? [
          "",
          ...relevant_activities
            .find(({ id }) => String(id) === selectedRelevantActivity)
            .items.map(({ code, description }) => ({
              value: code,
              label: `${code} - ${description}`,
            })),
        ]
      : [];

    return (
      <FormLayout>
        <Select
          label={"Relevant Activity"}
          options={relevantActivitiesOptions}
          value={selectedRelevantActivity}
          onChange={(value) => setSelectedRelevantActivity(value)}
        />

        <Select
          label={"MCC"}
          options={relevantActivityOptionsMCC}
          value={selectedRelevantActivityOptionMCC}
          disabled={!selectedRelevantActivity}
          onChange={(value) => setSelectedRelevantActivityOptionMCC(value)}
        />
      </FormLayout>
    );
  };

  const renderConfirmNotificationsSendModal = () => {
    const onClose = () => {
      handleActionDialogsClose("notificationsCheckbox");
    };
    const onSubmit = () => {
      handleNext();
      onClose();
    };
    return (
      <Modal
        title={t("dac7.notificationConsent")}
        iconType={"warning"}
        visible={actionDialogs.notificationsCheckbox.open}
        onClose={onClose}
        content={
          <>
            <br />
            <Box
              display="flex"
              flexDirection="column"
            >
              <Checkbox
                label={t("dac7.consentToSendMerchantNotifications")}
                checked={notificationSendCheckbox}
                onChange={() =>
                  setNotificationSendCheckbox((prevState) => !prevState)
                }
              />
            </Box>
          </>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose}>{t("dac7.cancel")}</Button>
            <Button
              variant={'primary'}
              disabled={!notificationSendCheckbox}
              onClick={onSubmit}
            >
              {t("dac7.submit")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  };

  const renderSuccessDialog = () => {
    const { type } = actionDialogs?.cellData || {};
    const onClose = () => {
      handleActionDialogsClose("successDialog");
      navigate("/reports");
    };
    const getDescription = (type) => {
      if (type === "save") {
        return t("report1099.reportSuccessfullySaved");
      } else {
        return t("report1099.reportSuccessfullySubmitted");
      }
    };
    const description = getDescription(type);

    return (
      <Modal
        title={t("eprReporting.success")}
        iconType={"success"}
        visible={actionDialogs.successDialog.open}
        onClose={onClose}
        description={description}
        footer={
          <ButtonGroup>
            <Button variant={'primary'} onClick={onClose} size='large'>
              Ok
            </Button>
          </ButtonGroup>
        }
      />
    );
  };

  const renderConfirmSubmitDialog = () => {
    const onClose = () => handleActionDialogsClose("confirmSubmit");
    const onSubmit = () => {
      fillForm("submit");
    };
    return (
      <Modal
        title={t("dac7.warning")}
        iconType={"warning"}
        description={""}
        visible={actionDialogs.confirmSubmit.open}
        onClose={onClose}
        content={
          <div style={{ maxWidth: "450px" }}>
            <br />
            <Checkbox
              label={
                <Text variant='bodyMd' color='subdued'>
                  By clicking on the 'Submit' button, you agree to the
                  following: When you submit this 1099 return information you
                  are making a legal declaration that the information is true
                  and complete. A false declaration can result in prosecution
                </Text>
              }
              checked={isConfirmSubmitDialog}
              onChange={() => {
                setConfirmSubmitDialog((prev) => !prev);
              }}
            />
          </div>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose}>{t("dac7.cancel")}</Button>
            <Button
              variant={'primary'}
              onClick={onSubmit}
              disabled={!isConfirmSubmitDialog}
            >
              {t("dac7.submit")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  };

  const renderAddNewMerchant = () => {
    const onClose = () => {
      handleActionDialogsClose("addMerchant");
    };

    const onSubmit = async () => {};

    const chunkSize = 2;

    const groupedTemplateStructure = chunkArray(merchantSchema, chunkSize);
    const fillGroupInTwoElements = (group) => {
      return group.length < chunkSize
        ? group.concat(Array(chunkSize - group.length).fill(null))
        : group;
    };

    const onChange = (value, id) => {
      const newData = { ...actionDialogs.addMerchant.cellData, [id]: value };
      editActionDialogCellData("addMerchant", newData);
    };

    return (
      actionDialogs.addMerchant.cellData && (
        <Modal
          title={t("dac7.addNewMerchant")}
          infoIconVisible={false}
          visible={actionDialogs.addMerchant.open}
          onClose={onClose}
          content={
            <>
              <FormLayout>
                {groupedTemplateStructure.map((group, groupIndex) => (
                  <FormLayout.Group key={groupIndex}>
                    {fillGroupInTwoElements(group).map(
                      (templateElement, index) => (
                        <div key={index}>
                          {templateElement && (
                            <>
                              {templateElement.type === "input" &&
                                renderInput({
                                  ...templateElement.props,
                                  value:
                                    actionDialogs.addMerchant.cellData[
                                      templateElement.props.id
                                    ],
                                  onChange,
                                  noValidate: true,
                                })}
                              {templateElement.type === "datepicker" &&
                                renderDatepicker({
                                  ...templateElement.props,
                                  value:
                                    actionDialogs.addMerchant.cellData[
                                      templateElement.props.id
                                    ],
                                  onChange,
                                })}
                            </>
                          )}
                        </div>
                      )
                    )}
                  </FormLayout.Group>
                ))}
                <br />
                {renderRelevantActivities()}
              </FormLayout>
            </>
          }
          footer={
            <ButtonGroup fullWidth>
              <Button onClick={onClose} size='large'>
                {t("dac7.cancel")}
              </Button>
              <Button primary onClick={onSubmit} size='large'>
                {t("dac7.add")}
              </Button>
            </ButtonGroup>
          }
        />
      )
    );
  };

  return (
    <Page
      fullWidth
      title={
        <Text variant='heading3xl' as='span'>
          1099 Return
        </Text>
      }
    >
      <PageHelmet title={"Fill 1099 Return"} />

      <Layout>
        <Layout.Section secondary>
          <Stepper
            steps={steps}
            activeStep={stepIndex}
            titleFontSize={14}
            activeColor='#E4F3FE'
            completeColor='#216DC5'
            circleFontColor='#212B36'
            defaultTitleColor='#919EAB'
            completeTitleColor='#919EAB'
            activeTitleColor='#212B36'
            completeBorderColor={"#367C41"}
            defaultBorderWidth={4}
            defaultBarColor='#ADADAD'
            size={24}
            circleFontSize={14}
          />
        </Layout.Section>
        <Layout.Section>
          <div style={{ marginBottom: 102 }}>{getStepContent(stepIndex)}</div>
        </Layout.Section>
        {renderConfirmNotificationsSendModal()}
        {renderConfirmSubmitDialog()}
        {renderSuccessDialog()}
        {renderAddNewMerchant()}
      </Layout>
    </Page>
  );
};

export default Report1099;
