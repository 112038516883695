import * as PropTypes from 'prop-types'
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import {
  Page,
  Layout,
  Card,
  Button,
  FormLayout,
  TextField,
  Select,
  Tag,
  Banner,
  Text,
  Box,
  Link,
} from "@shopify/polaris";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import BannerCustom from '../../components/Banners/BannerCustom'
import GoBackPageNavigation from '../../components/GoBackPageNavigation/GoBackPageNavigation'
import { openNewDialog } from "../../redux/features/messenger/messengerSlice";
import { fetchListCompany } from "../../redux/features/manager/managerSlice";

import ClipIMG from "img/clip.svg";
import PageHelmet from "components/PageHelmet";
import { createCustomDispatch } from "helpers/customDispatch";

const statusArr = [
  {
    value: "info",
    label: "info",
  },
  {
    value: "pending lovat action",
    label: "pending lovat action",
  },
  {
    value: "action required",
    label: "action required",
  },
  {
    value: "answered",
    label: "answered",
  },
];

function Typography(props) {
  return null
}

Typography.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node
}

class Case extends Component {
  constructor(props) {
    super(props);
    const { t } = props;

    this.state = {
      title: "",
      errorTitle: false,
      description: "",
      errorDescription: false,
      theme: "stores",

      selectedCompany: "",

      status: "info",

      fileNameList: {
        proof_files: [],
      },

      themes: [
        { label: t("case.themes.stores"), value: "stores" },
        { label: t("case.themes.trans"), value: "transactions" },
        { label: t("case.themes.vatRet"), value: "vat returns" },
        { label: t("case.themes.vatReg"), value: "vat registration" },
        { label: t("case.themes.taxAudit"), value: "tax audit" },
        { label: t("case.themes.documents"), value: "documents" },
        { label: t("case.themes.other"), value: "other" },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    const { selected_company_id: prevSelectedCompanyId } =
      prevProps.listCompany;
    const { selected_company_id: currentSelectedCompanyId } =
      this.props.listCompany;

    if (prevSelectedCompanyId !== currentSelectedCompanyId) {
      this.setState({
        selectedCompany: currentSelectedCompanyId,
      });
    }
  }

  componentDidMount() {
    const { selected_company_id: currentSelectedCompanyId } =
      this.props.listCompany;

    this.setState({
      selectedCompany: currentSelectedCompanyId,
    });
  }

  docsFiles = {
    proof_files: [],
  };

  attachFiles = (docsFiles, comments) => {
    // attach documents to form
    const formDataFiles = new FormData();
    for (let i = 0; i < docsFiles.proof_files.length; i++) {
      formDataFiles.append(
        "file_" + i,
        docsFiles.proof_files[i],
        docsFiles.proof_files[i].name
      );
    }

    formDataFiles.append("comments", comments);

    return formDataFiles;
  };

  handleFileSelect = (docsType) => {
    const { fileNameList } = this.state;
    if (this.fileUploadInput) {
      this.fileUploadInput.click();
      this.fileUploadInput.onchange = () => {
        const files = this.fileUploadInput.files;
        for (let i = 0; i < files.length; i++) {
          this.doDeleteFile(docsType, files[i].name); // delete the same file from list, comparing inside 'doDeleteFile' function
          this.docsFiles[docsType][this.docsFiles[docsType].length] = files[i]; // add file
          fileNameList[docsType][fileNameList[docsType].length] = files[i].name; // add filename
        }
        this.setState({ fileNameList });
      };
    }
  };

  doDeleteFile = (fileListName, fileName) => {
    const { fileNameList } = this.state;
    let fileToDelete = undefined;
    _.map(this.docsFiles[fileListName], (file, index) => {
      if (file.name === fileName) {
        fileToDelete = index;
      } // get index
    });

    if (fileToDelete !== undefined) {
      this.docsFiles[fileListName].splice(fileToDelete, 1); // delete file name the list
      fileNameList[fileListName].splice(fileToDelete, 1); // delete file name from the list
      this.setState({ fileNameList });
    }
  };

  createMessage = (event) => {
    event.preventDefault();
    const { title, description, theme, selectedCompany, status } = this.state;
    const { user, t } = this.props;
    if (!title) {
      this.setState({
        errorTitle: t("case.errFillCase"),
      });
      return;
    }
    if (!description) {
      this.setState({
        errorDescription: t("case.errFillDesc"),
      });
      return;
    }

    if (title && description) {
      const formData = {
        title,
        theme,
      };

      if (selectedCompany && user.is_manager) {
        formData.companyId = +selectedCompany;
        formData.status = status;
      }

      const files = this.attachFiles(this.docsFiles, description);
      this.props
        .openNewDialog({ ...formData, data: files })
        .then(() => {
          const { fileNameList } = this.state;
          fileNameList.proof_files = [];
          this.docsFiles.proof_files = [];
          this.setState({
            title: "",
            description: "",
            theme: "stores",
            openingError: null,
            openingResult: t("case.caseOpened"),
            fileNameList,
          });
        })
        .catch((resp) => {
          this.setState({ openingError: resp, openingResult: null });
        });
    }
  };

  renderMultipleFileSelect = (docType) => {
    const { fileNameList } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            icon={<img src={ClipIMG} alt='clip' style={{ marginTop: 4 }} />}
            onClick={() => this.handleFileSelect(docType)}
            disabled={this.props.isUploading}
            size={'micro'}
          >
            {t("case.addFile")}
            <input
              type='file'
              multiple
              style={{ display: "none" }}
              ref={(input) => (this.fileUploadInput = input)}
            />
          </Button>
          <h4 style={{ paddingLeft: 10 }}>
            {t("case.chooseFiles")}
          </h4>
        </div>
        <div>
          {_.map(fileNameList[docType], (fileName, index) => {
            return (
              <div
                style={{ display: "inline-block", margin: "5px" }}
                key={index}
              >
                <Tag
                  key={index}
                  onRemove={() => {
                    this.doDeleteFile(docType, fileName);
                  }}
                >
                  {fileName}
                </Tag>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  onNavigateBack = () => {
    this.props.navigate("/help", { state: { defaultTab: 1 } });
  };

  render() {
    const {
      selectedCompany,
      status,
      themes,
      theme,
      openingResult,
      openingError,
      title,
      errorTitle,
      description,
      errorDescription,
    } = this.state;
    const { listCompany, fetchingListCompany, user, t } = this.props;
    let options = [];

    if (user.is_manager && !_.isEmpty(listCompany)) {
      options = listCompany.companies.map((company) => {
        return {
          label: company.name,
          value: company.id,
        };
      });
    }

    return (
      <Page
        separator
        fullWidth
        title={
          <GoBackPageNavigation content={
            <Text variant="heading3xl" as="span">
              New request
            </Text>
          } />
        }
        subtitle={
          <Text variant='bodyLg' as='span' color='subdued'>
            We are here to help you
          </Text>
        }
      >
        <PageHelmet title={"New request"} />

        <Layout>
          <Layout.AnnotatedSection
            title={t("case.fillIn")}
            description={
              <p>
                <Text variant="bodyMd" color='subdued'>
                  {t("case.desc1")}
                </Text>
                <br />
                <br />
                <Text variant="bodyMd" color="subdued">
                  {t("case.desc2")}
                </Text>
              </p>
            }
          >
            <Card sectioned>
              <form onSubmit={this.createMessage}>
                {openingError && (
                  <>
                    {/*<Banner title='Error' tone='critical' />*/}
                    <BannerCustom
                      status={'critical'}
                      message={'Error'}
                    />
                    <br />
                  </>
                )}
                {openingResult && (
                  <>
                    <Banner
                      tone='success'
                      onDismiss={() => this.setState({ openingResult: null })}
                    >
                        <Box display="flex" alignItems="space-between">
                        <Text>{openingResult}</Text>

                        <Link onClick={this.onNavigateBack}>
                          go back to help center
                        </Link>
                        </Box>
                    </Banner>
                    <br />
                  </>
                )}
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      id='theme'
                      label={t("case.caseGroup")}
                      options={themes}
                      onChange={(theme) => this.setState({ theme })}
                      value={theme}
                    />
                  </FormLayout.Group>

                  {user.is_manager && (
                    <FormLayout.Group>
                      <Select
                        id='companyId'
                        label='Company'
                        disabled={fetchingListCompany}
                        options={options}
                        onChange={(id) =>
                          this.setState({ selectedCompany: id })
                        }
                        value={Number(selectedCompany)}
                      />

                      <Select
                        id='status'
                        label={t("case.status")}
                        options={statusArr}
                        onChange={(value) => this.setState({ status: value })}
                        value={status}
                      />
                    </FormLayout.Group>
                  )}

                  <TextField
                    id='title'
                    name='title'
                    label={t("case.desc")}
                    value={title}
                    error={errorTitle}
                    onChange={(title) =>
                      this.setState({ title, errorTitle: false })
                    }
                  />

                  <TextField
                    id='desc'
                    name='description'
                    label={
                      <Box display="flex" alignItems="center" gap="2">
                        <Text>{t("case.comments")}</Text>
                        <Text
                          color={
                            description.length > 1000 ? "critical" : "subdued"
                          }
                          variant='bodySm'
                        >
                          {description.length}/1000
                        </Text>
                      </Box>
                    }
                    value={description}
                    error={errorDescription}
                    multiline={4}
                    onChange={(description) =>
                      this.setState({ description, errorDescription: false })
                    }
                  />
                </FormLayout>
                <br />
                {this.renderMultipleFileSelect("proof_files")}
                <br />
                  <Box display="flex" alignItems="end">
                  <Button variant='primary' submit disabled={description.length > 1000}>
                    {t("case.openCase")}
                  </Button>
                  </Box>
              </form>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  listCompany: state.manager.listCompany,
  fetchingListCompany: state.manager.fetchingListCompany,
  user: state.user.user,
  defaultLanguage: state.user.defaultLanguage,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    openNewDialog: (params) => dispatch(openNewDialog(params)),
    fetchListCompany: (params) => dispatch(fetchListCompany(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Case))
);
