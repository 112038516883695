import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  Card,
  Button,
  ButtonGroup,
  FormLayout,
  Select,
  TextField,
  Checkbox,
  ChoiceList,
  Tooltip,
  Banner,
  Spinner,
  DataTable,
  Text,
  Box,
} from "@shopify/polaris";
import Modal from "components/Modal/Modal";
import { formatDate, formatDateForServer } from "utils/dates";
import _ from "lodash";
import formatRow from "utils/formatRow";

import checkmarkIMG from "img/checkmark.svg";
import crossIMG from "img/cross.svg";
import settingsIMG from "img/settings.svg";
import continueIMG from "img/continue.svg";
import deleteIMG from "img/delete.svg";
import payIMG from "img/pay.svg";
import cartIMG from "img/shopping-cart.svg";
import plusIMG from "img/plus.svg";
import Datepicker from "components/Datepicker/Datepicker";
import { editActivateVatReg, editRegNumberManagerArea } from '../../redux/features/manager/managerSlice'
import { fetchCountries } from '../../redux/features/other/otherSlice'
import {
  addRegNumber, deleteRegOrder,
  editRegNumber, fetchGermanyStates, fetchReasons, fetchRegCountries,
  fetchRegNumbers, fetchVatCountryStates,
  getAllNotCompletedOrders
} from '../../redux/features/registration/registrationSlice'
import { switchCalculateThresholds } from '../../redux/features/subscription/subscriptionSlice'
import { fetchCompanyDefaultLanguage, fetchUser, fetchUserGuide } from '../../redux/features/user/userSlice'
import Payment from "../Payment";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import { getCurrencySymbol } from "utils/currency";
import { createCustomDispatch } from "helpers/customDispatch";
import { AddIcon } from "icons";

const defaultDates = {
  from: moment().startOf("year").toDate(),
};

const pattern1 = /^[0-9]{2}\/[0-9]{3}\/[0-9]{5}/;
const pattern2 = /^[0-9]{3}\/[0-9]{3}\/[0-9]{5}/; // for Hessen (id === 3), Bayern (id === 5)

class VATRegistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortingColumns: [],
      searchColumn: "all",
      pagination: {
        page: 1,
        perPage: 100,
      },
      check: false,
      isOpenAddNumber: false,

      addingStatus: false,

      from: formatDateForServer(defaultDates.from),
      reason_for_registration_id: 0,
      till: null,
      agent: null,
      send_reminder: false,
      vatNumber: "",
      tinNumber: "",
      vatNexusDate: "",
      steuernummer: "",
      errorVatNumber: null,
      errorTinNumber: null,
      country: null,
      reason: null,
      state: null,
      scheme: null,
      province: null,
      ...props,
      threshold: [true],

      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      selected: {
        start: defaultDates.from,
        end: undefined,
      },

      actionDialogs: {
        editNumber: { open: false },
        editActivate: { open: false },
        deleteOrder: { open: false },
        pay: { open: false },
        notification: { open: false },
      },

      monthDlg: new Date().getMonth(),
      yearDlg: new Date().getFullYear(),
      selectedDlg: null,

      taxRepLovatDlg: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { countries, reasons, germanyStates } = this.props;

    if (prevProps.countries !== countries) {
      const defaultContryCode = countries[0].code;

      this.setState({
        country: countries ? defaultContryCode : null,
      });
    }
    if (prevProps.reasons !== reasons) {
      const defaultReason = reasons[0]?.id;

      this.setState({
        reason: reasons ? defaultReason : null,
      });
    }
    if (prevProps.germanyStates !== germanyStates) {
      const germanyState = germanyStates[0];

      this.setState({
        state: !_.isEmpty(germanyStates) ? germanyState.id : null,
        scheme: !_.isEmpty(germanyStates) ? germanyState.scheme : null,
      });
    }
  }

  componentDidMount() {
    this.props.fetchGermanyStates();
    this.props.fetchVatCountryStates();

    this.props
      .fetchReasons()
      .then(() =>
        this.setState({
          reason: this.props.reasons ? this.props.reasons[0].id : null,
        })
      )
      .catch(_.noop);
    this.props.fetchRegCountries();
    this.props
      .fetchCountries()
      .then(() =>
        this.setState({
          country: this.props.countries ? this.props.countries[0].code : null,
        })
      )
      .catch(_.noop);
  }

  getAllCountriesForSelect = () => {
    const optionsCountries = [];
    this.props.countries.map((country) => {
      optionsCountries.push({
        label: country.name_no_article,
        value: country.code,
      });
    });
    return optionsCountries;
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    if (name === "editNumber") {
      this.updateDialogData(data);
    }
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs[name].data = data;
    this.setState({ actionDialogs });
  };

  handleFromDateChange = (start) => {
    const { selected } = this.state;

    this.setState({
      selected,
      from: formatDateForServer(start),
      paymentStatus: undefined,
    });
  };

  handleTillDateChange = (end) => {
    const { selected } = this.state;
    const month = new Date(end).getMonth() + 1;
    const year = new Date(end).getFullYear();

    this.setState({
      selected,
      till: end ? formatDateForServer(end) : null,
      paymentStatus: undefined,
      month,
      year,
    });
  };

  handleChangeVatNumber = (vatNumber) => {
    const { t } = this.props;
    if (vatNumber.length > 0) {
      this.setState({
        vatNumber,
        errorVatNumber: false,
        paymentStatus: undefined,
      });
    } else {
      this.setState({
        vatNumber: "",
        errorVatNumber: t("vatReg.enterVatNum"),
      });
    }
  };

  handleChangeTinNumber = (tinNumber) => {
    const { t } = this.props;
    if (tinNumber.length > 0) {
      this.setState({
        tinNumber,
        errorTinNumber: false,
        paymentStatus: undefined,
      });
    } else {
      this.setState({
        tinNumber: "",
        errorTinNumber: t("vatReg.enterTinNumber"),
      });
    }
  };

  handleChangeSteuernummer = (steuernummer) => {
    const { t } = this.props;
    if (steuernummer.length > 0) {
      this.setState({
        steuernummer,
        errorSteuernummer: false,
        paymentStatus: undefined,
      });
    } else {
      this.setState({
        steuernummer: "",
        errorSteuernummer: t("vatReg.enterStNum"),
      });
    }
  };

  handleOrderReg = () => {
    this.props.navigate("/tax-settings/order");
  };

  toggleAddNumber(isOpen) {
    this.setState({
      isOpenAddNumber: isOpen,
    });
  }

  validateSteuernummer = (state, steuernummer) => {
    let validate = undefined;

    switch (+state) {
      case 1:
        validate = pattern1.test(steuernummer);
        break;
      case 2:
        validate = pattern1.test(steuernummer);
        break;
      case 3:
        validate = pattern2.test(steuernummer);
        break;
      case 4:
        validate = pattern1.test(steuernummer);
        break;
      case 5:
        validate = pattern2.test(steuernummer);
        break;
      default:
        validate = true;
    }
    return validate;
  };

  addNumber = () => {
    const { userGuide, countries, reasons, germanyStates, t } = this.props;
    const {
      agent,
      country,
      reason,
      vatNumber,
      tinNumber,
      province,
      vatNexusDate,
      steuernummer,
      threshold,
      from,
      till,
      send_reminder,
      state: germanyState,
    } = this.state;

    if (!province && !vatNumber) {
      this.setState({ errorVatNumber: t("vatReg.enterVatNum") });
      return;
    }

    if (province && !tinNumber) {
      this.setState({ errorTinNumber: t("vatReg.enterTinNumber") });
      return;
    }

    if (
      (country === "DEU" && !germanyState) ||
      (country === "DEU" && !steuernummer)
    ) {
      this.setState({ errorSteuernummer: t("vatReg.enterStNum") });
      return;
    }

    if (country === "DEU" && germanyState) {
      const validate = this.validateSteuernummer(
        Number(germanyState),
        steuernummer
      );

      if (!validate) {
        this.setState({ errorSteuernummer: t("vatReg.invalidVal") });
        return;
      }
    }

    this.setState({
      addingError: null,
    });

    const params = {
      country_id: country,
      from_date: from,
      reason_for_registration_id: Number.parseInt(reason, 16),
      send_reminder: send_reminder,
    };

    if (vatNumber) {
      params.vat_number = vatNumber;
    }

    if (tinNumber) {
      params.tin_number = tinNumber;
      params.state = province;
    }

    if (country === "DEU") {
      params.germany_state_id = Number(germanyState);
      params.steuernummer = steuernummer;
    }

    if (till) {
      params.till_date = till;
    }

    if (agent) {
      params.vat_agent_id = Number.parseInt(agent, 16);
    }

    if (Number(reason) === 1) {
      params.calculate_threshold = threshold[0];
    }

    if (vatNexusDate) {
      params.nexus_date = formatDateForServer(vatNexusDate);
    }

    this.props
      .addRegNumber(params)
      .then((result) => {
        this.setState({ addingResult: result });
        if (userGuide && userGuide.show_user_guide) {
          this.props.fetchUserGuide();
        }
        this.props.fetchRegNumbers();
      })
      .catch((result) => {
        this.setState({ addingError: result });
      });

    this.setState({
      creationStatus: "result",
    });

    const defaultGermanyState = germanyStates?.[0] ?? {};
    const defaultReason = reasons?.[0] ?? {};
    const defaultCountry = countries?.[0] ?? {};

    /* reset */
    this.setState({
      state: defaultGermanyState.id,
      scheme: defaultGermanyState.scheme,
      country: defaultCountry.code,
      reason: defaultReason.id,
      from: formatDateForServer(defaultDates.from),
      till: null,
      province: null,
      send_reminder: false,
      vatNumber: "",
      tinNumber: "",
      vatNexusDate: "",
      steuernummer: "",
      selected: {
        start: defaultDates.from,
        end: undefined,
      },
    });
    this.toggleAddNumber(false);
  };

  editActivate = (id, activate) => {
    const formData = {
      type: "vat_reg",
      id,
      activate,
    };

    this.props
      .editActivateVatReg(formData)
      .then(() => {
        /* update the table */
        this.props.fetchRegNumbers().then(() => {
          this.setState({ activateError: false });
          this.handleActionDialogsClose("editActivate");
        });
      })
      .catch((result) => {
        this.setState({ activateError: result });
      });
  };

  editNumber = (data) => {
    let params = {
      id: data.id,
      vat_agent_id: data.vat_agent_id,
    };

    /* optional */
    if (data.till_date) {
      const till = { till_date: data.till_date };
      params = { ...params, ...till };
    }

    /* optional */
    if (data.vat_agent_id) {
      const agent = { vat_agent_id: data.vat_agent_id };
      params = { ...params, ...agent };
    }

    if (data.calculate_threshold === "boolean" || data.calculate_threshold) {
      params = {
        ...params,
        calculate_threshold:
          data.calculate_threshold === "boolean"
            ? data.calculate_threshold
            : data.calculate_threshold[0],
      };
    }

    this.props
      .editRegNumber(params)
      .then((result) => {
        this.setState({ addingResult: result });
        /* update the table */
        this.props.fetchRegNumbers().then(() => {
          this.handleActionDialogsClose("editNumber");
        });
      })
      .catch((result) => {
        this.setState({ addingError: result });
      });
  };

  editNumberManagerArea = (data) => {
    const { t } = this.props;

    let params = {
      id: data.id,
      country_id: data.country_id,
      from_date: data.from_date,
      till_date: data.till_date,
      reason_for_registration_id: +data.reason_for_registration_id,
      vat_number: data.vat_number,
    };

    /* optional */
    if (data.vat_agent_id) {
      const agent = { vat_agent_id: data.vat_agent_id };
      params = { ...params, ...agent };
    }

    if (data.calculate_threshold === "boolean" || data.calculate_threshold) {
      params = {
        ...params,
        calculate_threshold:
          data.calculate_threshold === "boolean"
            ? data.calculate_threshold
            : data.calculate_threshold[0],
      };
    }

    if (data.country_id === "DEU") {
      params = {
        ...params,
        steuernummer: data.steuernummer,
        germany_state_id:
          +data.germany_state_id || this.props.germanyStates[0].id,
      };
      const validate = this.validateSteuernummer(
        +data.germany_state_id || this.props.germanyStates[0].id,
        data.steuernummer
      );

      if (!validate) {
        this.setState({ errorSteuernummerDlg: t("vatReg.invalidVal") });
        return;
      }
    }

    this.props
      .editRegNumberManagerArea(params)
      .then((result) => {
        this.setState({ addingResult: result });
        this.props.fetchRegNumbers().then(() => {
          this.handleActionDialogsClose("editNumber");
          this.setState({
            monthDlg: new Date().getMonth(),
            yearDlg: new Date().getFullYear(),
            selectedDlg: null,
            schemaValue: false,
            errorSteuernummerDlg: false,
            editingManagerError: false,
          });
        });
      })
      .catch((result) => {
        this.setState({ editingManagerError: result });
      });
  };

  updateDialogData = (data) => {
    const monthDlg = data.till_date
      ? +data.till_date.split("-")[1] - 1
      : new Date().getMonth();
    const yearDlg = data.till_date
      ? +data.till_date.split("-")[0]
      : new Date().getFullYear();
    const selectedDlg = data.till_date
      ? new Date(
          +data.till_date.split("-")[0],
          +data.till_date.split("-")[1] - 1,
          +data.till_date.split("-")[2]
        )
      : undefined;

    const monthDlgFrom = data.from_date
      ? +data.from_date.split("-")[1] - 1
      : new Date().getMonth();
    const yearDlgFrom = data.from_date
      ? +data.from_date.split("-")[0]
      : new Date().getFullYear();
    const selectedDlgFrom = data.from_date
      ? {
          start: new Date(formatDate(data.from_date)),
          end: new Date(formatDate(data.from_date)),
        }
      : null;
    const taxRepLovatDlg = data.vat_agent_id === 2;

    this.setState({
      monthDlg,
      yearDlg,
      selectedDlg,
      monthDlgFrom,
      yearDlgFrom,
      selectedDlgFrom,
      taxRepLovatDlg,
    });
  };

  deleteRegOrder = (params) => {
    const { countries } = this.props;
    const { reasons } = this.props;
    /* names to ids */
    let countryId = null;
    _.map(countries, (country) => {
      if (country.name === params.country) {
        countryId = country.code;
      }
    });

    let reasonId = null;
    _.map(reasons, (reason) => {
      if (reason.name === params.reason_for_registration) {
        reasonId = reason.id;
      }
    });

    const answer = {
      country_id: countryId,
      reason_for_registration_id: reasonId,
    };
    if (answer) {
      this.props
        .deleteRegOrder({ answer })
        .then(() => {
          /* update the table */
          this.props.getAllNotCompletedOrders().then((result1) => {
            this.setState({ notCompletedOrders: result1 });
            this.handleActionDialogsClose("deleteOrder");
          });
        })
        .catch(_.noop);
    }
  };

  renderEditActivateDialog = () => {
    const { actionDialogs, activateError } = this.state;
    const { t, editingActivateVatReg } = this.props;
    const { data } = actionDialogs.editActivate;

    return (
      data && (
        <Modal
          title={"Edit active status"}
          onClose={() => {
            this.handleActionDialogsClose("editActivate");
            this.setState({ activateError: false });
          }}
          iconType='warning'
          description={
            <p>
              Do you want to {data.activate ? "deactivate" : "activate"}{" "}
              {t("vatReg.vatNum")} {data.vat_number} {t("vatReg.for")}{" "}
              {data.reason_for_registration} {t("vatReg.in")} {data.country}?
            </p>
          }
          visible={actionDialogs.editActivate.open}
          content={
            <>
              {activateError && (
                <div>
                  <Banner title={t("vatReg.err")} tone='critical' />
                  <br />
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                status
              </div>
            </>
          }
          footer={
            <Button
              variant='primary'
              onClick={() => this.editActivate(data.id, !data.activate)}
              loading={editingActivateVatReg}
            >
              {data.activate ? "Deactivate" : "Activate"}
            </Button>
          }
        />
      )
    );
  };

  renderEditNumberDialog = () => {
    const {
      actionDialogs,
      schemeDlg,
      editingManagerError,
      errorSteuernummerDlg,
    } = this.state;
    const { data } = actionDialogs.editNumber;
    const { user, countries, reasons, germanyStates, t } = this.props;

    const countriesOptions = _.map(countries, (country) => ({
      key: `${country.code}cDlg`,
      label: country.name_no_article,
      value: country.code,
    }));

    const reasonsOptions = _.map(reasons, (reason) => ({
      key: `${reason.id}rDlg`,
      label: reason.name,
      value: reason.id.toString(),
    }));

    const statesOptions = _.map(germanyStates, (state) => ({
      key: `${state.id}s`,
      label: state.name,
      value: state.id.toString(),
    }));

    let schemaValue = undefined;

    if (schemeDlg) {
      if (data && data.germany_state_id) {
        schemaValue = this.props.germanyStates.find(
          (item) => item.id === +data.germany_state_id
        ).scheme;
      } else {
        schemaValue = schemeDlg;
      }
    } else {
      schemaValue = this.props.germanyStates[0].scheme;
    }

    const onClose = () => {
      this.handleActionDialogsClose("editNumber");
      delete actionDialogs.editNumber.data;
      this.setState({
        monthDlg: new Date().getMonth(),
        yearDlg: new Date().getFullYear(),
        selectedDlg: null,
        schemaValue: false,
        errorSteuernummerDlg: false,
        editingManagerError: false,
      });
    };

    const updateActionDialogs = (key, value) => {
      this.setState((prevState) => ({
        actionDialogs: {
          ...prevState.actionDialogs,
          editNumber: {
            ...prevState.actionDialogs.editNumber,
            data: {
              ...prevState.actionDialogs.editNumber.data,
              [key]: value,
            },
          },
        },
      }));
    };

    return (
      data && (
        <Modal
          title={t("vatReg.editVatReg")}
          onClose={onClose}
          visible={actionDialogs.editNumber.open}
          description={
            <>
              {t("vatReg.vatNum")} {data.vat_number} {t("vatReg.for")}{" "}
              {data.reason_for_registration} {t("vatReg.in")} {data.country}
            </>
          }
          content={
            <div>
              {editingManagerError && (
                <div>
                  <br />
                  <Banner title={t("vatReg.err")} tone='critical' />
                </div>
              )}
              <br />
              <Box
                display="flex"
                flexDirection="column"
                gap="2"
                width="100%"
              >
                {user.is_manager && (
                  <FormLayout>
                    <FormLayout.Group condensed>
                      <Select
                        label={`${t("vatReg.country")}:`}
                        options={countriesOptions}
                        onChange={(id) => {
                          updateActionDialogs("country_id", id);
                        }}
                        value={data.country_id}
                      />
                      <Select
                        label={`${t("vatReg.reasonForReg")}:`}
                        options={reasonsOptions}
                        onChange={(id) => {
                          updateActionDialogs("reason_for_registration_id", id);
                        }}
                        value={data.reason_for_registration_id.toString()}
                      />
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <TextField
                        label={t("vatReg.vatNum")}
                        type='text'
                        value={data.vat_number}
                        error={!data.vat_number}
                        onChange={(number) => {
                          updateActionDialogs("vat_number", number);
                        }}
                      />
                    </FormLayout.Group>
                    {data.country_id === "DEU" && (
                      <FormLayout.Group>
                        <Select
                          label={`${t("vatReg.federalSt")}:`}
                          options={statesOptions}
                          onChange={(state) => {
                            const scheme = this.props.germanyStates.find(
                              (item) => item.id === +state
                            ).scheme;
                            updateActionDialogs("germany_state_id", state);
                            this.setState({ schemeDlg: scheme });
                          }}
                          value={
                            data && data.germany_state_id
                              ? data.germany_state_id.toString()
                              : this.props.germanyStates[0].id.toString()
                          }
                        />
                        <TextField
                          label={`${t("vatReg.stNum")}(${schemaValue})`}
                          type='text'
                          value={data.steuernummer}
                          error={!data.steuernummer || errorSteuernummerDlg}
                          onChange={(steuernummer) => {
                            updateActionDialogs("steuernummer", steuernummer);
                          }}
                        />
                        <br />
                      </FormLayout.Group>
                    )}
                  </FormLayout>
                )}

                {user.is_manager && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-end',
                      gap: 'desired-spacing-value'
                    }}
                  >
                     <Datepicker
                      label={"Number valid from"}
                      start={data.from_date}
                      value={data.from_date}
                      allowRange={false}
                      onMonthChange={(monthDlgFrom,
                        yearDlgFrom) => {
                        this.setState({
                          monthDlgFrom,
                          yearDlgFrom,
                        });
                      }}
                      onChange={(value) => {
                        const selectedDlgFrom = {
                          start: value,
                          end: value,
                        };
                        updateActionDialogs(
                          "from_date",
                          value ? formatDateForServer(value) : null
                        );
                        this.setState({selectedDlgFrom});
                      }}
                    />
                    <br />
                  </div>
                )}
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent="end"
                  gap="4"
                  >
                  <Datepicker
                    label={t("vatReg.numberValidTill")}
                    isClearable={data.till_date}
                    allowRange={false}
                    start={data.till_date}
                    value={data.till_date}
                    onMonthChange={(monthDlg, yearDlg) => {
                      this.setState({
                        monthDlg,
                        yearDlg,
                      });
                    }}
                    onChange={(value) => {
                      const selectedDlg = {
                        start: value,
                        end: value,
                      };
                      updateActionDialogs(
                        "till_date",
                        value ? formatDateForServer(value) : null
                      );
                      this.setState({ selectedDlg });
                    }}
                  />
                  {user.is_manager && (
                    <Checkbox
                      label={t("vatReg.lovatTaxRep")}
                      checked={this.state.taxRepLovatDlg}
                      onChange={(taxRepLovatDlg) => {
                        updateActionDialogs(
                          "vat_agent_id",
                          taxRepLovatDlg ? 2 : -1
                        );
                        this.setState({ taxRepLovatDlg });
                      }}
                    />
                  )}
                </Box>

                {Number(data.reason_for_registration_id) === 1 && (
                  <ChoiceList
                    choices={[
                      { label: t("vatReg.calcThresh.yes"), value: true },
                      { label: t("vatReg.calcThresh.no"), value: false },
                    ]}
                    selected={String(data.calculate_threshold)}
                    onChange={(value) => {
                      updateActionDialogs("calculate_threshold", value);
                    }}
                  />
                )}
                <p style={{ maxWidth: "450px" }}>
                  {t("vatReg.contactManager1")}{" "}
                  <a
                    className='Polaris-Link'
                    href={`mailto:${this.state.user.company.manager.email}`}
                  >
                    {this.state.user.company.manager.email}
                  </a>
                  {t("vatReg.contactManager2")}
                </p>
              </Box>
            </div>
          }
          footer={
            <ButtonGroup fullWidth>
              <Button size='large' onClick={onClose}>
                {t("vatReg.cancel")}
              </Button>
              <Button
                primary
                size='large'
                onClick={
                  user.is_manager
                    ? () => this.editNumberManagerArea(data)
                    : () => this.editNumber(data)
                }
              >
                {t("vatReg.edit")}
              </Button>
            </ButtonGroup>
          }
        />
      )
    );
  };

  renderPayDialog = () => {
    const { actionDialogs /*, data, regReason */ } = this.state;
    const { data } = actionDialogs.pay;
    const { user, t } = this.props;
    /* get country VAT reg info */
    let country = {};
    if (data) {
      this.props.regCountries.map((item) => {
        if (item.name === data.country) {
          country = item;
        }
      });
    }

    return (
      data && (
        <Modal
          title={t("vatReg.vatRegPayment")}
          onClose={() => {
            this.handleActionDialogsClose("pay");
          }}
          visible={actionDialogs.pay.open}
          content={
            <Payment
              currency={user.company.currency_code}
              currencyIcon={getCurrencySymbol(user.company.currency_code)}
              country={data.country_id}
              regReason={data.reason_for_registration}
              service={"VAT registration"}
              sum={country.price}
              comment={`${t("vatReg.vatRegIn1")} ${country.name}${t(
                "vatReg.vatRegIn2"
              )}${data.reason_for_registration} ${t("vatReg.vatRegIn3")}`}
              reason={data.reason_for_registration}
              //openNextDialog={this.handleActionDialogsOpen('notification')}
              closeDialog={this.handleActionDialogsClose}
              openNextDialog={this.handleActionDialogsOpen}
              currentDialog='pay'
              nextDialog='notification'
            />
          }
        />
      )
    );
  };

  renderNotificationDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={t("vatReg.payment")}
        onClose={() => {
          this.handleActionDialogsClose("notification");
        }}
        description={
          <div style={{ maxWidth: "450px" }}>{t("vatReg.thankForReq")}</div>
        }
        visible={actionDialogs.notification.open}
        footer={
          <Button
            primary
            onClick={() => {
              this.handleActionDialogsClose("notification");
            }}
          >
            OK
          </Button>
        }
      />
    );
  }

  renderDeleteOrderDialog() {
    const { actionDialogs } = this.state;
    const { data } = actionDialogs.deleteOrder;
    const { t } = this.props;

    return !_.isEmpty(data) ? (
      <Modal
        title={t("vatReg.delVatREg")}
        contentOnCenter
        iconType={"danger"}
        onClose={this.handleActionDialogsClose.bind(null, "deleteOrder")}
        visible={actionDialogs.deleteOrder.open}
        description={
          <>
            <p>
              {t("vatReg.wantDelFor")} {data.reason_for_registration}{" "}
              {t("vatReg.in")} {data.country}?
            </p>
          </>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button
              onClick={this.handleActionDialogsClose.bind(null, "deleteOrder")}
              size='large'
            >
              {t("vatReg.cancel")}
            </Button>
            <Button
              primary
              onClick={() => this.deleteRegOrder(data)}
              destructive
              size='large'
            >
              {t("vatReg.delete")}
            </Button>
          </ButtonGroup>
        }
      />
    ) : undefined;
  }

  renderAddNumber() {
    const { countries, reasons, germanyStates, vatCountryStates, t } =
      this.props;
    const {
      selected,
      errorVatNumber,
      errorTinNumber,
      errorSteuernummer,
      steuernummer,
      scheme,
    } = this.state;

    const selectedCountryProvinces = vatCountryStates[this.state.country] ?? [];
    const shouldShowProvincesSelect = Boolean(selectedCountryProvinces.length);
    const shouldShowTinNumberInput = Boolean(this.state.province);

    const countriesOptions = _.map(countries, (country) => ({
      key: `${country.code}c`,
      label: country.name_no_article,
      value: country.code,
    }));

    const reasonsOptions = _.map(reasons, (reason) => ({
      key: `${reason.id}r`,
      label: reason.name,
      value: reason.id.toString(),
    }));

    const statesOptions = _.map(germanyStates, (state) => ({
      key: `${state.id}s`,
      label: state.name,
      value: state.id.toString(),
    }));

    const provincesOptions = [
      "",
      ..._.map(selectedCountryProvinces, (province, i) => ({
        key: province,
        label: province,
        value: province,
      })),
    ];

    return (
      <div style={{ overflow: 'visible', background: 'white', padding: 30, border: '1px solid rgba(227, 227, 227, 1)', borderRadius: '4px' }}>
        <form>
          <FormLayout>
            <Text variant='headingLg' as='h5'>
              Add VAT number
            </Text>
            <FormLayout.Group condensed>
              <Select
                label={`${t("vatReg.country")}:`}
                options={countriesOptions}
                onChange={(country) => {
                  this.setState({ country, paymentStatus: undefined });
                }}
                value={this.state.country}
              />
              {shouldShowProvincesSelect && (
                <Select
                  label={t("vatReg.province")}
                  options={provincesOptions}
                  onChange={(province) => {
                    const tinNumber = province ? this.state.tinNumber : "";
                    this.setState({
                      province,
                      tinNumber,
                      vatNumber: "",
                    });
                  }}
                  value={this.state.province}
                />
              )}
              {shouldShowTinNumberInput ? (
                <TextField
                  label={t("vatReg.tin")}
                  type='text'
                  value={this.state.tinNumber}
                  error={errorTinNumber}
                  onChange={this.handleChangeTinNumber}
                />
              ) : (
                <TextField
                  label={t("vatReg.vatNum")}
                  type='text'
                  value={this.state.vatNumber}
                  error={errorVatNumber}
                  onChange={this.handleChangeVatNumber}
                />
              )}
              <Select
                label={`${t("vatReg.reasonForReg")}:`}
                options={reasonsOptions}
                onChange={(reason) => {
                  this.setState({ reason, paymentStatus: undefined });
                }}
                value={this.state.reason}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Datepicker
                label={t("taxSettings.nexusEstablished")}
                value={this.state.vatNexusDate}
                allowRange={false}
                onChange={(date) => {
                  this.setState({
                    vatNexusDate: date,
                  });
                }}
                isLimited={false}
                autoComplited='off'
              />
              <Datepicker
                label={t("vatReg.validFrom")}
                value={selected.start}
                onChange={this.handleFromDateChange}
                allowRange={false}
                isLimited={false}
                autoComplited='off'
              />
              <Datepicker
                label={t("vatReg.validTill")}
                value={selected.end}
                onChange={this.handleTillDateChange}
                allowRange={false}
                alignment={"right"}
                isLimited={false}
                autoComplited='off'
              />
            </FormLayout.Group>

            {this.state.country === "DEU" && (
              <FormLayout.Group condensed>
                <Select
                  label={`${t("vatReg.federalSt")}:`}
                  options={statesOptions}
                  onChange={(state) => {
                    const newScheme = this.props.germanyStates.find(
                      (item) => item.id === +state
                    ).scheme;
                    this.setState({
                      state,
                      scheme: newScheme,
                      paymentStatus: undefined,
                    });
                  }}
                  value={this.state.state}
                />
                <TextField
                  label={`${t("vatReg.stNum")}(${scheme}):`}
                  type='text'
                  value={steuernummer}
                  error={errorSteuernummer}
                  onChange={this.handleChangeSteuernummer}
                />
                <br />
                <br />
                <br />
              </FormLayout.Group>
            )}

            {+this.state.reason === 1 && (
              <FormLayout.Group>
                <ChoiceList
                  choices={[
                    { label: t("vatReg.calcThresh.yes"), value: true },
                    { label: t("vatReg.calcThresh.no"), value: false },
                  ]}
                  selected={this.state.threshold}
                  onChange={(value) => this.setState({ threshold: value })}
                />
              </FormLayout.Group>
            )}

            <FormLayout.Group>
              <Checkbox
                checked={this.state.check}
                label={t("vatReg.appLovat")}
                onChange={(isChecked) => {
                  if (isChecked) {
                    this.setState({ check: true, agent: 2 }); // LOVAT agent
                  } else {
                    this.setState({ check: false, agent: null });
                  }
                }}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Checkbox
                checked={this.state.send_reminder}
                label={t("vatReg.taxRepThroughLovat")}
                onChange={(isChecked) => {
                  this.setState({ send_reminder: isChecked });
                }}
              />
            </FormLayout.Group>
          </FormLayout>
          <br />
          <ButtonGroup>
            <Button variant={'primary'} size={'micro'} onClick={this.addNumber}>
              {t("vatReg.addRegInfo")}
            </Button>
            <Button size={'micro'} onClick={() => this.toggleAddNumber(false)}>
              {t("vatReg.cancel")}
            </Button>
          </ButtonGroup>
        </form>
      </div>
    );
  }

  renderRegNumbers() {
    const { regNumbers, t } = this.props;
    const { notCompletedOrders } = this.state;

    const notCompletedOrdersList = _.map(notCompletedOrders, (order) => {
      return {
        id: "o" + order.id,
        country: order?.data?.["Registration Country"] || "",
        vat_number: t("vatReg.notFinished"),
        reason_for_registration: order?.data?.["Reason for registration"] || "",
      };
    });
    const rows = regNumbers.concat(notCompletedOrdersList);

    const columns = [
      {
        property: "country",
        header: {
          label: t("vatReg.country"),
        },
        cell: {
          formatters: [
            (value, { rowData }) =>
              `${value} ${rowData.state ? `(${rowData.state})` : ""}`,
          ],
        },
      },
      {
        property: "reason_for_registration",
        header: {
          label: t("vatReg.reasonForReg"),
        },
      },
      {
        property: "vat_number",
        header: {
          label: t("vatReg.vatNum"),
        },
        cell: {
          formatters: [(value, { rowData }) => value ?? rowData.tin_number],
        },
      },
      {
        property: "from_date",
        header: {
          label: t("vatReg.from"),
        },
        cell: {
          formatters: [
            (value) => (
              <span style={{ whiteSpace: "nowrap" }}>
                {value ? formatDate(value) : value}
              </span>
            ),
          ],
        },
      },
      {
        property: "till_date",
        header: {
          label: t("vatReg.till"),
          transforms: [this.state.sortable],
        },
        cell: {
          formatters: [
            (value) => (
              <span style={{ whiteSpace: "nowrap" }}>
                {value ? formatDate(value) : value}
              </span>
            ),
          ],
        },
      },
      {
        property: "activate",
        header: {
          label: t("vatReg.active"),
          transforms: [this.state.sortable],
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}
                gap="3"
              >
                {this.props.user.is_manager && (
                  <Tooltip content={t("vatReg.edit")}>
                    <Button
                      variant={'plain'}
                      onClick={() =>
                        this.handleActionDialogsOpen("editActivate", rowData)
                      }
                    >
                      <img src={settingsIMG} alt='edit'  style={{margin: '0 5px'}}/>
                    </Button>
                  </Tooltip>
                )}
                {value ? (
                  <img src={checkmarkIMG} alt='checkmark' style={{margin: '0 5px'}}/>
                ) : (
                  <span style={{ padding: "0 5px" }}>
                    <img src={crossIMG} alt='cross'  style={{margin: '0 5px'}}/>
                  </span>
                )}
              </div>
            ),
          ],
        },
      },
      {
        property: "calculate_threshold",
        header: {
          label: t("vatReg.thresh"),
          transforms: [this.state.sortable],
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              let threshold;
              if (rowData.reason_for_registration_id === 1) {
                // 'Distant sales'
                threshold = value
                  ? (threshold = t("vatReg.yes"))
                  : (threshold = t("vatReg.no"));
              } else {
                threshold = "";
              }
              return threshold;
            },
          ],
        },
      },
      {
        property: "vat_agent",
        header: {
          label: t("vatReg.vatRepr"),
          transforms: [this.state.sortable],
        },
      },
      {
        property: "actions",
        header: {
          label: t("vatReg.actions"),
          transforms: [],
        },
        cell: {
          formatters: [
            (value, { rowData }) =>
              (rowData.vat_number === t("vatReg.notFinished") && (
                <span style={{ display: "flex" }}>
                  <Tooltip content={t("vatReg.delete")}>
                    <Button
                      variant={'plain'}
                      onClick={() =>
                        this.handleActionDialogsOpen("deleteOrder", rowData)
                      }
                    >
                      <img src={deleteIMG} alt='delete' />
                    </Button>
                  </Tooltip>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <Tooltip content={t("vatReg.continue")}>
                    <Button variant={'plain'} onClick={this.handleOrderReg}>
                      <img src={continueIMG} alt='continue' />
                    </Button>
                  </Tooltip>
                </span>
              )) ||
              (rowData.vat_number === "pending payment" && (
                <span style={{ display: "flex" }}>
                  <Tooltip content={t("vatReg.edit")}>
                    <Button
                      variant={'plain'}
                      onClick={() =>
                        this.handleActionDialogsOpen("editNumber", rowData)
                      }
                    >
                      <img src={settingsIMG} alt='edit' />
                    </Button>
                  </Tooltip>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <Tooltip content={t("vatReg.pay")}>
                    <Button
                      variant={'plain'}
                      onClick={() =>
                        this.handleActionDialogsOpen("pay", rowData)
                      }
                    >
                      <img src={payIMG} alt='pay' />
                    </Button>
                  </Tooltip>
                </span>
              )) || (
                <Tooltip content={t("vatReg.edit")}>
                  <Button
                    variant={'plain'}
                    onClick={() =>
                      this.handleActionDialogsOpen("editNumber", rowData)
                    }
                  >
                    <img src={settingsIMG} alt='edit' />
                  </Button>
                </Tooltip>
              ),
          ],
        },
      },
    ];
    const sortedRows = formatRow(rows, columns);

    return (
      <div>
        {!_.isEmpty(sortedRows) ? (
          <DataTable
            columnContentTypes={["text", "text", "text", "text", "text"]}
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold'>{header.label}</Text>
            ))}
            rows={sortedRows}
          />
        ) : (
          <NoDataMessage
            description='Add VAT registrations to see data'
            buttonText='Add VAT registration'
            onClick={() => this.toggleAddNumber(true)}
          />
        )}
      </div>
    );
  }

  render() {
    const { user, regNumbersFetching, t } = this.props;
    const { isOpenAddNumber, isCheckedThresholds, errorCheckingThresholds } =
      this.state;

    return (
      <div>
        {isOpenAddNumber && (
          <div>
            {this.renderAddNumber()}
            <br />
          </div>
        )}
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}>
          <div style={{ display: 'flex', height: '100%' }}>
            <ButtonGroup>
              {!isOpenAddNumber && (
                <Button
                  loading={this.props.adding}
                  variant={'primary'}
                  onClick={() => this.toggleAddNumber(true)}
                  icon={
                    AddIcon
                  }
                >
                  {user.is_omp
                    ? "Add new VAT certificate"
                    : t("vatReg.addVatReg")}
                </Button>
              )}
              <Button
                onClick={this.handleOrderReg}
                disabled={isOpenAddNumber}
                icon={<img src={cartIMG} alt='cart' style={{ marginTop: 3 }} />}
              >
                <span style={{ fontSize: '14px', fontWeight: 400, color: 'rgba(51, 51, 51, 1)' }}> {t("vatReg.orderVatReg")}</span>
              </Button >
            </ButtonGroup >
          </div>
          <Checkbox
            label={t("vatReg.calcThresholds")}
            checked={
              isCheckedThresholds || isCheckedThresholds === false
                ? isCheckedThresholds
                : this.props.user.company.calculate_threshold
            }
            onChange={(value) => {
              this.props
                .switchCalculateThresholds(value)
                .then(() => this.setState({ isCheckedThresholds: value }))
                .catch((err) =>
                  this.setState({ errorCheckingThresholds: err.error })
                );
            }}
          />
        </div>
        <br />
        <Card sectioned={regNumbersFetching}>
          {regNumbersFetching ? <Spinner /> : this.renderRegNumbers()}
          {errorCheckingThresholds && (
            <div>
              <div>
                <Banner tone='critical'>{errorCheckingThresholds}</Banner>
                <br />
              </div>
            </div>
          )}
        </Card>
        {this.renderEditActivateDialog()}
        {this.state.actionDialogs.editNumber.open &&
          this.renderEditNumberDialog()}
        {this.renderDeleteOrderDialog()}
        {this.renderPayDialog()}
        {this.renderNotificationDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userGuide: state.user.userGuide,
  countries: state.other.countries,
  reasons: state.vatRegistration.reasons,
  adding: state.vatRegistration.adding,
  regNumbers: state.vatRegistration.regNumbers,
  regNumbersFetching: state.vatRegistration.fetching,
  regCountries: state.vatRegistration.regCountries,
  germanyStates: state.vatRegistration.germanyStates,
  vatCountryStates: state.vatRegistration.vatCountryStates,
  user: state.user.user,
  notCompletedOrders: state.vatRegistration.notCompletedOrders,
  defaultLanguage: state.user.defaultLanguage,
  editingActivateVatReg: state.manager.editingActivateVatReg,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchCompanyDefaultLanguage: () => dispatch(fetchCompanyDefaultLanguage()),
    fetchRegNumbers: () => dispatch(fetchRegNumbers()),
    fetchUser: () => dispatch(fetchUser()),
    fetchUserGuide: () => dispatch(fetchUserGuide()),
    addRegNumber: (params) => dispatch(addRegNumber(params)),
    editRegNumber: (params) => dispatch(editRegNumber(params)),
    getAllNotCompletedOrders: () => dispatch(getAllNotCompletedOrders()),
    deleteRegOrder: () => dispatch(deleteRegOrder()),
    editActivateVatReg: (params) => dispatch(editActivateVatReg(params)),
    editRegNumberManagerArea: (params) =>
      dispatch(editRegNumberManagerArea(params)),
    switchCalculateThresholds: (params) =>
      dispatch(switchCalculateThresholds(params)),
    fetchGermanyStates: () => dispatch(fetchGermanyStates()),
    fetchReasons: () => dispatch(fetchReasons()),
    fetchRegCountries: () => dispatch(fetchRegCountries()),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchVatCountryStates: () => dispatch(fetchVatCountryStates()),
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(VATRegistration))
);
