import * as PropTypes from 'prop-types'
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { isEmail, isPhone, isLatin } from "utils/validation";

import {
  Button,
  Select,
  Tag,
  TextField,
  FormLayout,
  Banner,
  Layout,
  List,
  Card,
  ButtonGroup,
  Box,
  Spinner,
  Text,
} from "@shopify/polaris";

import clipIMG from "img/clip.svg";
import GuideCompletionPopup from "components/GuideCompletionPopup/GuideCompletionPopup";
import InfoTooltip from "components/InfoTooltip/InfoTooltip";
import { createCustomDispatch } from "helpers/customDispatch";
import SearchingCountry from '../../../../components/SearchingCountry/SearchingCountry'
import { changeCompanyInfo, editUser, fetchUser, fetchUserGuide } from '../../../../redux/features/user/userSlice'

const statusArr = [
  {
    value: "pending",
    label: "pending",
  },
  {
    value: "active",
    label: "active",
  },
  {
    value: "ban",
    label: "ban",
  },
  {
    value: "achieved",
    label: "achieved",
  },
];

function Typography(props) {
  return null
}

Typography.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node
}

class ProfileCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyId: null,
      isEdit: false,

      name: "",
      errorName: false,

      country: "",
      errorCountry: false,

      person: "",
      errorPerson: false,

      regCode: "",
      errorRegCode: false,

      postcode: "",
      errorPostCode: false,

      locality: "",
      errorLocality: false,

      street: "",
      errorStreet: false,

      housenum: "",
      errorHousenum: false,

      apartment: "",
      errorAppartment: false,

      email: "",
      errorEmail: false,

      phone: "",
      errorPhone: false,

      status: "",
      errorStatus: false,

      vat: "",
      errorVat: false,

      salesTaxNum: "",
      errorSalesTaxNum: false,

      fileNameList: {
        proof_files: [],
      },
    };
  }

  componentDidUpdate() {
    const { user, userGuide } = this.props;
    const prevCompanyId = this.state.companyId;
    const nextCompanyId = user.company.id;

    if (prevCompanyId !== nextCompanyId) {
      this.setState({
        companyId: nextCompanyId,
        isEdit:
          userGuide &&
          userGuide.show_user_guide &&
          !userGuide.steps[0].complete &&
          user.company &&
          user.company.is_active
            ? true
            : false,
        name: (user.company && user.company.name) || "",
        country: user.company ? user.company.country_id : "",
        person: (user.company && user.company.person) || "",
        postcode: (user.company && user.company.info.postcode) || "",
        errorPostCode:
          user.company && !user.company.info.postcode ? true : false,
        locality: (user.company && user.company.info.locality) || "",
        errorLocality:
          user.company && !user.company.info.locality ? true : false,
        street: (user.company && user.company.info.street) || "",
        errorStreet: user.company && !user.company.info.street ? true : false,
        housenum: (user.company && user.company.info.housenum) || "",
        errorHousenum:
          user.company && !user.company.info.housenum ? true : false,
        apartment: (user.company && user.company.info.apartment) || "",
        email: (user.company && user.company.email) || "",
        phone: (user.company && user.company.phone) || "",
        status: (user.company && user.company.company_status) || "",
        vat: (user.company && user.company.info.default_vat_number) || "",
        regCode: (user.company && user.company.info.reg_code) || "",
        errorVat:
          user.company && !user.company.info.default_vat_number ? true : false,
        salesTaxNum:
          (user.company && user.company.info.default_sales_tax_number) || "",
        errorSalesTaxNum:
          user.company && !user.company.info.default_sales_tax_number
            ? true
            : false,
      });
    }
  }

  docsFiles = {
    proof_files: [],
  };

  attachFiles = (docsFiles) => {
    // attach documents to form
    const formDataFiles = new FormData();
    for (let i = 0; i < docsFiles.proof_files.length; i++) {
      formDataFiles.append(
        "file_" + i,
        docsFiles.proof_files[i],
        docsFiles.proof_files[i].name
      );
    }
    return formDataFiles;
  };

  afterEditUser = () => {
    const { userGuide } = this.props;
    if (userGuide && userGuide.show_user_guide) {
      this.props.fetchUserGuide();
    }

    this.props
      .fetchUser()
      .then((result) => {
        this.setState({
          user: result,
        });
      })
      .catch(_.noop);
  };

  doEdit = (event) => {
    event.preventDefault();
    const { user } = this.props;
    const {
      errorName,
      errorEmail,
      errorPerson,
      errorPhone,
      errorCountry,
      errorPostCode,
      errorLocality,
      errorStreet,
      errorHousenum,
      errorVat,
      errorSalesTaxNum,
      errorApartment,
      name,
      postcode,
      locality,
      street,
      housenum,
      apartment,
      email,
      person,
      regCode,
      phone,
      country,
      status,
      vat,
      salesTaxNum,
    } = this.state;

    if (
      errorName ||
      errorPostCode ||
      errorLocality ||
      errorStreet ||
      errorHousenum ||
      errorEmail ||
      errorPerson ||
      errorPhone ||
      errorCountry ||
      (errorVat && country !== "USA" && country !== "VIR") ||
      (errorSalesTaxNum && (country === "USA" || country === "VIR")) ||
      errorApartment
    )
      return;

    const formData = this.attachFiles(this.docsFiles);
    formData.append("name", name);
    formData.append("apartment", apartment);
    formData.append("email", email);
    formData.append("postcode", postcode);
    formData.append("locality", locality);
    formData.append("street", street);
    formData.append("housenum", housenum);
    formData.append("contact_person", person);
    formData.append("phone", phone);
    formData.append("country", country);
    formData.append("reg_code", regCode);

    if (country !== "USA" && country !== "VIR") {
      formData.append("default_vat_number", vat);
    } else {
      formData.append("default_sales_tax_number", salesTaxNum);
    }

    if (user.is_manager) {
      formData.append("company_status", status);
    }

    this.props
      .changeCompanyInfo(formData)
      .then(() => {
        this.afterEditUser();
        const { fileNameList } = this.state;
        fileNameList.proof_files = [];
        this.setState({
          fileNameList,
          errorApartment: false,
        });
      })
      .then(() => this.setState({ isEdit: false }))
      .catch(_.noop);
  };

  handleFileSelect = (docsType) => {
    const { fileNameList } = this.state;

    if (this.fileUploadInput) {
      this.fileUploadInput.click();
      this.fileUploadInput.onchange = () => {
        const files = this.fileUploadInput.files;
        for (let i = 0; i < files.length; i++) {
          this.doDeleteFile(docsType, files[i].name); // delete the same file from list, comparing inside "doDeleteFile" function
          this.docsFiles[docsType][this.docsFiles[docsType].length] = files[i]; // add file
          fileNameList[docsType][fileNameList[docsType].length] = files[i].name; // add filename
        }
        this.setState({ fileNameList });
      };
    }
  };

  handleChange = (value, id, errorName, errorText) => {
    if (value.length > 0 && isLatin(value)) {
      this.setState({
        [id]: value,
        [errorName]: false,
      });
    } else if (value.length > 0 && !isLatin(value)) {
      this.setState({
        [id]: value,
        [errorName]: "Use the Latin alphabet!",
      });
    } else {
      this.setState({
        [id]: "",
        [errorName]: errorText,
      });
    }
  };

  handleChangeCountry = (country) => {
    const { t } = this.props;
    if (country.length > 0) {
      this.setState({
        country,
        errorCountry: false,
      });
    } else {
      this.setState({
        country: "",
        errorCountry: t("profileComp.err.country"),
      });
    }
  };

  handleChangeCompanyEmail = (email) => {
    const { t } = this.props;
    if (email.length > 0 && isEmail(email)) {
      this.setState({
        email,
        errorEmail: false,
      });
    } else if (email.length > 0 && !isEmail(email)) {
      this.setState({
        email,
        errorEmail: t("profileComp.err.emailInvalid"),
      });
    } else {
      this.setState({
        email: "",
        errorEmail: t("profileComp.err.email"),
      });
    }
  };

  handleChangeCompanyPhone = (phone) => {
    const { t } = this.props;
    if (phone.length > 0 && isPhone(phone)) {
      this.setState({
        phone,
        errorPhone: false,
      });
    } else if (phone.length > 0 && !isPhone(phone)) {
      this.setState({
        phone,
        errorPhone: t("profileComp.err.phoneInvalid"),
      });
    } else {
      this.setState({
        phone: "",
        errorPhone: t("profileComp.err.phone"),
      });
    }
  };

  doDeleteFile = (fileListName, fileName) => {
    const { fileNameList } = this.state;
    let fileToDelete = undefined;
    _.map(this.docsFiles[fileListName], (file, index) => {
      if (file.name === fileName) {
        fileToDelete = index;
      } // get index
    });

    if (fileToDelete !== undefined) {
      this.docsFiles[fileListName].splice(fileToDelete, 1); // delete file name the list
      fileNameList[fileListName].splice(fileToDelete, 1); // delete file name from the list
      this.setState({ fileNameList });
    }
  };

  renderMultipleFileSelect(docType) {
    const { t } = this.props;
    const { fileNameList } = this.state;
    return (
      <div>
        <Box
          display="flex"
          alignItems="center"
          gap="3"
        >
          <Button
            onClick={() => this.handleFileSelect(docType)}
            disabled={this.props.isUploading}
            icon={<img src={clipIMG} alt='clip icon' />}
          >
            <Text color='subdued'>{t("profileComp.chooseFile")}</Text>
            <input
              type='file'
              multiple
              style={{ display: "none" }}
              ref={(input) => (this.fileUploadInput = input)}
            />
          </Button>
          <Text color='subdued' variant='bodySm'>
            Choose *jpg, *jpeg, *png files to upload
          </Text>
        </Box>
        <div>
          {_.map(fileNameList[docType], (fileName, index) => {
            return (
              <div
                style={{ display: "inline-block", margin: "5px" }}
                key={index}
              >
                <Tag
                  key={index}
                  onRemove={() => {
                    this.doDeleteFile(docType, fileName);
                  }}
                >
                  {fileName}
                </Tag>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderRequired = (data) => (
    <Text variant="bodyMd" color="critical">
      {data} required
    </Text>
  );

  renderViewCompany() {
    const { t, user } = this.props;
    return (
      <div>
        <div
          style={{
            display: 'flex', flexDirection: 'column', width: '100%',
          }}
          // gap="5"
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15}}>
            <div>
              <Text variant='bodyLg'>{t("profileComp.companyName")}:</Text>
              <Text variant={'bodyXs'}>{user.company.name}</Text>
            </div>

            <div style={{ width: '30%' }}>
              <Text variant='bodyLg'>{t("profileComp.merchID")}:</Text>
              <Text variant={'bodyXs'}>
                {user.company.id.toString().padStart(10, "0")}
              </Text>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15}}>
            <div>
              <Text variant='bodyLg'>{t("profileComp.address")}:</Text>
              <Text variant={'bodyXs'}>
                <div style={{ maxWidth: "350px" }}>
                  {user.company.info.postcode ||
                    this.renderRequired(t("profileComp.postcode"))}
                  ,&nbsp;
                  {user.company.country ||
                    this.renderRequired(t("profileComp.country"))}
                  ,&nbsp;
                  {user.company.info.locality ||
                    this.renderRequired(t("profileComp.cityTown"))}
                  ,&nbsp;
                  {user.company.info.street ||
                    this.renderRequired(t("profileComp.strAddress"))}
                  ,&nbsp;
                  {user.company.info.housenum ||
                    this.renderRequired(t("profileComp.housenum"))}
                  &nbsp;
                  {user.company.info.apartment !== "undefined" &&
                  !_.isEmpty(user.company.info.apartment)
                    ? user.company.info.apartment
                    : null}
                </div>
              </Text>
            </div>
            <div style={{width: '30%'}}>
              <Text variant='bodyLg'>{t("profileComp.contactPeson")}:</Text >
              <Text variant={'bodyXs'}>{user.company.person}</Text >
            </div >
          </div >

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15}}>
            <div>
              <Text variant='bodyLg'>{t("profileComp.companyEmail")}:</Text>
              <a
                href={`mailto:${user.company.email}`}
                style={{ textDecoration: "none" }}
              >
                <Text variant={'bodyXs'}>{user.company.email}</Text>
              </a>
            </div>
            {user.company.country_id !== "USA" &&
            user.company.country_id !== "VIR" ? (
              <div style={{width: '30%'}}>
                <Text variant='bodyLg'>{t("profileComp.vatNum")}:</Text >
                <Text variant={'bodyXs'}>
                  {user.company.info.default_vat_number ||
                    this.renderRequired("")}
                </Text >
              </div >
            ) : (
              <div style={{width: '30%'}}>
                <Text variant='bodyLg'>Sales tax permit number:</Text >
                <Text variant={'bodyXs'}>
                  {user.company.info.default_sales_tax_number ||
                    this.renderRequired("")}
                </Text >
              </div >
            )}
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15}}>
            {user.is_manager && (
              <div>
                <Text variant='bodyLg'>{t("profileComp.companyStatus")}:</Text>
                <Text variant={'bodyXs'}>{user.company.company_status}</Text>
              </div>
            )}
            <div style={{width: '30%'}}>
              <Text variant='bodyLg'>{t("profileComp.phone")}:</Text >
              <Text variant={'bodyXs'}>{user.company.phone}</Text >
            </div >
          </div >
          
          {
            user.company?.info?.registration_code
            &&
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15,}}>
            <div>
              <Text variant='bodyLg'>{t("profileComp.regCode")}:</Text>
              <Text variant={'bodyXs'}>{user.company?.info?.registration_code}</Text >
            </div>
          </div>
          }

          <ButtonGroup>
            <Button variant='primary' size={'micro'} onClick={() => this.setState({ isEdit: true })}>
              {t("profileComp.editCompany")}
            </Button>
          </ButtonGroup>
        </div>
      </div>
    );
  }

  renderEditCompany(user) {
    const { t, changing } = this.props;
    const options = [];
    this.props.countries.map((country) => {
      options.push({
        label: country.name_no_article,
        value: country.code,
      });
    });

    return (
      <form onSubmit={this.doEdit}>
        <div>
          <TextField
            id='name'
            value={user.name}
            onChange={(value, id) =>
              this.handleChange(
                value,
                id,
                "errorName",
                t("profileComp.err.companyName")
              )
            }
            label={`${t("profileComp.companyName")}:`}
            type='text'
            disabled={
              !this.props.user.is_manager && this.props.user.company.name
            }
            error={this.state.errorName}
          />
          <br />

          <Box padding="4" maxWidth="500px">
            <p>{t("profileComp.change.paragraph")}:</p>

            <List type='bullet' style={{ listStyle: "circle" }}>
              <List.Item>{t("profileComp.change.step1")}</List.Item>
              <List.Item>{t("profileComp.change.step2")}</List.Item>
              <List.Item>{t("profileComp.change.step3")}</List.Item>
            </List>
          </Box>
          <br />
          {this.renderMultipleFileSelect("proof_files")}
        </div>

        <div>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                type='text'
                label={`${t("profileComp.merchID")}:`}
                value={this.props.user.id.toString().padStart(10, "0")}
                disabled
              />
              <br />
            </FormLayout.Group>

            <FormLayout.Group condensed>
              <TextField
                id='postcode'
                type='text'
                maxLength='12'
                label={`${t("profileComp.postcode")}:`}
                onChange={(value, id) =>
                  this.handleChange(
                    value,
                    id,
                    "errorPostCode",
                    t("profileComp.err.data")
                  )
                }
                value={user.postcode}
                error={this.state.errorPostCode}
              />
              
              <SearchingCountry
                title={`${t("profileComp.country")}:`}
                placeholder={`Enter ${t("profileComp.country")}`}
                onChange={this.handleChangeCountry}
                dataCountriesList={options}
                changeCountry={this.props.countries.find(item => item.code === this.state.country)?.name || ''}
                
                disabled={!this.props.user.is_manager && this.state.country}
                error={this.state.errorCountry}
                isOpen={true}
              />

              <TextField
                id='locality'
                maxLength='30'
                type='text'
                label={`${t("profileComp.cityTown")}:`}
                onChange={(value, id) =>
                  this.handleChange(
                    value,
                    id,
                    "errorLocality",
                    t("profileComp.err.data")
                  )
                }
                value={user.locality}
                error={this.state.errorLocality}
              />
            </FormLayout.Group>

            <FormLayout.Group condensed>
              <TextField
                id='street'
                maxLength='30'
                type='text'
                label={`${t("profileComp.strAddress")}:`}
                onChange={(value, id) =>
                  this.handleChange(
                    value,
                    id,
                    "errorStreet",
                    t("profileComp.err.data")
                  )
                }
                value={user.street}
                error={this.state.errorStreet}
              />

              <TextField
                id='housenum'
                maxLength='10'
                type='text'
                label={`${t("profileComp.housenum")}:`}
                onChange={(value, id) =>
                  this.handleChange(
                    value,
                    id,
                    "errorHousenum",
                    t("profileComp.err.data")
                  )
                }
                value={user.housenum}
                error={this.state.errorHousenum}
              />

              <TextField
                id='apartment'
                type='text'
                label={`${t("profileComp.apt")}:`}
                onChange={(value) => {
                  if (value.length > 0 && !isLatin(value)) {
                    this.setState({
                      apartment: value,
                      errorApartment: "Use the Latin alphabet!",
                    });
                  } else {
                    this.setState({
                      apartment: value,
                      errorApartment: false,
                    });
                  }
                }}
                value={user.apartment || ""}
                error={this.state.errorApartment}
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <TextField
                type='text'
                label={`${t("profileComp.companyEmail")}:`}
                onChange={this.handleChangeCompanyEmail}
                value={user.email}
                error={this.state.errorEmail}
              />
              <TextField
                id='person'
                type='text'
                onChange={(value, id) =>
                  this.handleChange(
                    value,
                    id,
                    "errorPerson",
                    t("profileComp.err.contactPerson")
                  )
                }
                label={
                  <InfoTooltip
                    content={"CEO, Director"}
                    children={t("profileComp.contactPeson")}
                  />
                }
                value={user.person}
                error={this.state.errorPerson}
              />
              <TextField
                id='regCode'
                type='text'
                onChange={(value, id) =>
                  this.handleChange(
                    value,
                    id,
                    "errorRegCode",
                    t("profileComp.err.regCode")
                  )
                }
                label={`${t("profileComp.regCode")}:`}
                value={user.regCode}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                type='text'
                onChange={this.handleChangeCompanyPhone}
                label={`${t("profileComp.phone")}:`}
                value={user.phone}
                error={this.state.errorPhone}
              />

              {this.state.country !== "USA" && this.state.country !== "VIR" ? (
                <TextField
                  id='vat'
                  type='text'
                  onChange={(value, id) =>
                    this.handleChange(
                      value,
                      id,
                      "errorVat",
                      t("profileComp.err.vatNum")
                    )
                  }
                  label={`${t("profileComp.vatNum")}:`}
                  value={user.vat}
                  error={this.state.errorVat}
                />
              ) : (
                <TextField
                  id='salesTaxNum'
                  type='text'
                  onChange={(value, id) =>
                    this.handleChange(
                      value,
                      id,
                      "errorSalesTaxNum",
                      "Please enter default Sales tax permit number"
                    )
                  }
                  label={`Sales tax permit number:`}
                  value={user.salesTaxNum}
                  error={this.state.errorSalesTaxNum}
                />
              )}
            </FormLayout.Group>
            <FormLayout.Group>
              {this.props.user.is_manager && (
                <Select
                  label={`${t("profileComp.companyStatus")}:`}
                  options={statusArr}
                  onChange={(status) => this.setState({ status })}
                  value={this.state.status}
                />
              )}
              <br />
            </FormLayout.Group>
          </FormLayout>
          <br />
          <ButtonGroup>
            <Button loading={changing} variant='primary' submit>
              {t("profileComp.saveChanges")}
            </Button>
            <Button onClick={() => this.setState({ isEdit: false })}>
              {t("profileComp.discard")}
            </Button>
          </ButtonGroup>
        </div>
      </form>
    );
  }

  render() {
    const { t, userGuide } = this.props;
    const {
      name,
      country,
      postcode,
      locality,
      street,
      housenum,
      apartment,
      person,
      email,
      phone,
      status,
      id,
      vat,
      salesTaxNum,
      regCode,
    } = this.state;

    const user = {
      name,
      country,
      person,
      postcode,
      locality,
      street,
      housenum,
      apartment,
      email,
      phone,
      status,
      id,
      vat,
      salesTaxNum,
      regCode,
    };

    return (
      <Layout.AnnotatedSection
        title={
          <Text variant='headingLg' as='p'>
            {t("profileComp.companyDet")}
          </Text>
        }
        description={
          <Text variant={'bodySm'}>
            {t("profileComp.desc")}
          </Text>
        }
      >
        {_.isEmpty(this.props.user) ? (
          <Spinner />
        ) : (
          <Layout.Section>
            <GuideCompletionPopup
              title={t("userGuide.completed1")}
              description={t("userGuide.completed1_desc")}
              visible={
                userGuide &&
                userGuide.show_user_guide &&
                userGuide.steps[0].show_banner
              }
              step={1}
            />

            <Card>
              {user.status === "processing" && !this.props.user.is_manager && (
                <Banner tone='info'>
                  <p>{t("profileComp.mess.companyName")}</p>
                </Banner>
              )}
              {!this.state.isEdit &&
                this.props.user.company &&
                this.renderViewCompany()}
              {this.state.isEdit &&
                this.props.user.company &&
                this.renderEditCompany(user)}
            </Card>
          </Layout.Section>
        )}
      </Layout.AnnotatedSection>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  userGuide: state.user.userGuide,
  countries: state.other.countriesAll,
  fetchingCountries: state.other.fetchingCountries,
  fetchingCountriesError: state.other.countries,
  defaultLanguage: state.user.defaultLanguage,
  changing: state.user.changing,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchUser: () => dispatch(fetchUser()),
    fetchUserGuide: () => dispatch(fetchUserGuide()),
    changeCompanyInfo: (params) => dispatch(changeCompanyInfo(params)),
    editUser: (params) => dispatch(editUser(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ProfileCompany)
);
