import React, { useEffect } from "react";
import InvoiceEditSaveBar from "containers/DocumentTemplate/components/InvoiceEditSaveBar";
import ExemptionEditSaveBar from "containers/DocumentTemplate/components/ExemptionEditSaveBar";
import {
  BankingInfo,
  BusinessType,
  Customer,
  Details,
  Items,
  ReasonForExemption,
  Totals,
} from "containers/DocumentTemplate/components";
import { fetchSalesTaxPermits } from '../../../../../../redux/features/declarations/declarationsSlice'
import { getDataForExemptionCertificate } from '../../../../../../redux/features/documents/documentsSlice'
import { fetchRegNumbers } from '../../../../../../redux/features/registration/registrationSlice'
import { DocumentType } from "../../../../common/constants";
import InlineText from "../../../../common/InlineText";
import { Box, Card, FormLayout, Select } from "@shopify/polaris";
import { useSelector } from "react-redux";
import useAppDispatch from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { buildAddress } from "containers/DocumentTemplate/common/utils";
import {
  mapNumbersToOptions,
  mapNumbersWithMultipleLabelsToOptions,
} from "../../helpers";

const VatNumberSelectIfNeeded = ({
  documentType,
  onLinkNavigate,
  data,
  setData,
}) => {
  const { regNumbers } = useSelector((state) => state.vatRegistration);
  const hasVatNumbers = Boolean(regNumbers.length);

  if (documentType !== DocumentType.InvoiceVAT) {
    return null;
  }
  console.log('data', data)
  const onSelectNumber = (id) => {
    setData((state) => ({
      ...state,
      vat_number_id: id,
    }));
  };

  return hasVatNumbers ? (
    <FormLayout>
      <FormLayout.Group>
        <Select
          options={mapNumbersWithMultipleLabelsToOptions(
            regNumbers,
            ["vat_number", "tin_number"],
            "id"
          )}
          label='Vat Number'
          onChange={onSelectNumber}
          value={data.vat_number_id}
        />
        <></>
      </FormLayout.Group>
    </FormLayout>
  ) : (
    <InlineText
      label='Vat Number'
      linkText={"required"}
      onLinkNavigate={() => onLinkNavigate(0)}
    />
  );
};

const SalesTaxSelectIfNeeded = ({
  documentType,
  onLinkNavigate,
  data,
  setData,
}) => {
  const { taxPermits } = useSelector((state) => state.declarations);
  const hasSalesTaxNumbers = Boolean(taxPermits.length);

  if (documentType !== DocumentType.InvoiceSalesTax) {
    return null;
  }

  const onSelectNumber = (id) => {
    setData((state) => ({
      ...state,
      sales_tax_number_id: id,
    }));
  };

  return hasSalesTaxNumbers ? (
    <FormLayout>
      <FormLayout.Group>
        <Select
          options={mapNumbersToOptions(taxPermits, "sales_tax_id", "id")}
          label='Sales Tax Number'
          onChange={onSelectNumber}
          value={data.sales_tax_number_id}
        />
        <></>
      </FormLayout.Group>
    </FormLayout>
  ) : (
    <InlineText
      label='Sales Tax Number'
      linkText={"required"}
      onLinkNavigate={() => onLinkNavigate(1)}
    />
  );
};

const BusinessTypeIfNeeded = ({ documentType, data, setData }) => {
  if (documentType !== DocumentType.Exemption) {
    return null;
  }

  return (
    <div title='Business Type'>
      <BusinessType data={data} setData={setData} />
    </div>
  );
};

const ReasonForExcemptionIfNeeded = ({ documentType, data, setData }) => {
  if (documentType !== DocumentType.Exemption) {
    return null;
  }

  return (
    <div title='Reason For Exemption'>
      <ReasonForExemption data={data} setData={setData} />
    </div>
  );
};

const Edit = ({ data, setData, setStepIndex, documentType }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const address = buildAddress(user);

  const fetchInitialData = () => {
    if (documentType === DocumentType.InvoiceVAT) {
      dispatch(fetchRegNumbers(true));
    } else if (documentType === DocumentType.InvoiceSalesTax) {
      dispatch(fetchSalesTaxPermits(true));
    } else if (documentType === DocumentType.Exemption) {
      dispatch(getDataForExemptionCertificate());
    }
  };

  useEffect(() => {
    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNavigateToProfile = () => {
    navigate("/profile");
  };

  const onNavigateToTaxSettings = (tab) => {
    navigate("/tax-settings", { state: { defaultTab: tab } });
  };

  return (
    <>
      <Card>
        <div title='Invoice Details'>
          <Details data={data} setData={setData} documentType={documentType} />
        </div>
        <BusinessTypeIfNeeded
          data={data}
          setData={setData}
          documentType={documentType}
        />
        <ReasonForExcemptionIfNeeded
          data={data}
          setData={setData}
          documentType={documentType}
        />
        <div title='Invoice Sender'>
          <Box
            display="flex"
            flexDirection="column"
            gap="4"
            width="100%"
          >
            <SalesTaxSelectIfNeeded
              documentType={documentType}
              onLinkNavigate={onNavigateToTaxSettings}
              data={data}
              setData={setData}
            />
            <VatNumberSelectIfNeeded
              documentType={documentType}
              onLinkNavigate={onNavigateToTaxSettings}
              data={data}
              setData={setData}
            />
            <FormLayout>
              <InlineText
                label='Registration code'
                text={user.company.info.registration_code}
                onLinkNavigate={onNavigateToProfile}
                linkText={!user.company.info.registration_code && "required"}
              />
              <InlineText
                label='Address'
                text={address}
                onLinkNavigate={onNavigateToProfile}
                linkText={!address && "required"}
              />
              <InlineText
                label='Email'
                text={user.company.email}
                onLinkNavigate={onNavigateToProfile}
                linkText={!user.company.email && "required"}
              />
              <BankingInfo data={data} setData={setData} />
            </FormLayout>
          </Box>
        </div>
        <div title='Invoice Recipient'>
          <Customer data={data} setData={setData} />
        </div>
        <div title='Items'>
          <Items data={data} setData={setData} />
        </div>
        <div title='Totals'>
          <Totals data={data} setData={setData} />
        </div>
      </Card>
      {(documentType === DocumentType.InvoiceSalesTax ||
        documentType === DocumentType.InvoiceVAT) && (
        <InvoiceEditSaveBar
          data={data}
          setStepIndex={setStepIndex}
          documentType={documentType}
        />
      )}
      {documentType === DocumentType.Exemption && (
        <ExemptionEditSaveBar
          data={data}
          setStepIndex={setStepIndex}
          documentType={documentType}
        />
      )}
    </>
  );
};

export default Edit;
