import React from "react";
import { useSelector } from "react-redux";
import useAppDispatch from "hooks/useAppDispatch";
import {
  Button,
  ButtonGroup,
  Card,
  Text,
  DataTable,
  TextField,
} from "@shopify/polaris";
import SaveBar from "components/SaveBar/SaveBar";
import { t } from "i18next";
import formatRow  from 'utils/formatRow'
import ArrowLeftBack from '../../../../img/arrow-left-for-button-back.svg'
import ArrowRight from '../../../../img/arrow-right-white.svg'
import { setQuantityForTwoSteps } from '../../../../redux/features/declarations/declarationsSlice'

const QuantityForTwoSteps = ({ toNextStep, toPrevStep }) => {
  const dispatch = useAppDispatch();
  const {
    eprSubcategories: {selectedCategory1, selectedCategory2, quantityArr, unitsDefault },
  } = useSelector((state) => state.declarations);
  
  // console.log('Quantity, selectedCategory2', selectedCategory2)
  // console.log('Quantity, selectedCategory1', selectedCategory1)
  // console.log('Quantity, quantityArr', quantityArr)
  
  const handleChange = (
    quantity,
    category,
    subCategory,
    subSubCategory,
    subSubSubCategory,
    recyclateOfPrimary
  ) => {
    
    dispatch(
      setQuantityForTwoSteps({
        quantity,
        category,
        subCategory,
        subSubCategory,
        subSubSubCategory,
        recyclateOfPrimary
      })
    );
  };
  
  const findLongestArray = (...arrays) => {
    return arrays.reduce((longest, current) => {
      
      const currentCount = current.reduce((count, item) => {
        if (Array.isArray(item)) {
          return count + item.length;
        } else {
          return count + 1;
        }
      }, 0);
      
      const longestCount = longest.reduce((count, item) => {
        if (Array.isArray(item)) {
          return count + item.length;
        } else {
          return count + 1;
        }
      }, 0);
      
      return currentCount > longestCount ? current : longest;
    }, []);
  };
  
  const longestSelectedCategory = findLongestArray(
    selectedCategory1,
    selectedCategory2,
  );
  
  const columns = [
    {
      header: {
        label: "Subcategory 1",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return rowData[0];
          },
        ],
      },
    },
    {
      header: {
        label: "Subcategory 2",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            return <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
              {rowData[1]}
            </div>;
          },
        ],
      },
    },
    
    {
      header: {
        label: "Quantity",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            if (!Array.isArray(rowData)) return null
            const [
              category,
              subCategory,
            ] = Array.isArray(rowData) ? rowData : [rowData.category, rowData.subCategory, rowData.subSubCategory, rowData.subSubSubCategory, rowData.quantity];
            
            const recyclateOfPrimary= rowData?.[4] ? rowData?.[4] : undefined;
            return (
              <div style={{ display: 'flex', alignItems: 'center', minWidth: '150px' }}>
               <TextField
                 value={
                   quantityArr.length > 0 && quantityArr?.find((item) => {
                     return (
                       category === item.category &&
                       subCategory === item.subCategory
                     )
                   })?.quantity || rowData?.quantity || rowData?.[5] || ''
                 }
                 placeholder="Quantity"
                 autoComplete="off"
                 style={{ minWidth: '100px' }}
                 onChange={(newQuantity) => {
                   handleChange(
                     newQuantity,
                     category,
                     subCategory,
                     recyclateOfPrimary
                   );
                 }}
               />
                {(rowData[2]?.units)? (
                    <span style={{ marginLeft: '8px' }}>
                    {rowData[2]?.units}
                  </span>
                  )
                  :
                  (<span style={{ marginLeft: '8px' }}>
                    {unitsDefault}
                  </span>
                  )}
            </div>
            );
          },
        ],
      },
    },
  ];

  const rows = formatRow(longestSelectedCategory, columns)

  return (
    <>
      <Card sectioned>
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text", "text"]}
          headings={columns.map(({ header }) => (
            <Text fontWeight='semibold'>{header.label}</Text>
          ))}
          rows={rows}
        />
      </Card>

      <SaveBar>
        <ButtonGroup>
          <Button size={'micro'} onClick={toPrevStep}> <img src={ArrowLeftBack} alt='arrow' style={{width: 10, height:10 , marginRight:8}}/>{t("eprReporting.back")}</Button>
          <Button size={'micro'} variant={'primary'} onClick={toNextStep}>
            {t("orderReg.next")}<img src={ArrowRight} alt='arrow' style={{width: 10, height:10 , marginLeft:8}}/>
          </Button>
        </ButtonGroup>
      </SaveBar>
    </>
  );
};

export default QuantityForTwoSteps;
