import React, { Component } from 'react';

export default class Step extends Component {
  constructor() {
    super();
    this.getStyles = this.getStyles.bind(this);
  }
  getStyles() {
    const {
      activeColor,
      completeColor,
      defaultColor,
      circleFontColor,
      activeTitleColor,
      completeTitleColor,
      defaultTitleColor,
      size: baseSize,
      circleFontSize,
      titleFontSize,
      /*
      circleTop, titleTop, width,*/ completeOpacity,
      activeOpacity,
      defaultOpacity,
      completeTitleOpacity,
      activeTitleOpacity,
      defaultTitleOpacity,
      /*barStyle, */ defaultBarColor,
      completeBarColor,
      defaultBorderColor,
      completeBorderColor,
      activeBorderColor,
      defaultBorderStyle,
      completeBorderStyle,
      activeBorderStyle,
      lineMarginOffset,
      defaultBorderWidth,
    } = this.props;
    const size = baseSize + 6;

    return {
      step: {
        display: 'flex',
        flexDirection: 'column',
      },
      circle: {
        width: size,
        height: size,
        marginRight: '20px',

        backgroundColor: 'transparent',
        borderRadius: '50%',
        textAlign: 'center',
        padding: 1,
        fontSize: circleFontSize,
        color: circleFontColor,
        opacity: defaultOpacity,
        borderWidth: defaultBorderColor ? defaultBorderWidth : 0,
        borderColor: defaultBorderColor,
        borderStyle: defaultBorderStyle,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      activeCircle: {
        width: size,
        height: size,
        marginLeft: 0,
        fontWeight: 'bold',
        backgroundColor: activeColor,
        opacity: activeOpacity,
        borderWidth: activeBorderColor ? defaultBorderWidth : 0,
        borderColor: activeBorderColor,
        borderStyle: activeBorderStyle,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      completedCircle: {
        width: size,
        height: size,
        marginLeft: 0,
        backgroundColor: '#E8F5EB',
        opacity: completeOpacity,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      index: {
        lineHeight: '24px' /*`${size + circleFontSize / 4}px`*/,
        color: circleFontColor,
      },
      title: {
        fontSize: titleFontSize,
        fontWeight: '600',
        textAlign: 'center',
        display: 'block',
        color: defaultTitleColor,
        opacity: defaultTitleOpacity,
      },
      activeTitle: {
        color: activeTitleColor,
        fontSize: titleFontSize,
        fontWeight: 'bold',
        opacity: activeTitleOpacity,
      },
      completedTitle: {
        color: completeTitleColor,
        opacity: completeTitleOpacity,
      },
      leftBar: {
        width: defaultBorderWidth,
        backgroundColor: defaultBarColor,
        marginLeft: size / 2 - defaultBorderWidth,

        height: 15,
        marginRight: size / 2 + defaultBorderWidth,
        opacity: defaultOpacity,
      },
      rightBar: {
        width: defaultBorderWidth,
        backgroundColor: defaultBarColor,

        height: 15,
        marginLeft: size / 2 - lineMarginOffset / 2, // castom
        opacity: defaultOpacity,
      },
      completedBar: {
        backgroundColor: completeBarColor || defaultBarColor,
        opacity: completeOpacity,
      },
      innerCircle: {
        width: 8,
        height: 8,
        borderRadius: 100,
        backgroundColor: defaultBarColor,
      },
      innerCircleActive: {
        backgroundColor: completeColor,
      },
      circleWrap: {
        width: baseSize,
        height: baseSize,
        border: `1px solid ${completeColor}`,
        borderRadius: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      unactiveCircleWrap: {
        border: `1px solid ${defaultBarColor}`,
      },
      completedCircleWrap: {
        borderColor: '#367C41',
      },
    };
  }

  render() {
    const {
      title,
      icon,
      index,
      active,
      completed,
      first,
      isLast,
      href,
      onClick,
    } = this.props;

    const styles = this.getStyles();
    const circleStyle = Object.assign(
      styles.circle,
      completed ? styles.completedCircle : {},
      active ? styles.activeCircle : {}
    );
    const titleStyle = Object.assign(
      styles.title,
      completed ? styles.completedTitle : {},
      active ? styles.activeTitle : {}
    );
    const leftStyle = Object.assign(
      styles.leftBar,
      active || completed ? styles.completedBar : {},
      active || completed ? { backgroundColor: 'rgba(72, 159, 86, 1)' } : {}
    );
    const rightStyle = Object.assign(
      styles.rightBar,
      completed ? styles.completedBar : {},
      completed ? { backgroundColor: 'rgba(72, 159, 86, 1)' } : {}
    );

    /*  const stepContent = icon && completed ? <img src={icon} width="60%" alt={index + 1} /> : index + 1;*/
    let stepContent = '';
    if (icon && completed) {
      stepContent = (
        <div
          style={{
            ...styles.circleWrap,
            ...styles.completedCircleWrap,
          }}
        >
          <img src={icon} width="45%" alt={index + 1} />
        </div>
      );
    }
    if (active) {
      stepContent = (
        <div
          style={{
            ...styles.circleWrap,
          }}
        >
          <div style={{ ...styles.innerCircle, ...styles.innerCircleActive }} />
        </div>
      );
    }
    console.log('active', active)
    console.log('completed', completed)
    return (
      <div style={styles.step}>
        {!first && <div style={leftStyle}></div>}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={circleStyle}>
            {active || completed ? (
              <a
                className={'stepper-circle-completed'}
                href={href}
                onClick={onClick}
                style={styles.index}
              >
                {stepContent}
              </a>
            ) : (
              <span className={'stepper-circle-unstarted'} style={styles.index}>
                <div
                  style={{ ...styles.circleWrap, ...styles.unactiveCircleWrap }}
                >
                  <div style={styles.innerCircle} />
                </div>
              </span>
            )}
          </div>
          {active || completed ? (
            <a
              className={'stepper-title-completed'}
              href={href}
              onClick={onClick}
              style={titleStyle}
            >
              {title}
            </a>
          ) : (
            <div className={'stepper-title-unstarted'} style={titleStyle}>
              {title}
            </div>
          )}
        </div>

        {!isLast && <div style={rightStyle}></div>}
      </div>
    );
  }
}

Step.defaultProps = {
  activeColor: '#5096FF',
  completeColor: '#5096FF',
  defaultColor: '#E0E0E0',
  activeTitleColor: '#000',
  completeTitleColor: '#000',
  defaultTitleColor: '#757575',
  circleFontColor: '#FFF',
  size: 32,
  circleFontSize: 16,
  titleFontSize: 14,
  circleTop: 24,
  titleTop: 8,
  defaultBarColor: '#E0E0E0',
  /*  barStyle: 'solid',*/
  borderStyle: 'solid',
  lineMarginOffset: 4,
  defaultBorderWidth: 2,
};
