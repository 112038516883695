import moment from 'moment'
import React from "react";
import { FormLayout, Select, Text, TextField } from "@shopify/polaris";
import Datepicker from "components/Datepicker";
import { DocumentType } from "containers/DocumentTemplate/common/constants";

const SalesCertificateNumber = (props) => {
  const { onChange, value } = props;
  return (
    <FormLayout>
      <FormLayout.Group>
        <Text color='subdued' alignment='end'>
          Sales Certificate Number
        </Text>
        <TextField
          id='sales_certificate_number'
          type='text'
          inputMode='text'
          placeholder='Enter your sales certificate number'
          value={value}
          onChange={(newValue) =>
            onChange("sales_certificate_number", newValue)
          }
        />
      </FormLayout.Group>
    </FormLayout>
  );
};

const InvoiceNumber = (props) => {
  const { onChange, value } = props;
  return (
    <FormLayout>
      <FormLayout.Group>
        <Text color='subdued' alignment='end'>
          Invoice Number
        </Text>
        <TextField
          id='invoice_number'
          type='text'
          inputMode='text'
          placeholder='Enter your invoice number'
          value={value}
          onChange={(newValue) => onChange("invoice_number", newValue)}
        />
      </FormLayout.Group>
    </FormLayout>
  );
};

const Details = ({ data, setData, documentType }) => {
  const {
    sales_certificate_number,
    invoice_number,
    invoice_date,
    due_date,
    currency,
  } = data;
  //
  // const handleTemplateDataChange = (id, newValue) => {
  //   setData((prevTemplateData) => ({
  //     ...prevTemplateData,
  //     [id]: newValue,
  //   }));
  // };
  const handleTemplateDataChange = (id, newValue) => {
    setData((prevTemplateData) => ({
      ...prevTemplateData,
      [id]: id.includes("date") ? moment(newValue) : newValue,
    }));
  };
  
  
  return (
    <FormLayout>
      <FormLayout.Group>
        <br />
        <FormLayout>
          {documentType === DocumentType.Exemption && (
            <SalesCertificateNumber
              value={sales_certificate_number}
              onChange={handleTemplateDataChange}
            />
          )}
          {(documentType === DocumentType.InvoiceSalesTax ||
            documentType === DocumentType.InvoiceVAT) && (
            <InvoiceNumber
              value={invoice_number}
              onChange={handleTemplateDataChange}
            />
          )}
          <FormLayout.Group>
            <Text color='subdued' alignment='end'>
              Invoice Date
            </Text>
            {/*<Datepicker*/}
            {/*  id='invoice_date'*/}
            {/*  allowRange={false}*/}
            {/*  labelDisabled*/}
            {/*  value={invoice_date}*/}
            {/*  onChange={(newValue) =>*/}
            {/*    handleTemplateDataChange("invoice_date", newValue)*/}
            {/*  }*/}
            {/*/>*/}
            <Datepicker
              id='invoice_date'
              allowRange={false}
              labelDisabled
              value={invoice_date ? invoice_date.format("YYYY-MM-DD") : ""}
              onChange={(newValue) =>
                handleTemplateDataChange("invoice_date", moment(newValue))
              }
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Text color='subdued' alignment='end'>
              Due Date
            </Text>
            {/*<Datepicker*/}
            {/*  id='due_date'*/}
            {/*  allowRange={false}*/}
            {/*  labelDisabled*/}
            {/*  value={due_date}*/}
            {/*  onChange={(newValue) =>*/}
            {/*    handleTemplateDataChange("due_date", newValue)*/}
            {/*  }*/}
            {/*/>*/}
            <Datepicker
              id='due_date'
              allowRange={false}
              labelDisabled
              value={due_date ? due_date.format("YYYY-MM-DD") : ""}
              onChange={(newValue) =>
                handleTemplateDataChange("due_date", moment(newValue))
              }
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Text color='subdued' alignment='end'>
              Currency
            </Text>
            <Select
              id='currency'
              allowRange={false}
              value={currency}
              onChange={(newValue) =>
                handleTemplateDataChange("currency", newValue)
              }
              options={[
                { value: "", label: "" },
                { value: "eur", label: "EUR" },
                { value: "usd", label: "USD" },
              ]}
            />
          </FormLayout.Group>
        </FormLayout>
      </FormLayout.Group>
    </FormLayout>
  );
};

export default Details;
