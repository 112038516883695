import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { injectStripe } from "react-stripe-elements";
import {
  orderPaymentRedirect,
  orderVatRegRedirect,
  orderPaymentAddonsRedirect,
  orderPaymentChargeInvoiceRedirect,
  orderSalesTaxRedirect,
  changeCardRedirect,
  removeCard,
} from "../../redux/features/payment/paymentSlice";
import { ButtonGroup, Banner, Button } from "@shopify/polaris";
import Modal from "components/Modal";
import { createCustomDispatch } from "helpers/customDispatch";
import { fetchUser } from '../../redux/features/user/userSlice'

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentShopify: null,
      paymentError: false,
      delError: false,
      save: true,

      actionDialogs: {
        deleteCard: { open: false },
      },
    };
  }

  doPayment = (resp) => {
    this.props.stripe
      .redirectToCheckout({
        sessionId: resp.session_id,
      })
      .then((strieResp) => {
        if (strieResp.error) {
          this.setState({
            paymentError: strieResp.error.message,
          });
        }
      })
      .catch(_.noop);
  };

  doRemovePaymentMethod = () => {
    const { t } = this.props;

    this.props
      .removeCard()
      .then(() => {
        window.location.reload(false);
      })
      .catch(() => {
        this.setState({
          delError: t("vatReg.err"),
        });
      });
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleChange = () => {
    const { t } = this.props;

    this.props
      .changeCardRedirect()
      .then((resp) => this.doPayment(resp))
      .catch((err) => {
        this.setState({
          paymentError: err.error || t("vatReg.err"),
        });
      });
  };

  handleSubmit = () => {
    const { data: params, service, promoCode, t } = this.props;
    const { save } = this.state;

    const data = {
      ...params,
      save,
      promo_code: promoCode,
    };

    if (service === "VAT registration") {
      this.props
        .orderVatRegRedirect({ data })
        .then((resp) => this.doPayment(resp))
        .catch((err) => {
          this.setState({
            paymentError: err.error || t("vatReg.err"),
          });
        });
    } else if (service === "addons") {
      this.props
        .orderPaymentAddonsRedirect({ data })
        .then((resp) => {
          if (resp.session_id) {
            this.doPayment(resp);
          } else {
            this.setState({
              paymentShopify: true,
            });
          }
        })
        .catch((err) => {
          this.setState({
            paymentError: err.error || t("vatReg.err"),
          });
        });
    } else if (service === "charge") {
      this.props
        .orderPaymentChargeInvoiceRedirect({ data })
        .then((resp) => this.doPayment(resp))
        .catch((err) => {
          this.setState({
            paymentError: err.error || t("vatReg.err"),
          });
        });
    } else if (service === "tax-return") {
      this.props
        .orderSalesTaxRedirect({ data })
        .then((resp) => {
          if (data.action === "credits" && resp.status === 200) {
            this.props.onCreditsPayment();
          } else {
            this.doPayment(resp);
          }
        })
        .catch((err) => {
          this.setState({
            paymentError: err.error || t("vatReg.err"),
          });
        });
    } else {
      this.props
        .orderPaymentRedirect({ data })
        .then((resp) => {
          if (resp.session_id) {
            this.doPayment(resp);
          } else {
            this.setState({
              paymentShopify: resp.message,
            });
          }
        })
        .catch((err) => {
          this.setState({
            paymentError:
              t("vatReg.err") + `${err.error ? `: ${err.error}` : ""}`,
          });
        });
    }
  };

  renderDeleteCardDialog = () => {
    const { removingCard, t } = this.props;
    const { actionDialogs, delError } = this.state;

    const onClose = () => {
      this.handleActionDialogsClose("deleteCard");
      this.setState({ delError: false });
    };

    return (
      <Modal
        title={"Remove payment method"}
        visible={actionDialogs.deleteCard.open}
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose} size='large'>
              {t("profileUsers.cancel")}
            </Button>

            <Button
              variant='primary'
              loading={removingCard}
              destructive
              onClick={this.doRemovePaymentMethod}
              size='large'
            >
              {t("profileMemb.confirm")}
            </Button>
          </ButtonGroup>
        }
        iconType='danger'
        onClose={onClose}
        content={
          <>
            {delError && (
              <div>
                <Banner
                  tone='critical'
                  onDismiss={() => this.setState({ delError: false })}
                  title={delError}
                />
                <br />
              </div>
            )}
            Are you sure you want to remove the card?
          </>
        }
      />
    );
  };

  render() {
    const { service, editingCard, user, renderCustom } = this.props;
    const { paymentShopify, paymentError } = this.state;

    return (
      <div>
        <form data-secret={this.state.secret}>
          {paymentError && (
            <div>
              <Banner
                tone='critical'
                onDismiss={() => this.setState({ paymentError: false })}
                title={paymentError}
              />
              <br />
            </div>
          )}

          {paymentShopify && (
            <div>
              <Banner
                tone='success'
                onDismiss={() => this.setState({ paymentShopify: null })}
                title={paymentShopify}
              />
              <br />
            </div>
          )}

          {service === "change" ? (
            <ButtonGroup>
              <Button
                variant='primary'
                size={'micro'}
                submit
                loading={editingCard}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleChange();
                }}
              >
                {user.company && user.company.info.card ? "Edit" : "Add card"}
              </Button>
              {user.company && user.company.info.card && (
                <Button
                  tone={'critical'}
                  variant={'plain'}
                  size={'micro'}
                  onClick={() => this.handleActionDialogsOpen("deleteCard")}
                >
                  Remove
                </Button>
              )}
            </ButtonGroup>
          ) : (
            <>
              {renderCustom ? (
                renderCustom((e) => {
                  e.preventDefault();
                  this.handleSubmit();
                }) // passing onConfirm fn
              ) : (
                <ButtonGroup fullWidth>
                  <Button onClick={this.props.onClose} size='large'>
                    Cancel
                  </Button>
                  <Button
                    variant='primary'
                    submit
                    size='large'
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleSubmit();
                    }}
                  >
                    Confirm
                  </Button>
                </ButtonGroup>
              )}
            </>
          )}
        </form>

        {this.renderDeleteCardDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  defaultLanguage: state.user.defaultLanguage,
  editingCard: state.payment.editingCardRedirect,
  removingCard: state.payment.removingCard,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchUser: () => dispatch(fetchUser()),
    orderPaymentRedirect: (params) => dispatch(orderPaymentRedirect(params)),
    orderVatRegRedirect: (params) => dispatch(orderVatRegRedirect(params)),
    orderPaymentAddonsRedirect: (data) =>
      dispatch(orderPaymentAddonsRedirect(data)),
    orderPaymentChargeInvoiceRedirect: (params) =>
      dispatch(orderPaymentChargeInvoiceRedirect(params)),
    orderSalesTaxRedirect: (params) => dispatch(orderSalesTaxRedirect(params)),
    changeCardRedirect: () => dispatch(changeCardRedirect()),
    removeCard: () => dispatch(removeCard()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectStripe(CheckoutForm));
